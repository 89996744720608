import { Avatar } from '@systemic-design-framework/react';
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/authenticationSlice";
import {useEffect, useState} from "react";

function CurrentUser() {
    const details = useSelector(selectUser);
    const [letters, setLetters] = useState<string>('');

    useEffect(() => {
        if (!!details && details.firstName){
            let uu = details.firstName.slice(0,1);
            if (!!details.lastName && details.lastName.length > 0){
                uu = uu + details.lastName.slice(0,1);
            }
            setLetters(uu.toUpperCase());
        }
    }, [details]);

    if (letters.length == 0){
        return <div></div>;
    }

    return <div title={details?.firstName + ' ' + details?.lastName + '\n' + details?.username}><Avatar size="xl">{letters}</Avatar></div>
}

export default CurrentUser;
