/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RESTResult,
  RESTResultString,
} from '../models/index';
import {
    RESTResultFromJSON,
    RESTResultToJSON,
    RESTResultStringFromJSON,
    RESTResultStringToJSON,
} from '../models/index';

/**
 * TestPerentieWSApi - interface
 * 
 * @export
 * @interface TestPerentieWSApiInterface
 */
export interface TestPerentieWSApiInterface {
    /**
     * Test endpoint. returns pong with the time and version string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPerentieWSApiInterface
     */
    pingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>>;

    /**
     * Test endpoint. returns pong with the time and version string
     */
    ping(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString>;

    /**
     * Test rule endpoint.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPerentieWSApiInterface
     */
    ruleTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResult>>;

    /**
     * Test rule endpoint.
     */
    ruleTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResult>;

}

/**
 * 
 */
export class TestPerentieWSApi extends runtime.BaseAPI implements TestPerentieWSApiInterface {

    /**
     * Test endpoint. returns pong with the time and version string
     */
    async pingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/test/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     * Test endpoint. returns pong with the time and version string
     */
    async ping(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString> {
        const response = await this.pingRaw(initOverrides);
        return await response.value();
    }

    /**
     * Test rule endpoint.
     */
    async ruleTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/test/rule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultFromJSON(jsonValue));
    }

    /**
     * Test rule endpoint.
     */
    async ruleTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResult> {
        const response = await this.ruleTestRaw(initOverrides);
        return await response.value();
    }

}
