// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z8NWF4S1RNTPK6BbTv5I {\n  display: flex;\n  flex-direction: column;\n  border-radius: 4px;\n  border: solid 1px var(--neutral-40);\n  background-color: var(--neutral-10);\n}\n.z8NWF4S1RNTPK6BbTv5I ._rDIgWHex_0jf_HhnWgf {\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  padding: 8px 16px;\n  background-color: var(--neutral-20);\n}\n.z8NWF4S1RNTPK6BbTv5I .LOtFIZWf58_rywpnWrDd {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  gap: 16px;\n  padding: 16px;\n}", "",{"version":3,"sources":["webpack://./src/components/Card/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mCAAA;EACA,mCAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,iBAAA;EACA,mCAAA;AACR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,SAAA;EACA,aAAA;AAAR","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    border-radius: 4px;\n    border: solid 1px var(--neutral-40);\n    background-color: var(--neutral-10);\n\n    .header {\n        height: 40px;\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        gap: 16px;\n        padding: 8px 16px;\n        background-color: var(--neutral-20);\n    }\n\n    .content {\n        display: flex;\n        flex-direction: column;\n        flex-grow: 1;\n        gap: 16px;\n        padding: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "z8NWF4S1RNTPK6BbTv5I",
	"header": "_rDIgWHex_0jf_HhnWgf",
	"content": "LOtFIZWf58_rywpnWrDd"
};
export default ___CSS_LOADER_EXPORT___;
