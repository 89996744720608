import { createContext } from 'react';

import { Validator } from '.';

interface ContextTypes {
    getValue: (key: string) => any;
    setValue: (key: string, value: any) => void;
    getError: (key: string) => string;
    register: (key: string, value: any, required: boolean, validators?: Validator<any> | Validator<any>[]) => void;
    loading: () => boolean;
}

const DefaultContextData: ContextTypes = {
    getValue: (key) => "",
    setValue: (key, value) => {},
    getError: (key) => "",
    register: (key, value, required, validators) => {},
    loading:  () => true
}

export const FormContext = createContext(DefaultContextData);

export default FormContext;
