import { useState } from 'react';
import FormContext from './FormContext';
import styles from './Form.module.scss';

interface FormProps {
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
    onChangeReducer?: (key: string, value: any) => void;
}

function Form({ children, onChangeReducer, style, className }: FormProps) {
    const [data, setData] = useState({});

    const update = (key: string, value: any) => {
        setData({ ...data, [key]: value });
        onChangeReducer && onChangeReducer(key, value);
    };

    return (
        <FormContext.Provider value={{ data, update }}>
            <div className={[styles['form'], className].join(' ')} style={style}>
                {children}
            </div>
        </FormContext.Provider>
    );
}

export default Form;
