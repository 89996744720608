import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import AccordionContext from '../AccordionContext';
import { animated, AnimatedProps, useSpring } from 'react-spring';
import styles from './Item.module.scss';
import useMeasure from 'react-use-measure';
import useAnimationConfig from '../../../hooks/useAnimationConfig';

interface ItemProps {
    title: string;
    children?: React.ReactNode;
    initiallyOpen?: boolean;
}

function Item({ title, children, initiallyOpen }: ItemProps) {
    const {} = useContext(AccordionContext);
    const [shouldAnimate, animationConfig] = useAnimationConfig();
    const [collapsed, setCollapsed] = useState(!initiallyOpen);
    const toggleCollapsed = () => setCollapsed(!collapsed);
    const [contentRef, { height }] = useMeasure();

    const ref = useRef<HTMLDivElement>(null);
    const [isLastElement, setIsLastElement] = useState<boolean>(false);
    useEffect(() => {
        if (ref.current) {
            if (ref.current.parentElement) {
                // get index of item within the parent
                const index = Array.from(ref.current.parentElement.children).indexOf(ref.current);

                setIsLastElement(index === ref.current.parentElement.children.length - 1);
            }
        }
    }, [ref]);

    const animatedIconStyle = useSpring({
        to: {
            transform: collapsed ? `rotate(0deg)` : `rotate(-180deg)`,
        } as AnimatedProps<CSSProperties>,
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    const animatedContentStyle = useSpring({
        to: {
            height: collapsed ? 0 : height,
        } as AnimatedProps<CSSProperties>,
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    const getBorderBottomColor = () => {
        if (collapsed && isLastElement) {
            return 'var(--neutral-20)';
        }

        if (!collapsed) {
            return 'var(--neutral-10)';
        }

        return 'var(--neutral-40)';
    };

    const animatedHeaderStyle = useSpring({
        to: {
            borderBottomColor: getBorderBottomColor(),
            backgroundColor: collapsed ? 'var(--neutral-20)' : 'var(--neutral-10)',
        } as AnimatedProps<CSSProperties>,
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    return (
        <div className={styles['item']} ref={ref}>
            <animated.div className={styles['header']} onClick={toggleCollapsed} style={animatedHeaderStyle}>
                <span className={styles['title']}>{title}</span>
                <animated.svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className={styles['icon']}
                    style={animatedIconStyle}
                >
                    <path d="M11.06 5.53 8 8.583 4.94 5.53 4 6.47l4 4 4-4-.94-.94z" fill="#0A0A0A" />
                </animated.svg>
            </animated.div>

            <animated.div className={styles['content-frame']} style={animatedContentStyle}>
                <div className={styles['content']} ref={contentRef}>
                    {children}
                </div>
            </animated.div>
        </div>
    );
}

export default Item;
