/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserRoleDetails } from './UserRoleDetails';
import {
    UserRoleDetailsFromJSON,
    UserRoleDetailsFromJSONTyped,
    UserRoleDetailsToJSON,
} from './UserRoleDetails';

/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    lastName?: string;
    /**
     * 
     * @type {Array<UserRoleDetails>}
     * @memberof UserDetails
     */
    roles?: Array<UserRoleDetails>;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    timeZone?: string;
}

/**
 * Check if a given object implements the UserDetails interface.
 */
export function instanceOfUserDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(UserRoleDetailsFromJSON)),
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'username': value.username,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(UserRoleDetailsToJSON)),
        'timeZone': value.timeZone,
    };
}

