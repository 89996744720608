/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventMetric } from './EventMetric';
import {
    EventMetricFromJSON,
    EventMetricFromJSONTyped,
    EventMetricToJSON,
} from './EventMetric';

/**
 * 
 * @export
 * @interface ReceivedEvent
 */
export interface ReceivedEvent {
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    agentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    componentId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivedEvent
     */
    receivedTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    status?: ReceivedEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    componentEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    messageId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivedEvent
     */
    eventTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    severity?: ReceivedEventSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceivedEvent
     */
    title?: string;
    /**
     * 
     * @type {Array<EventMetric>}
     * @memberof ReceivedEvent
     */
    metrics?: Array<EventMetric>;
}


/**
 * @export
 */
export const ReceivedEventStatusEnum = {
    New: 'NEW',
    Processing: 'PROCESSING',
    Complete: 'COMPLETE'
} as const;
export type ReceivedEventStatusEnum = typeof ReceivedEventStatusEnum[keyof typeof ReceivedEventStatusEnum];

/**
 * @export
 */
export const ReceivedEventSeverityEnum = {
    Ok: 'OK',
    Info: 'INFO',
    Low: 'LOW',
    Moderate: 'MODERATE',
    High: 'HIGH',
    Severe: 'SEVERE'
} as const;
export type ReceivedEventSeverityEnum = typeof ReceivedEventSeverityEnum[keyof typeof ReceivedEventSeverityEnum];


/**
 * Check if a given object implements the ReceivedEvent interface.
 */
export function instanceOfReceivedEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReceivedEventFromJSON(json: any): ReceivedEvent {
    return ReceivedEventFromJSONTyped(json, false);
}

export function ReceivedEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceivedEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'agentId': !exists(json, 'agent_id') ? undefined : json['agent_id'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'receivedTime': !exists(json, 'receivedTime') ? undefined : (new Date(json['receivedTime'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'componentEventId': !exists(json, 'componentEventId') ? undefined : json['componentEventId'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'eventTime': !exists(json, 'eventTime') ? undefined : (new Date(json['eventTime'])),
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'metrics': !exists(json, 'metrics') ? undefined : ((json['metrics'] as Array<any>).map(EventMetricFromJSON)),
    };
}

export function ReceivedEventToJSON(value?: ReceivedEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'agent_id': value.agentId,
        'component_id': value.componentId,
        'receivedTime': value.receivedTime === undefined ? undefined : (value.receivedTime.toISOString()),
        'status': value.status,
        'componentEventId': value.componentEventId,
        'messageId': value.messageId,
        'eventTime': value.eventTime === undefined ? undefined : (value.eventTime.toISOString()),
        'severity': value.severity,
        'title': value.title,
        'metrics': value.metrics === undefined ? undefined : ((value.metrics as Array<any>).map(EventMetricToJSON)),
    };
}

