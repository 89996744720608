import { AxiosResponse } from 'axios';

import { ServiceStatus } from './service';

export type SuspendedPromise<T> = { read: () => T };

export function suspendPromise<T, D>(promise: Promise<AxiosResponse<T, D>>): SuspendedPromise<T> {
    var status = ServiceStatus.Loading;
    var data = {} as T;

    const suspense = promise
        .then(response => {
            data = response.data;

            // @ts-ignore
            const error = response.data.error;

            if (error) {
                console.error(error);
            }

            status = ServiceStatus.Success;
            return response;
        })
        .catch(error => {
            status = ServiceStatus.Error;
            return error;
        });

    const read = () => {
        switch (status) {
            case ServiceStatus.Loading:
                throw suspense;
            case ServiceStatus.Error:
                return data;
            case ServiceStatus.Success:
                return data;
            default:
                return data;
        }
    };

    return {
        read,
    };
}

export type PassthroughPromise<T> = { read: () => T; status: () => ServiceStatus };

export function passthroughPromise<T, D>(promise: Promise<AxiosResponse<T, D>>): Promise<T> {
    return promise.then(response => {
        return response.data;
    });
}
