import styles from './AgentStatus.module.scss';
import { ReactComponent as AgentStatusSVG } from '../../assets/agent-status-icon.svg';

interface AgentStatusIconProps {
    connected?: boolean;
    timestamp?: string;
}

function AgentStatus({ connected, timestamp = '' }: AgentStatusIconProps) {
    // convert timestamp to dd/mm/yyyy
    const date = new Date(timestamp);
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return (
        <div className={styles['container']}>
            <AgentStatusSVG
                className={styles[`icon-${connected ? 'connected' : 'disconnected'}`]}
            />
            <div className={styles['timestamp']}>{dateString}</div>
        </div>
    );
}

export default AgentStatus;
