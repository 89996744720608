import styles from './Progress.module.scss';
import { animated, useSpring } from 'react-spring';
import useAnimationConfig from '../../hooks/useAnimationConfig';

interface ProgressProps {
    type: 'linear' | 'circular';
    size: 'small' | 'medium';
    value?: number;
    max?: number;
    min?: number;
}

function Progress({ type, size, value = 0, max = 100, min = 0 }: ProgressProps) {
    const [shouldAnimate, animationConfig] = useAnimationConfig();
    const percentage = Math.min(Math.max((value - min) / max, 0), 1) * 100;
    const animatedLinearStyle = useSpring({
        to: { width: `${percentage}%` },
        config: animationConfig,
        immediate: !shouldAnimate,
    });
    const animatedCircularStyle = useSpring({
        to: { strokeDashoffset: `${percentage}` },
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    if (type === 'linear') {
        return (
            <div className={styles[`linear-progress-${size}`]}>
                <svg className={styles['bar']} fill="none">
                    <rect height="100%" width="100%" rx="3" fill="var(--neutral-30)" />
                    <animated.rect
                        style={animatedLinearStyle}
                        className={styles['progress-bar']}
                        height="100%"
                        rx="3"
                        fill="var(--primary-light)"
                    />
                </svg>
                <animated.div className={styles['percentage']}>{percentage}%</animated.div>
            </div>
        );
    } else {
        return (
            <div className={styles[`circular-progress-${size}`]}>
                <svg fill="none" overflow="visible" className={styles['circle']} strokeWidth="3">
                    <circle cx="50%" cy="50%" r="50%" fill="none" stroke="var(--primary-light)" strokeWidth="inherit" />

                    <animated.circle
                        style={animatedCircularStyle}
                        cx="50%"
                        cy="50%"
                        r="50%"
                        fill="transparent"
                        stroke="var(--neutral-30)"
                        strokeWidth="inherit"
                        pathLength="100"
                        strokeDasharray="100"
                    />
                </svg>
                <div className={styles['percentage']}>{percentage}%</div>
            </div>
        );
    }
}

export default Progress;
