/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HistoryItemDetails } from './HistoryItemDetails';
import {
    HistoryItemDetailsFromJSON,
    HistoryItemDetailsFromJSONTyped,
    HistoryItemDetailsToJSON,
} from './HistoryItemDetails';

/**
 * 
 * @export
 * @interface StateHistoryDetails
 */
export interface StateHistoryDetails {
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    lastState?: StateHistoryDetailsLastStateEnum;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    nextState?: StateHistoryDetailsNextStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof StateHistoryDetails
     */
    timeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    ruleTrigger?: string;
    /**
     * 
     * @type {Array<HistoryItemDetails>}
     * @memberof StateHistoryDetails
     */
    events?: Array<HistoryItemDetails>;
}


/**
 * @export
 */
export const StateHistoryDetailsLastStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type StateHistoryDetailsLastStateEnum = typeof StateHistoryDetailsLastStateEnum[keyof typeof StateHistoryDetailsLastStateEnum];

/**
 * @export
 */
export const StateHistoryDetailsNextStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type StateHistoryDetailsNextStateEnum = typeof StateHistoryDetailsNextStateEnum[keyof typeof StateHistoryDetailsNextStateEnum];


/**
 * Check if a given object implements the StateHistoryDetails interface.
 */
export function instanceOfStateHistoryDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StateHistoryDetailsFromJSON(json: any): StateHistoryDetails {
    return StateHistoryDetailsFromJSONTyped(json, false);
}

export function StateHistoryDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateHistoryDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'lastState': !exists(json, 'lastState') ? undefined : json['lastState'],
        'nextState': !exists(json, 'nextState') ? undefined : json['nextState'],
        'timeStamp': !exists(json, 'timeStamp') ? undefined : (new Date(json['timeStamp'])),
        'ruleTrigger': !exists(json, 'ruleTrigger') ? undefined : json['ruleTrigger'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(HistoryItemDetailsFromJSON)),
    };
}

export function StateHistoryDetailsToJSON(value?: StateHistoryDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'component_id': value.componentId,
        'lastState': value.lastState,
        'nextState': value.nextState,
        'timeStamp': value.timeStamp === undefined ? undefined : (value.timeStamp.toISOString()),
        'ruleTrigger': value.ruleTrigger,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(HistoryItemDetailsToJSON)),
    };
}

