import { createContext } from 'react';

export interface TabBarContextProps {
    size: 's' | 'm';
    current: string | number;
    setCurrent: (current: any) => void;
    setCurrentRef: (currentRef: React.RefObject<HTMLElement>) => void;
}

const TabBarContext = createContext<TabBarContextProps>({
    size: 's',
    current: '',
    setCurrent: (current: string | number) => {},
    setCurrentRef: (currentRef: React.RefObject<HTMLElement>) => {},
});

export default TabBarContext;
