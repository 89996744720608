/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AlertActionRequest,
  BaseObjectRequest,
  RESTResultAlertDetails,
  RESTResultListUserBasicDetails,
} from '../models/index';
import {
    AlertActionRequestFromJSON,
    AlertActionRequestToJSON,
    BaseObjectRequestFromJSON,
    BaseObjectRequestToJSON,
    RESTResultAlertDetailsFromJSON,
    RESTResultAlertDetailsToJSON,
    RESTResultListUserBasicDetailsFromJSON,
    RESTResultListUserBasicDetailsToJSON,
} from '../models/index';

export interface AssignAlertRequest {
    alertActionRequest?: AlertActionRequest;
}

export interface GetUserHandlingOptionsRequest {
    id?: string;
    type?: GetUserHandlingOptionsTypeEnum;
}

export interface HandleRequest {
    baseObjectRequest?: BaseObjectRequest;
}

export interface UnHandleRequest {
    baseObjectRequest?: BaseObjectRequest;
}

/**
 * PerentieAlertHandlingWSApi - interface
 * 
 * @export
 * @interface PerentieAlertHandlingWSApiInterface
 */
export interface PerentieAlertHandlingWSApiInterface {
    /**
     * Assign alert to another user. User will become the handling user for that alert
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertHandlingWSApiInterface
     */
    assignAlertRaw(requestParameters: AssignAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Assign alert to another user. User will become the handling user for that alert
     */
    assignAlert(alertActionRequest?: AlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Get a list of users that can either be assigned as the handling user to the given alert
     * @param {string} [id] 
     * @param {'HANDLE' | 'UNHANDLE' | 'ASSIGN' | 'COMMENT' | 'NOTIFY' | 'NOTIFY_FAIL' | 'USER_CLOSE'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertHandlingWSApiInterface
     */
    getUserHandlingOptionsRaw(requestParameters: GetUserHandlingOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListUserBasicDetails>>;

    /**
     * Get a list of users that can either be assigned as the handling user to the given alert
     */
    getUserHandlingOptions(id?: string, type?: GetUserHandlingOptionsTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListUserBasicDetails>;

    /**
     * Session user handle alert
     * @param {BaseObjectRequest} [baseObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertHandlingWSApiInterface
     */
    handleRaw(requestParameters: HandleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Session user handle alert
     */
    handle(baseObjectRequest?: BaseObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Session user unhandle alert
     * @param {BaseObjectRequest} [baseObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAlertHandlingWSApiInterface
     */
    unHandleRaw(requestParameters: UnHandleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Session user unhandle alert
     */
    unHandle(baseObjectRequest?: BaseObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

}

/**
 * 
 */
export class PerentieAlertHandlingWSApi extends runtime.BaseAPI implements PerentieAlertHandlingWSApiInterface {

    /**
     * Assign alert to another user. User will become the handling user for that alert
     */
    async assignAlertRaw(requestParameters: AssignAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/handle/assign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertActionRequestToJSON(requestParameters.alertActionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Assign alert to another user. User will become the handling user for that alert
     */
    async assignAlert(alertActionRequest?: AlertActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.assignAlertRaw({ alertActionRequest: alertActionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of users that can either be assigned as the handling user to the given alert
     */
    async getUserHandlingOptionsRaw(requestParameters: GetUserHandlingOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListUserBasicDetails>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/handle/handleOptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListUserBasicDetailsFromJSON(jsonValue));
    }

    /**
     * Get a list of users that can either be assigned as the handling user to the given alert
     */
    async getUserHandlingOptions(id?: string, type?: GetUserHandlingOptionsTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListUserBasicDetails> {
        const response = await this.getUserHandlingOptionsRaw({ id: id, type: type }, initOverrides);
        return await response.value();
    }

    /**
     * Session user handle alert
     */
    async handleRaw(requestParameters: HandleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/handle/alert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseObjectRequestToJSON(requestParameters.baseObjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Session user handle alert
     */
    async handle(baseObjectRequest?: BaseObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.handleRaw({ baseObjectRequest: baseObjectRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Session user unhandle alert
     */
    async unHandleRaw(requestParameters: UnHandleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/handle/unhandle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseObjectRequestToJSON(requestParameters.baseObjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Session user unhandle alert
     */
    async unHandle(baseObjectRequest?: BaseObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.unHandleRaw({ baseObjectRequest: baseObjectRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetUserHandlingOptionsTypeEnum = {
    Handle: 'HANDLE',
    Unhandle: 'UNHANDLE',
    Assign: 'ASSIGN',
    Comment: 'COMMENT',
    Notify: 'NOTIFY',
    NotifyFail: 'NOTIFY_FAIL',
    UserClose: 'USER_CLOSE'
} as const;
export type GetUserHandlingOptionsTypeEnum = typeof GetUserHandlingOptionsTypeEnum[keyof typeof GetUserHandlingOptionsTypeEnum];
