/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgentRequest,
  BaseObjectRequest,
  RESTResultAgentDetails,
  RESTResultClientDetails,
  RESTResultListAgentBasicDetails,
  RESTResultListClientDetails,
  RESTResultListComponentBasicDetails,
  RESTResultListScheduleDetails,
  RESTResultListUserDetails,
  RESTResultString,
  RESTResultUserDetails,
  UpdateClientRequest,
  UpdateUserRequest,
} from '../models/index';
import {
    AgentRequestFromJSON,
    AgentRequestToJSON,
    BaseObjectRequestFromJSON,
    BaseObjectRequestToJSON,
    RESTResultAgentDetailsFromJSON,
    RESTResultAgentDetailsToJSON,
    RESTResultClientDetailsFromJSON,
    RESTResultClientDetailsToJSON,
    RESTResultListAgentBasicDetailsFromJSON,
    RESTResultListAgentBasicDetailsToJSON,
    RESTResultListClientDetailsFromJSON,
    RESTResultListClientDetailsToJSON,
    RESTResultListComponentBasicDetailsFromJSON,
    RESTResultListComponentBasicDetailsToJSON,
    RESTResultListScheduleDetailsFromJSON,
    RESTResultListScheduleDetailsToJSON,
    RESTResultListUserDetailsFromJSON,
    RESTResultListUserDetailsToJSON,
    RESTResultStringFromJSON,
    RESTResultStringToJSON,
    RESTResultUserDetailsFromJSON,
    RESTResultUserDetailsToJSON,
    UpdateClientRequestFromJSON,
    UpdateClientRequestToJSON,
    UpdateUserRequestFromJSON,
    UpdateUserRequestToJSON,
} from '../models/index';

export interface CreatePerentieClientRequest {
    updateClientRequest?: UpdateClientRequest;
}

export interface DownloadComponentConfigRequest {
    comp?: Array<string>;
    dl?: boolean;
}

export interface GetAgentDetailsRequest {
    agentId: string;
}

export interface RegisterAgentRequest {
    agentRequest?: AgentRequest;
}

export interface SendTestNotificationRequest {
    baseObjectRequest?: BaseObjectRequest;
}

export interface UpdateAgentRequest {
    agentRequest?: AgentRequest;
}

export interface UpdatePerentieClientRequest {
    clientId: string;
    updateClientRequest?: UpdateClientRequest;
}

export interface UpdateUserRolesRequest {
    updateUserRequest?: UpdateUserRequest;
}

/**
 * PerentieAdminWSApi - interface
 * 
 * @export
 * @interface PerentieAdminWSApiInterface
 */
export interface PerentieAdminWSApiInterface {
    /**
     * ADMIN ONLY - Create a new PerentieClient
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    createPerentieClientRaw(requestParameters: CreatePerentieClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultClientDetails>>;

    /**
     * ADMIN ONLY - Create a new PerentieClient
     */
    createPerentieClient(updateClientRequest?: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultClientDetails>;

    /**
     * ADMIN ONLY - Download component config yml.
     * @param {Array<string>} [comp] 
     * @param {boolean} [dl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    downloadComponentConfigRaw(requestParameters: DownloadComponentConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * ADMIN ONLY - Download component config yml.
     */
    downloadComponentConfig(comp?: Array<string>, dl?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * ADMIN ONLY - Get Agent Details
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    getAgentDetailsRaw(requestParameters: GetAgentDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAgentDetails>>;

    /**
     * ADMIN ONLY - Get Agent Details
     */
    getAgentDetails(agentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAgentDetails>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    getAgentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAgentBasicDetails>>;

    /**
     */
    getAgents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAgentBasicDetails>;

    /**
     * ADMIN ONLY - Get all Perentie Clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    getAllPerentieClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListClientDetails>>;

    /**
     * ADMIN ONLY - Get all Perentie Clients
     */
    getAllPerentieClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListClientDetails>;

    /**
     * ADMIN ONLY - Get all Perentie Components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    getAllPerentieComponentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListComponentBasicDetails>>;

    /**
     * ADMIN ONLY - Get all Perentie Components
     */
    getAllPerentieComponents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListComponentBasicDetails>;

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    getAllSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListScheduleDetails>>;

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     */
    getAllSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListScheduleDetails>;

    /**
     * Returns all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListUserDetails>>;

    /**
     * Returns all users
     */
    getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListUserDetails>;

    /**
     * ADMIN ONLY - Registers a new agent
     * @param {AgentRequest} [agentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    registerAgentRaw(requestParameters: RegisterAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAgentDetails>>;

    /**
     * ADMIN ONLY - Registers a new agent
     */
    registerAgent(agentRequest?: AgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAgentDetails>;

    /**
     * ADMIN ONLY - Sends a test notification to a user
     * @param {BaseObjectRequest} [baseObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    sendTestNotificationRaw(requestParameters: SendTestNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>>;

    /**
     * ADMIN ONLY - Sends a test notification to a user
     */
    sendTestNotification(baseObjectRequest?: BaseObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString>;

    /**
     * ADMIN ONLY - Updates and existing agent\'s details
     * @param {AgentRequest} [agentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    updateAgentRaw(requestParameters: UpdateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAgentDetails>>;

    /**
     * ADMIN ONLY - Updates and existing agent\'s details
     */
    updateAgent(agentRequest?: AgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAgentDetails>;

    /**
     * ADMIN ONLY - Update a PerentieClient
     * @param {string} clientId 
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    updatePerentieClientRaw(requestParameters: UpdatePerentieClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultClientDetails>>;

    /**
     * ADMIN ONLY - Update a PerentieClient
     */
    updatePerentieClient(clientId: string, updateClientRequest?: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultClientDetails>;

    /**
     * ADMIN ONLY - Update a user\'s roles
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAdminWSApiInterface
     */
    updateUserRolesRaw(requestParameters: UpdateUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultUserDetails>>;

    /**
     * ADMIN ONLY - Update a user\'s roles
     */
    updateUserRoles(updateUserRequest?: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultUserDetails>;

}

/**
 * 
 */
export class PerentieAdminWSApi extends runtime.BaseAPI implements PerentieAdminWSApiInterface {

    /**
     * ADMIN ONLY - Create a new PerentieClient
     */
    async createPerentieClientRaw(requestParameters: CreatePerentieClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultClientDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/createClient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClientRequestToJSON(requestParameters.updateClientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultClientDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Create a new PerentieClient
     */
    async createPerentieClient(updateClientRequest?: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultClientDetails> {
        const response = await this.createPerentieClientRaw({ updateClientRequest: updateClientRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Download component config yml.
     */
    async downloadComponentConfigRaw(requestParameters: DownloadComponentConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.comp) {
            queryParameters['comp'] = requestParameters.comp;
        }

        if (requestParameters.dl !== undefined) {
            queryParameters['dl'] = requestParameters.dl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/componentConfig`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ADMIN ONLY - Download component config yml.
     */
    async downloadComponentConfig(comp?: Array<string>, dl?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.downloadComponentConfigRaw({ comp: comp, dl: dl }, initOverrides);
    }

    /**
     * ADMIN ONLY - Get Agent Details
     */
    async getAgentDetailsRaw(requestParameters: GetAgentDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAgentDetails>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling getAgentDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/agent/{agent_id}`.replace(`{${"agent_id"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAgentDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Get Agent Details
     */
    async getAgentDetails(agentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAgentDetails> {
        const response = await this.getAgentDetailsRaw({ agentId: agentId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAgentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAgentBasicDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/agents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListAgentBasicDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getAgents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAgentBasicDetails> {
        const response = await this.getAgentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Get all Perentie Clients
     */
    async getAllPerentieClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListClientDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListClientDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Get all Perentie Clients
     */
    async getAllPerentieClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListClientDetails> {
        const response = await this.getAllPerentieClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Get all Perentie Components
     */
    async getAllPerentieComponentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListComponentBasicDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/components`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListComponentBasicDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Get all Perentie Components
     */
    async getAllPerentieComponents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListComponentBasicDetails> {
        const response = await this.getAllPerentieComponentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     */
    async getAllSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListScheduleDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListScheduleDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Get all Perentie Schedules
     */
    async getAllSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListScheduleDetails> {
        const response = await this.getAllSchedulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns all users
     */
    async getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListUserDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListUserDetailsFromJSON(jsonValue));
    }

    /**
     * Returns all users
     */
    async getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListUserDetails> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Registers a new agent
     */
    async registerAgentRaw(requestParameters: RegisterAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAgentDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/registerAgent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgentRequestToJSON(requestParameters.agentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAgentDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Registers a new agent
     */
    async registerAgent(agentRequest?: AgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAgentDetails> {
        const response = await this.registerAgentRaw({ agentRequest: agentRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Sends a test notification to a user
     */
    async sendTestNotificationRaw(requestParameters: SendTestNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/notifyTest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseObjectRequestToJSON(requestParameters.baseObjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Sends a test notification to a user
     */
    async sendTestNotification(baseObjectRequest?: BaseObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString> {
        const response = await this.sendTestNotificationRaw({ baseObjectRequest: baseObjectRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Updates and existing agent\'s details
     */
    async updateAgentRaw(requestParameters: UpdateAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAgentDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/updateAgent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgentRequestToJSON(requestParameters.agentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAgentDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Updates and existing agent\'s details
     */
    async updateAgent(agentRequest?: AgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAgentDetails> {
        const response = await this.updateAgentRaw({ agentRequest: agentRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Update a PerentieClient
     */
    async updatePerentieClientRaw(requestParameters: UpdatePerentieClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultClientDetails>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updatePerentieClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/updateClient/{client_id}`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClientRequestToJSON(requestParameters.updateClientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultClientDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Update a PerentieClient
     */
    async updatePerentieClient(clientId: string, updateClientRequest?: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultClientDetails> {
        const response = await this.updatePerentieClientRaw({ clientId: clientId, updateClientRequest: updateClientRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ADMIN ONLY - Update a user\'s roles
     */
    async updateUserRolesRaw(requestParameters: UpdateUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultUserDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/updateUserRoles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestToJSON(requestParameters.updateUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultUserDetailsFromJSON(jsonValue));
    }

    /**
     * ADMIN ONLY - Update a user\'s roles
     */
    async updateUserRoles(updateUserRequest?: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultUserDetails> {
        const response = await this.updateUserRolesRaw({ updateUserRequest: updateUserRequest }, initOverrides);
        return await response.value();
    }

}
