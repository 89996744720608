import { ComponentType, CSSProperties, useContext, useState } from 'react';
import SidebarContext from '../SidebarContext';
import styles from './Link.module.scss';
import { animated, AnimatedProps } from 'react-spring';
interface LinkProps {
    title: string;
    to?: string;
    Icon?: ComponentType<any>;
    ActiveIcon?: ComponentType<any>;
}

function Link({ title, Icon, ActiveIcon, to }: LinkProps) {
    const { navigator, location, animationProgress } =
        useContext(SidebarContext);

    const isActive =
        to === location.pathname || location.pathname?.startsWith(to + '/');

    const [isHovered, setIsHovered] = useState(false);
    const shouldShowActiveIcon = isActive || isHovered;

    const animatedIconStyle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [5, 50],
            })
            .to((number) => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [5, 50],
            })
            .to((number) => `${number}%`),
    } as AnimatedProps<CSSProperties>;

    const animatedTitleStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [25, 100],
            })
            .to((number) => `${number}%`),
    } as AnimatedProps<CSSProperties>;

    const onClickHandler = () => {
        if (to) {
            navigator(to);
        } else {
            console.warn('Link was clicked but no destination was specified');
        }
    };

    return (
        <div
            className={`${styles['link']} ${isActive && styles['selected']}`}
            onClick={onClickHandler}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <animated.div
                className={styles['icon-frame']}
                style={animatedIconStyle}
            >
                <i className={styles['icon']}>{Icon && <Icon />}</i>
            </animated.div>

            <animated.div
                className={styles['title-frame']}
                style={animatedTitleStyle}
            >
                <span className={styles['title']}>{title}</span>
            </animated.div>

            <i className={styles['active-icon']}>
                {ActiveIcon && shouldShowActiveIcon && <ActiveIcon />}
            </i>
        </div>
    );
}

export default Link;
