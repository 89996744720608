import {
    AuthenticationService,
    Credentials,
    LoginDetails,
    ServiceStatus,
    UserDetails,
} from '@perentie/common';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../store';

interface CheckSessionReduxPayload {
    sessionResponse: any;
    success: boolean;
}

interface LoginReduxPayload {
    sessionResponse: any;
    loginResponse: LoginDetails;
    serviceStatus: ServiceStatus;
    success: boolean;
}

export const login = createAsyncThunk<LoginReduxPayload, Credentials, { state: RootState }>(
    'authentication/login',
    async (credentials: Credentials, thunkAPI) => {
        const API = new AuthenticationService(thunkAPI.getState().dev.apiUrl, thunkAPI.getState().authentication.token);

        // login and check session must be done sequentially to avoid checking of session before login
        const [loginResponse, loginStatus] = await API.login(credentials);
        const [sessionResponse, sessionStatus] = await API.checkSession();

        const success = loginStatus === ServiceStatus.Success && sessionStatus === ServiceStatus.Success;
        return {
            loginResponse,
            sessionResponse,
            success,
            serviceStatus: sessionStatus,
        } as LoginReduxPayload;
    }
);

export const logout = createAsyncThunk<boolean, void, { state: RootState }>(
    'authentication/logout',
    async (_, thunkAPI) => {
        const API = new AuthenticationService(thunkAPI.getState().dev.apiUrl, thunkAPI.getState().authentication.token);
        const [logoutResponse, logoutStatus] = await API.logout();
        return logoutStatus === ServiceStatus.Success;
    }
);

export const checkSession = createAsyncThunk<CheckSessionReduxPayload, void, { state: RootState }>(
    'authentication/checkSession',
    async (_, thunkAPI) => {
        console.log(thunkAPI.getState().authentication.token);
        const API = new AuthenticationService(thunkAPI.getState().dev.apiUrl, thunkAPI.getState().authentication.token);
        const [sessionResponse, sessionStatus] = await API.checkSession();
        const success = sessionStatus === ServiceStatus.Success;
        return { sessionResponse, success };
    }
);

const authenticationReducer = createSlice({
    name: 'authentication',
    initialState: {
        isAuthenticated: false,
        token: '' as string,
        user: {} as UserDetails,
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
      setAuthenticated: (state, action) => {
          state.isAuthenticated = action.payload;
          if (action.payload === false){
            state.user = {};
            state.token = '';
          }
      },
      setUserDetails: (state, action: PayloadAction<UserDetails>) => {
          state.user = action.payload;
      }
    },
    extraReducers: builder => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.isAuthenticated = action.payload.success;
                state.user = action.payload.loginResponse?.userDetails || {};

                if (action.payload.success) {
                    console.info('login success');
                } else {
                    console.info('login fail');
                }
            })
            .addCase(checkSession.fulfilled, (state, action) => {
                state.user = action.payload.sessionResponse.userDetails || {};
                state.isAuthenticated = !!action.payload.success;

                if (!!!action.payload.success) {
                    sessionStorage.clear();
                }
            })
            .addCase(logout.fulfilled, (state, action) => {
                if (action.payload) {
                    state.isAuthenticated = false;
                    state.token = '';
                    state.user = {};
                    sessionStorage.clear();
                    console.info('logout');
                }
            });
    },
});

export const selectToken = (state: RootState) => state.authentication.token;
export const selectUsername = (state: RootState) => state.authentication.user?.username;
export const selectIsAuthenticated = (state: RootState) => state.authentication.isAuthenticated;
export const selectRoles = (state: RootState) => state.authentication.user?.roles;
export const selectUser = (state: RootState) => state.authentication.user;

export const { setToken, setAuthenticated, setUserDetails } = authenticationReducer.actions;

export default authenticationReducer.reducer;
