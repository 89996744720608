// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dXD63gsIUtXjfOCi5UTn {\n  position: relative;\n  display: flex;\n  gap: 8px;\n  background-color: var(--neutral-10);\n}\n.dXD63gsIUtXjfOCi5UTn .iLaw2UNxQoJqwBm6A_4P {\n  position: absolute;\n  fill: var(--primary-default);\n  height: 3px;\n  width: 1px;\n  transform-origin: center;\n  bottom: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/TabBar/TabBar.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,QAAA;EACA,mCAAA;AACJ;AACI;EACI,kBAAA;EACA,4BAAA;EACA,WAAA;EACA,UAAA;EACA,wBAAA;EAEA,SAAA;AAAR","sourcesContent":[".tab-bar {\r\n    position: relative;\r\n    display: flex;\r\n    gap: 8px;\r\n    background-color: var(--neutral-10);\r\n\r\n    .highlight {\r\n        position: absolute;\r\n        fill: var(--primary-default);\r\n        height: 3px;\r\n        width: 1px;\r\n        transform-origin: center;\r\n\r\n        bottom: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab-bar": "dXD63gsIUtXjfOCi5UTn",
	"highlight": "iLaw2UNxQoJqwBm6A_4P"
};
export default ___CSS_LOADER_EXPORT___;
