/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientBasicDetails
 */
export interface ClientBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof ClientBasicDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBasicDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBasicDetails
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientBasicDetails
     */
    systemClient?: boolean;
}

/**
 * Check if a given object implements the ClientBasicDetails interface.
 */
export function instanceOfClientBasicDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClientBasicDetailsFromJSON(json: any): ClientBasicDetails {
    return ClientBasicDetailsFromJSONTyped(json, false);
}

export function ClientBasicDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientBasicDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'systemClient': !exists(json, 'systemClient') ? undefined : json['systemClient'],
    };
}

export function ClientBasicDetailsToJSON(value?: ClientBasicDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'name': value.name,
        'description': value.description,
        'systemClient': value.systemClient,
    };
}

