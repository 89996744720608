import styles from './Notification.module.scss';
import CloseIcon from '../../assets/close.svg';
import ErrorIcon from '../../assets/error.svg';

interface NotificationProps {
    status?: 'danger' | 'info' | 'success' | 'warning';
    visible?: boolean;
    children?: React.ReactNode;
    title?: string;
    onClose?: () => void;
}

function Notification({ status = 'info', visible = true, children, title, onClose }: NotificationProps) {
    const onCloseHandler = () => {
        if (onClose) {
            onClose();
        } else {
            console.info(`${Notification.name}: onClose handler not provided`);
        }
    };

    return (
        <div className={`${styles[`notification-${status}`]} ${styles['notification']}`}>
            <div className={styles['error']}>
                <ErrorIcon />
            </div>
            <div className={styles['frame']}>
                <div className={styles['header']}>
                    <div className={styles['title']}>{title}</div>
                    <i className={styles['close']} onClick={onCloseHandler}>
                        <CloseIcon />
                    </i>
                </div>

                <div className={styles['content']}>{children}</div>
            </div>
        </div>
    );
}

export default Notification;
