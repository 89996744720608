/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventLogItem
 */
export interface EventLogItem {
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    type?: EventLogItemTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof EventLogItem
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    tag?: string;
}


/**
 * @export
 */
export const EventLogItemTypeEnum = {
    Event: 'EVENT',
    Handle: 'HANDLE',
    Unhandle: 'UNHANDLE',
    Notify: 'NOTIFY',
    NotifyFail: 'NOTIFY_FAIL',
    Comment: 'COMMENT',
    Assign: 'ASSIGN',
    StateChange: 'STATE_CHANGE',
    UserClose: 'USER_CLOSE'
} as const;
export type EventLogItemTypeEnum = typeof EventLogItemTypeEnum[keyof typeof EventLogItemTypeEnum];


/**
 * Check if a given object implements the EventLogItem interface.
 */
export function instanceOfEventLogItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventLogItemFromJSON(json: any): EventLogItem {
    return EventLogItemFromJSONTyped(json, false);
}

export function EventLogItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventLogItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'objectId': !exists(json, 'object_id') ? undefined : json['object_id'],
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
    };
}

export function EventLogItemToJSON(value?: EventLogItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'title': value.title,
        'description': value.description,
        'object_id': value.objectId,
        'tag': value.tag,
    };
}

