/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentConnectionDetails } from './AgentConnectionDetails';
import {
    AgentConnectionDetailsFromJSON,
    AgentConnectionDetailsFromJSONTyped,
    AgentConnectionDetailsToJSON,
} from './AgentConnectionDetails';
import type { ComponentBasicDetails } from './ComponentBasicDetails';
import {
    ComponentBasicDetailsFromJSON,
    ComponentBasicDetailsFromJSONTyped,
    ComponentBasicDetailsToJSON,
} from './ComponentBasicDetails';

/**
 * 
 * @export
 * @interface AgentDetails
 */
export interface AgentDetails {
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    publicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    status?: AgentDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    type?: AgentDetailsTypeEnum;
    /**
     * 
     * @type {ComponentBasicDetails}
     * @memberof AgentDetails
     */
    component?: ComponentBasicDetails;
    /**
     * 
     * @type {AgentConnectionDetails}
     * @memberof AgentDetails
     */
    connectionInfo?: AgentConnectionDetails;
}


/**
 * @export
 */
export const AgentDetailsStatusEnum = {
    Active: 'ACTIVE',
    Pending: 'PENDING',
    Disabled: 'DISABLED'
} as const;
export type AgentDetailsStatusEnum = typeof AgentDetailsStatusEnum[keyof typeof AgentDetailsStatusEnum];

/**
 * @export
 */
export const AgentDetailsTypeEnum = {
    Zabbix: 'ZABBIX',
    Freshdesk: 'FRESHDESK',
    Gmail: 'GMAIL'
} as const;
export type AgentDetailsTypeEnum = typeof AgentDetailsTypeEnum[keyof typeof AgentDetailsTypeEnum];


/**
 * Check if a given object implements the AgentDetails interface.
 */
export function instanceOfAgentDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgentDetailsFromJSON(json: any): AgentDetails {
    return AgentDetailsFromJSONTyped(json, false);
}

export function AgentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'component': !exists(json, 'component') ? undefined : ComponentBasicDetailsFromJSON(json['component']),
        'connectionInfo': !exists(json, 'connectionInfo') ? undefined : AgentConnectionDetailsFromJSON(json['connectionInfo']),
    };
}

export function AgentDetailsToJSON(value?: AgentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'name': value.name,
        'description': value.description,
        'publicKey': value.publicKey,
        'status': value.status,
        'type': value.type,
        'component': ComponentBasicDetailsToJSON(value.component),
        'connectionInfo': AgentConnectionDetailsToJSON(value.connectionInfo),
    };
}

