/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserBasicDetails } from './UserBasicDetails';
import {
    UserBasicDetailsFromJSON,
    UserBasicDetailsFromJSONTyped,
    UserBasicDetailsToJSON,
} from './UserBasicDetails';

/**
 * 
 * @export
 * @interface NotifyChainLinkDetails
 */
export interface NotifyChainLinkDetails {
    /**
     * 
     * @type {number}
     * @memberof NotifyChainLinkDetails
     */
    level?: number;
    /**
     * 
     * @type {{ [key: string]: UserBasicDetails; }}
     * @memberof NotifyChainLinkDetails
     */
    userMap?: { [key: string]: UserBasicDetails; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof NotifyChainLinkDetails
     */
    onCallMap?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof NotifyChainLinkDetails
     */
    last?: boolean;
}

/**
 * Check if a given object implements the NotifyChainLinkDetails interface.
 */
export function instanceOfNotifyChainLinkDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotifyChainLinkDetailsFromJSON(json: any): NotifyChainLinkDetails {
    return NotifyChainLinkDetailsFromJSONTyped(json, false);
}

export function NotifyChainLinkDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyChainLinkDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': !exists(json, 'level') ? undefined : json['level'],
        'userMap': !exists(json, 'userMap') ? undefined : (mapValues(json['userMap'], UserBasicDetailsFromJSON)),
        'onCallMap': !exists(json, 'onCallMap') ? undefined : json['onCallMap'],
        'last': !exists(json, 'last') ? undefined : json['last'],
    };
}

export function NotifyChainLinkDetailsToJSON(value?: NotifyChainLinkDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'userMap': value.userMap === undefined ? undefined : (mapValues(value.userMap, UserBasicDetailsToJSON)),
        'onCallMap': value.onCallMap,
        'last': value.last,
    };
}

