// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1i3c22FD3_hBNzhJAqA {\n  margin-left: 10px;\n  margin-right: 10px;\n  width: calc(var(--sidebar-width) - 20px);\n}\n\n.AMfy7qC06p6tMR3jfu4g {\n  margin-left: 10px;\n  margin-right: 10px;\n  width: calc(var(--sidebar-collapsed-width) - 20px);\n}\n\n.nrcJzHbpav05YfsXcxb_ {\n  position: absolute;\n  display: none;\n}", "",{"version":3,"sources":["webpack://./src/components/Sidebar/SidebarImage/SidebarImage.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,wCAAA;AACJ;;AAGA;EACI,iBAAA;EACA,kBAAA;EACA,kDAAA;AAAJ;;AAIA;EACI,kBAAA;EACA,aAAA;AADJ","sourcesContent":[".image {\n    margin-left: 10px;\n    margin-right: 10px;\n    width: calc(var(--sidebar-width) - 20px);\n    // transition: width var(--sidebar-transition-duration); \n}\n\n.image-collapsed {\n    margin-left: 10px;\n    margin-right: 10px;\n    width: calc(var(--sidebar-collapsed-width) - 20px);\n    // transition: width var(--sidebar-transition-duration); \n}\n\n.image-hidden {\n    position: absolute;\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "_1i3c22FD3_hBNzhJAqA",
	"image-collapsed": "AMfy7qC06p6tMR3jfu4g",
	"image-hidden": "nrcJzHbpav05YfsXcxb_"
};
export default ___CSS_LOADER_EXPORT___;
