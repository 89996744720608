import { createContext } from 'react';
import { ColorConfig } from '../../theme/color';
import { SpacingConfig } from '../../theme/spacing';
import { SpringConfig } from 'react-spring';

const ThemeContext = createContext({
    animated: false,
    animationConfig: {} as SpringConfig,
    colors: {} as ColorConfig,
    spacing: {},
    setColors: (_colors: ColorConfig) => {},
    setSpacing: (_spacing: SpacingConfig) => {},
});

export default ThemeContext;
