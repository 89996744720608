import {useEffect, useState} from 'react';
import { IoAddOutline, IoCheckmarkCircleOutline, IoSaveOutline, IoSettingsOutline } from 'react-icons/io5';

import {
  AlertBasicDetailsComponentStateEnum,
    ComponentDetails,
    PerentieRuleDefinition,
    PerentieRuleDefinitionStateEnum,
    ReceivedEvent,
    ReceivedEventSeverityEnum,
    TestRuleDefnRequest,
} from '@perentie/common';
import { Button, ContainerCard, Label} from '@systemic-design-framework/react';

//@ts-ignore
import styles from './ManageRules.module.scss';
import {perDS} from "../../datasource/WebDS";
import { Text, TextArea } from '../../components/Input';

function ManageRulesPage() {
    const [currentRuleSet, setCurrentRuleSet] = useState<string>();
    const [updatedRuleSet, setUpdatedRuleSet] = useState<string>("");
    const [showRuleSet, setShowRuleSet] = useState<boolean>(false);

    const [components, setComponents] = useState<ComponentDetails[]>([]);
    const [componentId, setComponentId] = useState<string>("");
    const [isComponentSelected, setIsComponentSelected] = useState<boolean>(false);

    const [changeCancelled, setChangeCancelled] = useState<boolean>(false);

    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const [canValidateTest, setCanValidateTest] = useState<boolean>(false);
    const [isTestValidated, setIsTestValidated] = useState<boolean>();


    const [testRuleSet, setTestRuleSet] = useState<string>("");

    const [eventTitle, setEventTitle] = useState<string>("");
    const [eventSeverity, setEventSeverity] = useState<string>("");

    const [ruleName, setRuleName] = useState<string>("<NAME>");
    const [ruleState, setRuleState] = useState<string>("<STATE>");
    const [ruleCondition, setRuleCondition] = useState<string>("<{CONDITION}>");
    const [ruleDescription, setRuleDescription] = useState<string>("<DESCRIPTION>");
    const [rulePriority, setRulePriority] = useState<string>("<PRIORITY>");

    const [canAddToSet, setCanAddToSet] = useState<boolean>(!false);
    const [canTest, setCanTest] = useState<boolean>(!false);
    
    const [ruleCount, setRuleCount] = useState<number>(0);

    const [eventSeverityStates, setEventSeverityStates] = useState<string[]>();
    const [componentStates, setComponentStates] = useState<string[]>();
    const [usePriority, setUsePriority] = useState<boolean>(false);

    useEffect(() => {
        perDS.userGetAllComponents().then(resp => {
          setComponents(resp);  
        });
    },[]);

    useEffect(() => {
      if (updatedRuleSet) {
        setRuleCount(updatedRuleSet.split('}}').length-1);
      }
    },[updatedRuleSet]);

    useEffect(() => {
      updateTestRuleSet();
    },[ruleName, ruleState, ruleCondition, ruleDescription, rulePriority]);

    useEffect(() => {
      updateDummyEvent();
    },[eventTitle, eventSeverity]);
    
    useEffect(() => {
      setComponentStates(Object.values(AlertBasicDetailsComponentStateEnum).filter(s => s != AlertBasicDetailsComponentStateEnum.Ok && s != AlertBasicDetailsComponentStateEnum.Unknown))
      setEventSeverityStates(Object.values(ReceivedEventSeverityEnum).filter(s => s != ReceivedEventSeverityEnum.Ok));
    },[]);

    useEffect(() => {
      if (componentId) {
        perDS.getComponentRuleSet(componentId).then(resp => {
          const filtered = resp.replaceAll('\n', '');
          const updated = filtered.replaceAll('}}', '}}\n\n');
          setCurrentRuleSet(updated);
          setUpdatedRuleSet(updated);
          setIsComponentSelected(true);
          setShowRuleSet(true);
        }).catch(err => {
          console.error("getComponentRuleSet fail: ", err);
          alert("Failed getting component rule set: " + err + "\n\n\n\n" + "This normally happens when the components rules are set to [] instead of null in the config file.");
        });
      }
    },[componentId]);

    const selectComponent = (event: any) => {
      setChangeCancelled(false);
      if (canValidate) {
        if (!confirm("Are you sure you want to change components. \n\nAny changes you've made to the rule set will not be saved.")) {
          setChangeCancelled(true);
          return;
        }
        setCanValidate(false);
      }

      const componentId = event.target.value;
      setShowRuleSet(false);

      if (componentId) {
        setComponentId(componentId);
      }
      else {
        setComponentId(componentId);
        setIsComponentSelected(false);
      }
      return;
    };

    const selectState = (event: any) => {
      const state = event.target.value;
      setRuleState(state);
    };

    const selectSeverity = (event: any) => {
      const severity = event.target.value;
      setEventSeverity(severity);
    };

    const compareRuleSet = (event: any) => {
      const updatedRuleSet = event.target.value;

      if (currentRuleSet == updatedRuleSet) {
        setCanValidate(false);
        setUpdatedRuleSet(updatedRuleSet);
      }
      else {
        setCanValidate(true);
        setIsValidated(false);
        setUpdatedRuleSet(updatedRuleSet);
      }
    };

    const validateRuleSet = () => {
      perDS.validateRuleSet(componentId, updatedRuleSet).then(resp => {
        alert("Successfully validated component rule set.");
        setCanValidate(false);
        setIsValidated(true);
      }).catch(err => {
        alert("(ERROR) \n\n" + err);
      });
    };

    const validateTestRuleSet = () => {
      perDS.validateRuleSet(componentId, testRuleSet).then(resp => {
        alert("Successfully validated test rule set.");
        setCanValidateTest(false);
        setIsTestValidated(true);
        setCanAddToSet(!true);
      }).catch(err => {
        alert("(ERROR) \n\n" + err);
      });
    }

    const updateRuleSet = () => {
      perDS.updateComponentRuleSet(componentId, updatedRuleSet).then(resp => {
        alert("Successfully updated component rule set.");
        setIsValidated(false);
        setCurrentRuleSet(resp.toString());
      }).catch(err => {
        alert("(ERROR) \n\n" + err);
      });
    };

    const setTestRuleValue = (event: any) => {
      const value = event.target.value;
      setTestRuleSet(value);
    }

    function getEnumValue(str: string, type:any): any | undefined {
      const enumKeys = Object.keys(type) as (keyof typeof type)[];
      for (const key of enumKeys) {
          if (type[key] === str) {
              return type[key];
          }
      }
      return undefined; // not found
    };

    const testUserRuleSet = () => {
      let defn: PerentieRuleDefinition = {};
      defn.name = ruleName;
      defn.state = getEnumValue(ruleState, PerentieRuleDefinitionStateEnum);
      defn.condition = ruleCondition;
      defn.description = ruleDescription;
      defn.valid = true;

      let event: ReceivedEvent = {};
      event.title = eventTitle;
      event.severity = getEnumValue(eventSeverity, ReceivedEventSeverityEnum);

      let request: TestRuleDefnRequest = {};
      request.definition = defn;
      request.event = event;

      perDS.testRuleSet(componentId, request).then(resp => {
        console.log("PerentieRuleDefn -- ", resp);
        alert("The rule was triggered successfully.");
      }).catch(err => {
        alert(err);
      })
    };

    const appendToRuleSet = () => {
      setShowRuleSet(false);
      
      const filtered = updatedRuleSet.replaceAll('\n', '');
      const existingRuleSet = filtered.replaceAll('}}', '}}\n\n');

      let ruleSet = testRuleSet;
      if (usePriority) {
        ruleSet = ruleSet.replace(`)`, `,'${rulePriority}')`);
      }

      let testRules = ruleSet.replaceAll('\n', '');
      let updated = existingRuleSet + testRules.replaceAll('}}', '}}\n\n');
      
      setUpdatedRuleSet(updated);
      setCanValidate(true);

      setTestRuleSet("");
      setUsePriority(false);

      setTimeout(() => {
        setShowRuleSet(true);
        setCanAddToSet(!false);
        setCanValidateTest(false);
        setCanTest(!false);
      }, 1);
    };

    const updateTestRuleSet = () => {
      if (ruleName != "<NAME>" || ruleState != "<STATE>" || ruleCondition != "<{CONDITION}>" || ruleDescription != "<DESCRIPTION>") {
        const ruleBuilder = ruleName.toLowerCase().replaceAll(' ', '_') + "(" + ruleState + ",'" + ruleName + "','" + ruleDescription + "') { " + ruleCondition + "}";
        setTestRuleSet(ruleBuilder);
      }

      if (ruleName != "<NAME>" && ruleState != "<STATE>" && ruleCondition != "<{CONDITION}>" && ruleDescription != "<DESCRIPTION>") {
        setTimeout(() => {
          setCanValidateTest(true);
          setIsTestValidated(false);
        }, 1);
      }
      else {
        setTimeout(() => {
          setCanValidateTest(false);
        }, 1);
      }

      if (ruleName == "" || ruleState == "" || ruleCondition == "" || ruleDescription == "") {
        setTimeout(() => {
          setCanValidateTest(false);
          setIsTestValidated(false);  
        }, 1);
        
        ruleName == "" ? setRuleName("<NAME>") : "";
        ruleState == "" ? setRuleState("<STATE>") : "";
        ruleCondition == "" ? setRuleCondition("<{CONDITION}>") : "";
        ruleDescription == "" ? setRuleDescription("<DESCRTIPTION>") : "";
      }
    }

    const updateDummyEvent = () => {
      if (isTestValidated) {
        setCanValidateTest(false);
      }
      else {
        setCanValidateTest(true);
      }
      if (eventTitle.length > 0 && eventSeverity.length > 0 && isTestValidated) {
        setCanTest(!true);
      }
      else {
        setCanTest(!false);
      }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '15px', gap: '15px', height: '100%', overflow: 'scroll'}}>
          <ContainerCard HeaderComponent={
            <div style={{display:'flex', justifyContent:'space-between', flex:'1'}}>
              Component
            </div>
          }>
            <Label text='Select a component:'></Label>
            <select style={{height: '30px', paddingLeft: '15px'}} id={'component-select'} onChange={selectComponent}>
              <option value={''}>Select...</option>
              {components.map(c => {
                if (changeCancelled) {
                  if (c.objectId === componentId) {
                    return (
                      <option value={c.objectId} selected>
                        {c.name}
                      </option>
                    );
                  }
                  return (
                    <option value={c.objectId}>
                      {c.name}
                    </option>
                  );
                }
                else {
                  return (
                    <option value={c.objectId}>
                      {c.name}
                    </option>
                  );
                }
              })}
            </select>
          </ContainerCard>

          {isComponentSelected
          ? 
          <ContainerCard HeaderComponent={
            <div style={{display:'flex', justifyContent:'space-between', flex:'1'}}>
              Edit Rule Set
            </div>
          }>
            {showRuleSet
            ? 
            <>
              <TextArea id='component-ruleset' label="Component Rule Set" value={updatedRuleSet} rows={(ruleCount*2)} onInput={compareRuleSet}></TextArea>
              <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: '20px'}}>
                <Button title='Validate' Icon={<IoCheckmarkCircleOutline />} disabled={!canValidate} onClick={validateRuleSet}/>
                <Button title='Save' Icon={<IoSaveOutline />} disabled={!isValidated} onClick={updateRuleSet}/>
              </div>
            </>
            
            : <></>
            }
          </ContainerCard>
          : 
          <></>
          }

          {isComponentSelected
          ? 
          <ContainerCard HeaderComponent={
            <div style={{display:'flex', justifyContent:'space-between', flex:'1'}}>
              Test Rule Set
            </div>
          }>
            {showRuleSet
            ? 
            <>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: '10%'}}>
              <div style={{width: '40%', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <Text id="ruleName" label="Name" placeholder='e.g. Default Info' valueChange={setRuleName}></Text>
                {/* <Text id="ruleState" label="State" placeholder='e.g. INFO' valueChange={setRuleState}></Text> */}
                <Label text='State'></Label>
                <select style={{height: '45px', paddingLeft: '10px'}} id={'state-select'} onChange={selectState}>
                  <option value="">Select a state...</option>
                  {componentStates?.map(s => {
                    return (
                      <option value={s}>
                        {s}
                      </option>
                    );
                  })}
                </select>
                <Text id="ruleCondition" label="Condition" placeholder='e.g. {severity <= LOW}' valueChange={setRuleCondition}></Text>
                <Text id="ruleDescription" label="Description" placeholder='e.g. Map (Info,Low) to INFO' valueChange={setRuleDescription}></Text>

                <label style={{fontSize: '14px'}}><input type='checkbox' defaultChecked={usePriority} onChange={(e) => {setUsePriority(e?.target?.checked)}}/>&nbsp;&nbsp;Use Priority</label>
                {usePriority === true && (
                  <Text id="rulePriority" label="Priority" placeholder='e.g. 1-32767' valueChange={setRulePriority}></Text>
                )}
              </div>
              <div style={{width: '50%', display: 'flex', flexDirection: 'column', gap: '30px'}}>
                <div>
                  <TextArea id='test-ruleset' disabled={true} label="Test Rule Set" value={testRuleSet} placeholder="<name>(<STATE>,'<NAME>','<DESCRIPTION>'){ {CONDITION}}" rows={1} onInput={setTestRuleValue}></TextArea>
                </div>
                <div>
                  <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <Text id='event-title' disabled={!isTestValidated} label='Event Title' valueChange={setEventTitle}></Text>
                    {/* <Text id='event-severity' disabled={!isTestValidated} label='Event Severity' placeholder='e.g. LOW' valueChange={setEventSeverity}></Text> */}
                    <Label text='Event Severity'></Label>
                    <select style={{height: '45px', paddingLeft: '10px'}} id={'severity-select'} onChange={selectSeverity} disabled={!isTestValidated}>
                      <option value="">Select a state...</option>
                      {eventSeverityStates?.map(s => {
                        return (
                          <option value={s}>
                            {s}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: '20px', alignItems: 'center'}}>
                <i style={{color: 'green', fontSize: '12px', display: isTestValidated ? "block" : "none"}}>Validated</i>
                <Button title='Validate' Icon={<IoCheckmarkCircleOutline />} disabled={!canValidateTest} onClick={validateTestRuleSet}/>
                <Button title='Test' Icon={<IoSettingsOutline />}  disabled={canTest} onClick={testUserRuleSet}/>
                <Button title='Add to Rule Set' Icon={<IoAddOutline />} disabled={canTest} onClick={appendToRuleSet}/>
              </div>
            </>
            : <></>
            }
          </ContainerCard>
          : 
          <></>
          }
        </div>
    );
}

export default ManageRulesPage;
