import { CSSProperties, useEffect, useState } from 'react';
import { animated, AnimatedProps, useChain, useSpring } from 'react-spring';
import TabBarContext from './TabBarContext';
import styles from './TabBar.module.scss';
import Tab from './Tab/Tab';
import useAnimationConfig from '../../hooks/useAnimationConfig';

interface TabBarProps {
    children: React.ReactElement<typeof Tab>[] | React.ReactElement<typeof Tab>;
    size?: 's' | 'm';
}

function TabBar({ children, size = 's' }: TabBarProps) {
    const [current, setCurrent] = useState<string | number>('');
    const [currentRef, setCurrentRef] = useState<React.RefObject<HTMLElement>>();
    const [shouldAnimate, animationConfig] = useAnimationConfig();
    const [width, setWidth] = useState<number>(0);
    const [position, setPosition] = useState<number>(0);
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!!currentRef) {
            if (currentRef.current) {
                setVisible(!!currentRef.current);
                setWidth(currentRef.current.offsetWidth);
                setPosition(currentRef.current.offsetLeft);
            }
        }
    }, [currentRef]);

    const animatedHighlightStyle = useSpring({
        to: {
            opacity: visible ? 1 : 0,
            transform: `translateX(${position + width / 2}px) scaleX(${width})`,
        } as AnimatedProps<CSSProperties>,
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    return (
        <TabBarContext.Provider value={{ size, current, setCurrent, setCurrentRef }}>
            <div className={styles['tab-bar']}>
                {children}
                <animated.svg className={styles['highlight']} style={animatedHighlightStyle}>
                    <rect width="100%" height="100%" />
                </animated.svg>
            </div>
        </TabBarContext.Provider>
    );
}

export default TabBar;
