import {StateHistoryDetails} from "@perentie/common";
import StateText from "../../components/StateText/StateText";

export interface StateHistoryComponentProps {
  historyItem: StateHistoryDetails;
}

function StateHistoryComponent(props: StateHistoryComponentProps){
  const {historyItem} = props;
  return (
    <div style={{border: '1px solid darkgray', borderRadius: 6, padding: 8}}>
      <p>{historyItem.timeStamp?.toLocaleString()}</p>
      <p style={{margin: '0 0 4px 8px'}}><StateText>{historyItem.lastState}</StateText> --&gt; <StateText>{historyItem.nextState}</StateText></p>
      <p style={{margin: '0 0 4px 8px'}}>Trigger: {historyItem.ruleTrigger == null ? '-' : historyItem.ruleTrigger}</p>
      <p style={{margin: '0 0 4px 8px'}}>Event Count: {historyItem.events?.length}</p>
    </div>
  )
}

export default StateHistoryComponent;
