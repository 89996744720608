import { createContext } from 'react';
import { UserDetails } from '../api';

export interface PerentieServiceContextProps {
    apiUrl: string,
    apiToken: string,
    authenticated: boolean,
    userDetails?: UserDetails | null | undefined,
    setAuthenticated?: (_: boolean) => void | undefined,
    setUserDetails?: (_: UserDetails) => void | undefined,
    setApiUrl?: (_: string) => void | undefined,
    setApiToken?: (_: string) => void | undefined,
}

// const PerentieServiceContext = createContext({
//     apiUrl: '',
//     apiToken: '',
//     authenticated: false as boolean,
//     userDetails: {} as UserDetails,
//     testSource: DataSource,
//
//     setAuthenticated: (_: boolean) => {},
//     setUserDetails: (_: UserDetails) => {},
//     setApiUrl: (_: string) => {},
//     setApiToken: (_: string) => {},
// });

const PerentieServiceContext = createContext<PerentieServiceContextProps>({
    apiUrl: '',
    apiToken: '',
    authenticated: false,
});

export default PerentieServiceContext;
