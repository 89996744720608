import { CSSProperties } from 'react';
import styles from './Button.module.scss';

export type ButtonVariant =
    | 'primary'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-light'
    | 'danger'
    | 'ghost';

export type ButtonSize = 's' | 'sm' | 'm' | 'l';

export interface ButtonProps {
    variant?: ButtonVariant;
    size?: ButtonSize;
    title?: string;
    disabled?: boolean;
    onClick?: () => void;
    Icon?: JSX.Element;
    iconPosition?: 'left' | 'right';
    type?: 'button' | 'submit' | 'reset';
}

function Button({
    onClick,
    variant = 'primary',
    size = 'sm',
    title,
    Icon,
    disabled,
    iconPosition = 'left',
    type = 'button'
}: ButtonProps) {
    const style: CSSProperties =
        iconPosition === 'left' ? { flexDirection: 'row' } : { flexDirection: 'row-reverse' };

    return (
        <button
            className={styles[`${variant}-${size}`]}
            onClick={onClick}
            disabled={disabled}
            type={type}
            style={style}>
            {Icon && <div>{Icon}</div>}
            {title && <span>{title}</span>}
        </button>
    );
}
export default Button;
