import { CSSProperties } from 'react';
import styles from './Table.module.scss';

interface TableProps<T> {
    data: Array<T>;
    renderItem: (item: T, index: number) => JSX.Element;
    sorter?: (a: T, b: T) => number;
    filter?: (item: T) => boolean;
    border?: boolean;
    divider?: boolean;
    HeaderComponent?: JSX.Element;
    FooterComponent?: JSX.Element;
}

function Table<T>({
    data,
    renderItem,
    HeaderComponent,
    FooterComponent,
    border,
    filter = (_item) => true,
    sorter = (_a, _b) => 0,
}: TableProps<T>) {
    const style: CSSProperties = {
        borderStyle: border ? undefined : 'hidden',
    };

    const tableData = data.filter(filter).sort(sorter);

    return (
        <div className={styles['table-container']}>
            <div className={styles['table']} style={style}>
                {HeaderComponent && <div className={styles['header']}>{HeaderComponent}</div>}
                <div className={styles['body']}>
                    {tableData.map((item, index) => renderItem(item, index))}
                </div>
                {FooterComponent && <div className={styles['footer']}>{FooterComponent}</div>}
            </div>
        </div>
    );
}

export default Table;
