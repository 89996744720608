import ComponentsListPage from "./ComponentsListPage";
import {ComponentBasicDetails, ComponentDetails, useAdminService, useComponentService} from "@perentie/common";
import {useEffect, useState} from "react";
import componentsListPage from "./ComponentsListPage";
import {perDS} from "../../datasource/WebDS";

function ComponentsListContainer(){

    // const {getComponents} = useComponentService();
    const [components, setComponents] = useState<ComponentDetails[]>([]);
    const [config, setConfig] = useState<string>('');

    //const {uploadConfig} = useAdminService();

    const onUpload = (file: File) => {
        console.log(" on upload ",file);
        let data = new FormData();
        data.set("file", file);
        perDS.uploadConfig(file).then( () => {
            perDS.userGetAllComponents().then(res => setComponents(res));
            perDS.getComponentConfigString('all').then(res => {
                setConfig(res);
            });
        });
    }

    useEffect( () => {
        perDS.userGetAllComponents().then(res => setComponents(res));
        perDS.getComponentConfigString('all').then(res => {
            setConfig(res);
        });

    },[]);

    return (
        <ComponentsListPage components={components} uploadConfig={onUpload} rawConfig={config}/>
    )
}

export default ComponentsListContainer;
