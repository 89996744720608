/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Credentials,
  RESTResultLoginDetails,
  RESTResultString,
} from '../models/index';
import {
    CredentialsFromJSON,
    CredentialsToJSON,
    RESTResultLoginDetailsFromJSON,
    RESTResultLoginDetailsToJSON,
    RESTResultStringFromJSON,
    RESTResultStringToJSON,
} from '../models/index';

export interface AuthenticateRequest {
    credentials?: Credentials;
}

/**
 * PerentieAuthenticateWSApi - interface
 * 
 * @export
 * @interface PerentieAuthenticateWSApiInterface
 */
export interface PerentieAuthenticateWSApiInterface {
    /**
     * Authenticate users
     * @param {Credentials} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWSApiInterface
     */
    authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultLoginDetails>>;

    /**
     * Authenticate users
     */
    authenticate(credentials?: Credentials, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultLoginDetails>;

    /**
     * Check if Session still authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWSApiInterface
     */
    checkSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultLoginDetails>>;

    /**
     * Check if Session still authenticated
     */
    checkSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultLoginDetails>;

    /**
     * Logout Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWSApiInterface
     */
    logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>>;

    /**
     * Logout Session
     */
    logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString>;

}

/**
 * 
 */
export class PerentieAuthenticateWSApi extends runtime.BaseAPI implements PerentieAuthenticateWSApiInterface {

    /**
     * Authenticate users
     */
    async authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultLoginDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultLoginDetailsFromJSON(jsonValue));
    }

    /**
     * Authenticate users
     */
    async authenticate(credentials?: Credentials, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultLoginDetails> {
        const response = await this.authenticateRaw({ credentials: credentials }, initOverrides);
        return await response.value();
    }

    /**
     * Check if Session still authenticated
     */
    async checkSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultLoginDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/checksession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultLoginDetailsFromJSON(jsonValue));
    }

    /**
     * Check if Session still authenticated
     */
    async checkSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultLoginDetails> {
        const response = await this.checkSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Logout Session
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     * Logout Session
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString> {
        const response = await this.logoutRaw(initOverrides);
        return await response.value();
    }

}
