// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AJfKyytrLASfmznnNfCA {\n  display: flex;\n  flex: inherit;\n  padding: 8px 16px;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/components/Table/Cell/Cell.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;AACJ","sourcesContent":[".cell {\n    display: flex;\n    flex: inherit;\n    padding: 8px 16px;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "AJfKyytrLASfmznnNfCA"
};
export default ___CSS_LOADER_EXPORT___;
