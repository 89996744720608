import { useEffect, useContext, ChangeEvent } from 'react';
import { InputProps } from '..';
import FormContext from '../FormContext';
import styles from './Checkbox.module.scss';
import { Validator } from '..';
import CheckedIcon from '../../../assets/checked.svg';

interface CheckboxProps extends InputProps {
    validate?: Validator<boolean> | Validator<boolean>[];
    defaultValue?: boolean;
}

function Checkbox({ for: self, defaultValue=false, label, disabled, validate, required }: CheckboxProps) {
    const context = useContext(FormContext);

    useEffect(() => {
        context.register(self, defaultValue, !!required, validate);
    }, [defaultValue]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        context.setValue(self, event.target.checked)
    }

    const loading = context.loading();

    return (
        <>
            <div className={styles["checkbox-container"]}>
                <label className={styles["checkbox-label"]}>
                    <input
                        name={self}
                        style={{display: "none"}}
                        type="checkbox"
                        checked={loading ? false : context.getValue(self)}
                        disabled={loading || disabled}
                        onChange={onChange}
                    />
                    <div className={styles["checkbox"]}><CheckedIcon/></div>
                    <span>{label}</span>
                </label>
            </div>
            <label className={styles["error-message"]}>
                {context.getError(self)}
            </label>
        </>
    );
}

export default Checkbox;
