import styles from './Container.module.scss';

interface ContainerProps {
    HeaderComponent?: JSX.Element;
    children?: React.ReactNode;
}

function Container({ HeaderComponent, children }: ContainerProps) {
    return (
        <div className={styles['container']}>
            {HeaderComponent && <div className={styles['header']}>{HeaderComponent}</div>}
            <div className={styles['content']}>{children}</div>
        </div>
    );
}

export default Container;
