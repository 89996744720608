/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentBasicDetails } from './ComponentBasicDetails';
import {
    ComponentBasicDetailsFromJSON,
    ComponentBasicDetailsFromJSONTyped,
    ComponentBasicDetailsToJSON,
} from './ComponentBasicDetails';

/**
 * 
 * @export
 * @interface ClientDetails
 */
export interface ClientDetails {
    /**
     * 
     * @type {string}
     * @memberof ClientDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDetails
     */
    description?: string;
    /**
     * 
     * @type {Array<ComponentBasicDetails>}
     * @memberof ClientDetails
     */
    components?: Array<ComponentBasicDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientDetails
     */
    systemClient?: boolean;
}

/**
 * Check if a given object implements the ClientDetails interface.
 */
export function instanceOfClientDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClientDetailsFromJSON(json: any): ClientDetails {
    return ClientDetailsFromJSONTyped(json, false);
}

export function ClientDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'components': !exists(json, 'components') ? undefined : ((json['components'] as Array<any>).map(ComponentBasicDetailsFromJSON)),
        'systemClient': !exists(json, 'systemClient') ? undefined : json['systemClient'],
    };
}

export function ClientDetailsToJSON(value?: ClientDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'name': value.name,
        'description': value.description,
        'components': value.components === undefined ? undefined : ((value.components as Array<any>).map(ComponentBasicDetailsToJSON)),
        'systemClient': value.systemClient,
    };
}

