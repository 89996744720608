
import styles from "./StateText.module.scss";
import {JSXElementConstructor, ReactElement} from "react";
export interface StateTextProps {
  children: any;
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
}
function StateText(props: StateTextProps){

  const state = styles[props.children] == null ? '' : styles[props.children];
  // @ts-ignore
  const classes = styles['base'] + ' ' + state + (props.className != null ? ' ' + props.className : '');

  return <span className={classes} style={props.style}>{props.children}</span>
}
export default StateText;
