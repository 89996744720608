import React, { useEffect, useState } from 'react';
import { SpringConfig } from 'react-spring';
import defaultAnimationConfig from '../../theme/animation';
import defaultColors, { ColorConfig, GenericColorConfig } from '../../theme/color';
import defaultSpacing, { SpacingConfig, GenericSpacingConfig } from '../../theme/spacing';
import ThemeContext from './ThemeContext';

export interface ThemeProviderProps {
    children: React.ReactNode;
    colors?: ColorConfig;
    spacing?: SpacingConfig;
    otherSpacing?: GenericSpacingConfig;
    otherColors?: GenericColorConfig; // custom colors not defined by systemic design framework
    animated?: boolean;
    animationConfig?: SpringConfig;
}

function ThemeProvider({
    children,
    colors = defaultColors,
    spacing = defaultSpacing,
    otherColors = {},
    animated,
    animationConfig = defaultAnimationConfig,
}: ThemeProviderProps) {
    const [currentColor, setCurrentColor] = useState({
        ...colors,
        ...otherColors,
    });

    const [currentSpacing, setCurrentSpacing] = useState({
        ...spacing,
    });

    // when the current theme is changed, set the global stylesheet
    useEffect(() => {
        Object.entries({ ...currentColor, ...currentSpacing }).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, value!);
        });
    }, [currentColor, currentSpacing]);

    return (
        <ThemeContext.Provider
            value={{
                colors: currentColor,
                spacing: currentSpacing,
                setColors: setCurrentColor,
                setSpacing: setCurrentSpacing,
                animated: !!animated,
                animationConfig: animationConfig,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}

export default ThemeProvider;
