import {
  AbstractBaseDatasource,
  ActiveEventDetails,
  AgentBasicDetails,
  AgentDetails,
  AgentRequest,
  AlertBasicDetails,
  AlertDetails,
  Configuration,
  ConfigurationParameters,
  LoginDetails,
  PerentieAuthenticateWSApi,
  PerentieDatasourceInterface,
  ResponseErrorHandler,
  TestPerentieWSApi,
  PerentieAdminWSApi,
  ComponentBasicDetails,
  HeaderMiddleWare,
  PerentieAlertsWSApi,
  PerentieComponentWSApi,
  NotifyChainDetails,
  NotifyChainRequest,
  UserDetails,
  UserRoleDetailsNameEnum,
  ScheduleDetails,
  StateHistoryDetails,
  ComponentDetails,
  ClientDetails,
  PerentieAlertHandlingWSApi, AlertActionRequestTypeEnum, UserBasicDetails, TestRuleDefnRequest,
  PerentieRuleDefinition, EventLogItem
} from "@perentie/common";
import {Store} from "redux";

class PerentieWebDS extends AbstractBaseDatasource
  implements PerentieDatasourceInterface{

  private configParams: ConfigurationParameters = {};
  private store: Store;

  private authApi: PerentieAuthenticateWSApi;
  private testApi: TestPerentieWSApi;
  private adminApi: PerentieAdminWSApi
  private alertsApi: PerentieAlertsWSApi;
  private componentApi: PerentieComponentWSApi;
  private handleApi: PerentieAlertHandlingWSApi;
  private token: string = '';

  private KEY_USER_TOKEN = 'PERTKN';

  constructor(path: string, store: Store) {
    console.log('WebDS base', path);
    super(path, new WebErrorHandler(store));
    this.loadSavedUserToken().then( stored => {
      console.log('storedToken: ', stored);
      if (!!stored){
        this.token = stored;
      }
    });


    this.configParams.basePath = this.apiBasePath;
    this.configParams.middleware = [new HeaderMiddleWare(this.getToken.bind(this))]
    this.store = store;

    const config = new Configuration(this.configParams);
    this.authApi = new PerentieAuthenticateWSApi(config);
    this.testApi = new TestPerentieWSApi(config);
    this.adminApi = new PerentieAdminWSApi(config);
    this.alertsApi = new PerentieAlertsWSApi(config);
    this.componentApi = new PerentieComponentWSApi(config);
    this.handleApi = new PerentieAlertHandlingWSApi(config);
  }

  async getToken(): Promise<string>{
    // console.log('WebDS getToken: ', this.token);
    return Promise.resolve(this.token);
  }

  authenticate(user: string, password: string): Promise<LoginDetails> {
    return this.wrap<LoginDetails>(
      this.authApi.authenticate({username: user, password}),
    );
  }

  checkSession(): Promise<LoginDetails> {
    console.log('WebDS: Check Session');
    return new Promise<LoginDetails>( (resolve, reject) => {
      return this.authApi.checkSession().then(res => {
        resolve(res.data!);
      }).catch( reason => {
        console.log(' checkSession no good', reason);
        reject(reason);
      });
    });

    // this.authApi.checkSession().then(( resp ) => {
    //   return new Promise<SessionDetails>( (resolve,reject) => {
    //     resolve(resp.data!);
    //   });
    // }).catch( reason => {
    //   return
    // })
  }

  getPerentieUsers(): Promise<Array<UserDetails>> {
    return this.wrap<Array<UserDetails>>(this.adminApi.getUsers());
  }

  updateUserRoles(userId: string, roles: UserRoleDetailsNameEnum[]): Promise<UserDetails> {
    return this.wrap<UserDetails>(this.adminApi.updateUserRoles({objectId: userId, roles: roles}));
  }

  createClient(name: string, description: string, components?: string[]): Promise<ClientDetails> {
    return this.wrap<ClientDetails>(this.adminApi.createPerentieClient({name,description,components}));
  }

  getPerentieClients(): Promise<Array<ClientDetails>> {
    return this.wrap<Array<ClientDetails>>(this.adminApi.getAllPerentieClients());
  }

  updatePerentieClient(clientId: string, name: string, description: string, components?: string[]): Promise<Array<ClientDetails>> {
    return this.wrap<Array<ClientDetails>>(this.adminApi.updatePerentieClient(clientId, {name, description, components}));
  }


  getActiveAlerts(): Promise<Array<AlertBasicDetails>> {
    return this.wrap<Array<AlertBasicDetails>>(this.alertsApi.getActiveAlerts());
  }

  getOpenAlerts(): Promise<Array<AlertBasicDetails>> {
    return this.wrap<Array<AlertBasicDetails>>(this.alertsApi.getOpenAlerts());
  }

  getRecentAlerts(limit: number): Promise<Array<AlertBasicDetails>> {
    return this.wrap<Array<AlertBasicDetails>>(this.alertsApi.getRecentAlerts(limit));
  }

  getAlertDetails(alertId: string): Promise<AlertDetails> {
    return this.wrap<AlertDetails>(this.alertsApi.getAlertDetails(alertId));
  }

  getAlertEventSummary(alertId: string): Promise<Array<ActiveEventDetails>> {
    return this.wrap<ActiveEventDetails[]>(this.alertsApi.getAlertEventSummary(alertId));
  }

  handleAlert(alertId: string): Promise<AlertDetails> {
    return this.wrap<AlertDetails>(this.handleApi.handle({objectId: alertId}));
  }

  unHandleAlert(alertId: string): Promise<AlertDetails> {
    return this.wrap<AlertDetails>(this.handleApi.unHandle({objectId: alertId}));
  }

  addCommentToAlert(alertId: string, comment: string): Promise<AlertDetails> {
    return this.wrap<EventLogItem>(this.alertsApi.addCommentToAlert(alertId, {type: "COMMENT", info: comment}));
  }

  assignAlert(alertId: string, toUserId: string, comment?: string): Promise<AlertDetails> {
    return this.wrap<AlertDetails>(this.handleApi.assignAlert({objectId: alertId, userId: toUserId, type: "ASSIGN", info: comment}));
  }

  getUserHandlingOptions(alertId: string, actionType: AlertActionRequestTypeEnum): Promise<UserBasicDetails[]> {
    return this.wrap<UserBasicDetails[]>(this.handleApi.getUserHandlingOptions(alertId, actionType));
  }



  closeAlert(alertId: string): Promise<AlertDetails> {
    return this.wrap<AlertDetails>(this.alertsApi.closeAlert(alertId));
  }

  loadSavedAuthType(): Promise<"SSO" | "REGULAR" | null> {
    throw new Error('Not Done');
  }

  loadSavedUserToken(): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(this.KEY_USER_TOKEN));
  }

  logoutUser(): void {
    this.authApi.logout().then(res => {})
    .catch(err => {
      console.log('logoutUser fail ', err);
    });
    this.setUserToken('');
  }

  setUserToken(token: string): void {
    this.token = token;
    this.storeUserToken(token);
  }

  storeAuthType(authType: "SSO" | "REGULAR"): void {
  }

  storeUserToken(token: string): void {
    console.log(' StoreToken: ', token);
    localStorage.setItem(this.KEY_USER_TOKEN, token);
  }

  getAgentDetails(agentId: string): Promise<AgentDetails | null | undefined> {
    return this.wrap<AgentDetails>(this.adminApi.getAgentDetails(agentId));
  }

  getAgents(): Promise<Array<AgentBasicDetails>> {
    return this.wrap<Array<AgentBasicDetails>>(this.adminApi.getAgents());
  }

  registerAgent(req: AgentRequest): Promise<AgentDetails> {
    return this.wrap<AgentDetails>(this.adminApi.registerAgent(req));
  }

  updateAgent(req: AgentRequest): Promise<AgentDetails> {
    return this.wrap<AgentDetails>(this.adminApi.updateAgent(req));
  }

  createComponent(name: string, description: string, agentId: string, systemIdentifier: string): Promise<ComponentDetails> {
    return this.wrap<ComponentDetails>(this.componentApi.createComponent({name, description, agentId, systemIdentifier}));
  }

  adminGetAllComponents(): Promise<Array<ComponentBasicDetails>> {
    return this.wrap<Array<ComponentBasicDetails>>(this.adminApi.getAllPerentieComponents());
  }

  userGetAllComponents(): Promise<Array<ComponentDetails>> {
    return this.wrap<Array<ComponentDetails>>(this.componentApi.getComponents());
  }

  getAllUserNotifyComponents(): Promise<Array<ComponentDetails>> {
    throw new Error('Not Done');
  }

  getNotifyChainsForUserNotifyComponents(): Promise<Array<NotifyChainDetails>> {
    throw new Error('Not Done');
  }


  getComponentConfigString(compId: string): Promise<string> {
    return new Promise<string>((resolve,reject) => {
      this.adminApi.downloadComponentConfigRaw({comp: ['all'], dl: false}).then(resp => {
        if (resp.raw.ok) {
          resp.raw.text().then(t => {
            resolve(t);
          })
        }else{
          reject( resp.raw.statusText );
        }
      })
    });
  }

  async uploadConfig(file: File): Promise<void> {
    // this.adminApi.uploadComponentConfig(file).then(resp => {
    //   console.log('upload resp', resp);
    // }).catch(reason => {
    //   console.log(' erro uploading config', reason);
    // })
    const formData = new FormData();
    formData.append('file', file);
    const url = new URL(this.configParams.basePath + '/admin/uploadCompConfig');
    console.log(' eeerl', url);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log('File successfully uploaded.');
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  getComponentAlertHistory(compId: string): Promise<AlertBasicDetails[]> {
    return this.wrap<AlertBasicDetails[]>(this.componentApi.getComponentAlertHistory(compId));
  }

  getComponentNotifyChain(compId: string): Promise<NotifyChainDetails> {
    return this.wrap<NotifyChainDetails>(this.componentApi.getComponentNotifyChain(compId));
  }

  updateComponentNotifyChain(compId: string, req: NotifyChainRequest): Promise<NotifyChainDetails> {
    return this.wrap<NotifyChainDetails>(this.componentApi.updateNotifyChain(compId, req));
  }

  getComponentStateHistory(compId: string, limit?: number): Promise<StateHistoryDetails[]> {
    return this.wrap<StateHistoryDetails[]>(this.componentApi.getComponentStateHistory(compId, limit));
  }

  getEventsSinceLastAlert(compId: string): Promise<EventLogItem[]> {
    return this.wrap<EventLogItem[]>(this.componentApi.getEventsSinceLastAlert(compId));
  }

  getComponentRuleSet(compId: string): Promise<String> {
    return this.wrap<String>(this.componentApi.getComponentRuleSet(compId).then());
  }

  validateRuleSet(compId: string, rules: string): Promise<String> {
    return this.wrap<String>(this.componentApi.validatedRuleSet(compId, {objectId: compId, rules: rules}).then());
  }

  updateComponentRuleSet(compId: string, rules: string): Promise<String> {
    return this.wrap<String>(this.componentApi.updateComponentRuleSet(compId, {objectId: compId, rules: rules}).then());
  }

  testRuleSet(compId: string, testRuleDefnRequest: TestRuleDefnRequest): Promise<PerentieRuleDefinition> {
    return this.wrap<PerentieRuleDefinition>(this.componentApi.testRuleSet(compId, testRuleDefnRequest).then());
  }


  getSchedules(): Promise<ScheduleDetails[]> {
    return this.wrap<ScheduleDetails[]>(this.adminApi.getAllSchedules());
  }

  registerNotifyToken(token: string, platform: "IOS" | "ANDROID" | "MACOS"): Promise<UserDetails> {
    throw new Error('registerNotifyToken Not Done');
  }







  testPing(): Promise<string> {
    // @ts-ignore
    return this.testApi.ping();
  }

  testNotify(userId: string): Promise<string> {
    // @ts-ignore
    return this.adminApi.sendTestNotification({objectId: userId});
  }





}

class WebErrorHandler implements ResponseErrorHandler{

  private store: Store;

  constructor(reduxStore: Store) {
    this.store = reduxStore;
  }
  handleErrorResponse(response: Response): void {
    console.log(' WebErrorHandler:: handle ErrorResponse', response);
    if (response.status === 401){
      location.href = "/";
    }
    if (response.status == 500){
      alert('Error '+ response.statusText);
      location.href = "/";
    }
  }

  handleRestError(errorMessage: string): void {
    console.log(' WebErrorHandler:: handleRestError', errorMessage);
  }
}

export default PerentieWebDS;
