import { InputProps } from '..';
import FormContext from '../Form/FormContext';
import styles from './Dropdown.module.scss';

type DropdownSize = 's' | 'sm';

interface DropdownProps extends InputProps {
    size?: DropdownSize;
    options?: string[];
    selected?: number;
}

function Dropdown({ label, helper, size = 'sm', options = [], selected, id }: DropdownProps) {
    return (
        <FormContext.Consumer>
            {({ data, update }) => (
                <div className={styles['container']}>
                    {label && <label htmlFor={id}>{label}</label>}
                    <select
                        id={id}
                        className={styles[`select-${size}`]}
                        onChange={(e) => update(id, e.currentTarget.value)}
                        defaultValue={selected}>
                        <option value={undefined}>Select</option>
                        {options.map((option, index) => (
                            <option key={index} value={index}>
                                {option}
                            </option>
                        ))}
                    </select>
                    {helper && <div>{helper}</div>}
                </div>
            )}
        </FormContext.Consumer>
    );
}

export default Dropdown;
