import { useState } from 'react';
import { GoogleLoginButton } from 'systemic-auth-react';

import { Credentials } from '@perentie/common';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import useDispatch from '../../hooks/useDispatch';
// import { login } from '../../redux/authenticationSlice';
import styles from './LoginPage.module.scss';
import {perDS} from "../../datasource/WebDS";
import {useNavigate} from "react-router-dom";
import {setAuthenticated, setUserDetails} from "../../redux/authenticationSlice";


function LoginPage() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const credentials = { username, password } as Credentials;
    const nav = useNavigate();
    const [errMsg, setErrMsg] = useState('');

    const loginClick = () => {
        setErrMsg('');
        perDS.authenticate(username,password).then(resp => {
            perDS.checkSession().then(res => {
                dispatch(setUserDetails(res.userDetails!));
                console.log(' checkSession res:', res);
                perDS.setUserToken(res.authToken!);
                dispatch(setAuthenticated(true));
                nav("/");
            }).catch(reason => {
                dispatch(setUserDetails({}));
                console.log(' checkSession catch', reason);
                perDS.setUserToken('');
                dispatch(setAuthenticated(false));
            })
        }).catch(err => {
            console.log(' authenticate error', err, err.response);
            setErrMsg(err.response + '');
            setUsername('');
            setPassword('');
        })
    }

    return (
        <div className={styles['login-page']}>
            <div className={styles['login-form']}>
                <Logo className={styles['logo']} />
                <input
                    placeholder="Username"
                    value={username}
                    onInput={event => {
                        setUsername(event.currentTarget.value);
                    }}
                />
                <input
                    placeholder="Password"
                    value={password}
                    type="password"
                    onInput={event => {
                        setPassword(event.currentTarget.value);
                    }}
                    onKeyUp={ (event) => {event.key === 'Enter' ? loginClick() : '';}}
                />
                <p style={{color:'red', display: (errMsg.length > 0 ? '' : 'none') }}>Bad username/password</p>
                <div className={styles['login-button-group']}>
                    <button onClick={loginClick}>Login</button>
                    <div>
                        <GoogleLoginButton />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
