import { CSSProperties, useCallback, useEffect } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import { useSpring } from 'react-spring';
import useSchmittTrigger from '../../hooks/useSchmittTrigger';
import styles from './Sidebar.module.scss';
import SidebarContext from './SidebarContext';
import { animated } from 'react-spring';
import useAnimationConfig from '../../hooks/useAnimationConfig';
interface SidebarProps {
    width?: number;
    minWidth?: number;
    threshold?: number;
    debounce?: number;
    collapsed?: boolean;
    children?: React.ReactNode;
    location: Location;
    navigator?: NavigateFunction;
}

function Sidebar({
    minWidth = 100,
    width = 200,
    threshold = 800,
    debounce = 25,
    collapsed = false,
    children,
    location,
    navigator = () => {},
}: SidebarProps) {
    const [autoCollapse, setAutoCollapse] = useSchmittTrigger({
        midpoint: threshold,
        hysteresis: debounce,
        initial: true,
        inverted: true,
    });

    const [shouldAnimate, animationConfig] = useAnimationConfig();

    const { animationProgress } = useSpring({
        to: {
            animationProgress: autoCollapse || collapsed ? 0 : 100,
        },
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    const onResize = useCallback(() => {
        setAutoCollapse(window.innerWidth);
    }, [setAutoCollapse]);

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [onResize]);

    return (
        <SidebarContext.Provider
            value={{
                animationProgress: animationProgress,
                collapsed: autoCollapse || collapsed,
                location: location,
                navigator: navigator,
            }}
        >
            <animated.nav
                className={styles['sidebar']}
                style={{
                    width: animationProgress
                        .to({
                            range: [100, 0],
                            output: [width, minWidth],
                        })
                        .to((number) => `${number}px`),
                }}
            >
                {children}
            </animated.nav>
        </SidebarContext.Provider>
    );
}

export default Sidebar;
