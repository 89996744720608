/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentDetails } from './ComponentDetails';
import {
    ComponentDetailsFromJSON,
    ComponentDetailsFromJSONTyped,
    ComponentDetailsToJSON,
} from './ComponentDetails';

/**
 * 
 * @export
 * @interface RESTResultComponentDetails
 */
export interface RESTResultComponentDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultComponentDetails
     */
    error?: string;
    /**
     * 
     * @type {ComponentDetails}
     * @memberof RESTResultComponentDetails
     */
    data?: ComponentDetails;
}

/**
 * Check if a given object implements the RESTResultComponentDetails interface.
 */
export function instanceOfRESTResultComponentDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultComponentDetailsFromJSON(json: any): RESTResultComponentDetails {
    return RESTResultComponentDetailsFromJSONTyped(json, false);
}

export function RESTResultComponentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultComponentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ComponentDetailsFromJSON(json['data']),
    };
}

export function RESTResultComponentDetailsToJSON(value?: RESTResultComponentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': ComponentDetailsToJSON(value.data),
    };
}

