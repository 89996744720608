/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    roles?: Array<UpdateUserRequestRolesEnum>;
}


/**
 * @export
 */
export const UpdateUserRequestRolesEnum = {
    ShedAdmin: 'SHED_ADMIN',
    ClientAdmin: 'CLIENT_ADMIN',
    ReadOnly: 'READ_ONLY',
    Responder: 'RESPONDER'
} as const;
export type UpdateUserRequestRolesEnum = typeof UpdateUserRequestRolesEnum[keyof typeof UpdateUserRequestRolesEnum];


/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'object_id') ? undefined : json['object_id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'object_id': value.objectId,
        'userName': value.userName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'roles': value.roles,
    };
}

