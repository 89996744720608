import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {AgentDetails} from '@perentie/common';
import {perDS} from "../../datasource/WebDS";

import AgentDetailsPage from './AgentDetails';

function AgentDetailsContainer() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [agentId, setAgentId] = useState<string>(searchParams.get('id') || '');
    const [agentDetails, setAgentDetails] = useState<AgentDetails>({});

    useEffect(() => {
        perDS.getAgentDetails(agentId).then(res => {
          if (!!res){
            setAgentDetails(res);
          }
        }).catch( reason => {
          console.log(' getAgentDetails catch', reason);
        });
    },[]);

    const updateAgentDetails = (req: AgentDetails) => {
      perDS.updateAgent(req).then( res => {
        setAgentDetails(res);
      }).catch( reason => {
        console.log(' update fail', reason);
        window.alert(' Update agent details failed' + reason);
      })

    }

    return (
        <AgentDetailsPage
            agentDetails={agentDetails}
            onSubmit={req => {
              updateAgentDetails(req);
            }}
        />
    );
}

export default AgentDetailsContainer;
