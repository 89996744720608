const defaultColors = {
    'neutral-10': '#fff',
    'variant-border': '#7b61ff',
    'neutral-60': '#9e9e9e',
    'neutral-20': '#f5f5f5',
    'neutral-80': '#616161',
    'neutral-70': '#757575',
    'neutral-30': '#ededed',
    'neutral-40': '#e0e0e0',
    'neutral-90': '#424242',
    'neutral-50': '#c2c2c2',
    'neutral-100': '#0a0a0a',
    'neutral-transparent': '#fff0',
    'primary-default': '#6aad00',
    'primary-hover': '#66a00b',
    'primary-focus': '#88c82333',
    'primary-active': '#57870b',
    'primary-light': '#9ddf46',
    'primary-dark': '#367d00',
    'primary-border': '#bce778',
    'primary-very-light': '#c9e7a1',
    'primary-visited': '#362ac0',
    'primary-surface': '#fafff2',
    'secondary-default': '#6c757d',
    'secondary-light': '#9aa4ac',
    'secondary-border': '#cbd1d7',
    'secondary-surface': '#f0f4f8',
    'secondary-hover': '#535a61',
    'secondary-active': '#414951',
    'secondary-dark': '#414951',
    'danger-default': '#e00000',
    'danger-surface': '#ffecec',
    'danger-border': '#ffd7d7',
    'secondary-focus': '#6c757d33',
    'danger-hover': '#a20000',
    'danger-active': '#630000',
    'danger-focus': '#e0000033',
    'warning-default': '#ea780e',
    'warning-surface': '#fff2e7',
    'warning-border': '#ffca99',
    'warning-hover': '#d16603',
    'warning-active': '#653508',
    'success-default': '#008243',
    'success-surface': '#ebf5f0',
    'success-border': '#b3dac7',
    'success-hover': '#006836',
    'success-active': '#005029',
    'success-focus': '#00824333',
    'info-default': '#0b5cd7',
    'info-surface': '#ecf2fc',
    'info-border': '#b6cff3',
    'info-hover': '#044dba',
    'info-active': '#043c8f',
};

export type ColorConfig = typeof defaultColors;
export type GenericColorConfig = { [key in string]: string };

export default defaultColors;
