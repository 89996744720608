interface PerentieLogoProps {
    variant?: 'default' | 'small';
}

function PerentieLogo({ variant = 'default' }: PerentieLogoProps) {
    return (
        <svg width="176" height="52" viewBox="0 0 176 52" fill="none" version="1.1" id="svg242">
            <g filter="url(#zkq3pp6swa)" id="g53">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.855 16.657c-.134 7.999 4.52 13.603 9.483 9.782 7.953-6.12 22.04-5.804 23.15-10.046-7.614 1.794-17.252.886-22.81 3.408-1.86.845-2.148.222-.782-1.013 4.943-4.464 18.433-2.585 22.872-4.717 3.37-1.625 6.514-8.833 1.952-9.91-1.52-.358-3.216-.031-4.757.138-2.569.274-5.024.559-7.48.897-3.668.506-6.473 1.097-10.203 1.076-6.185-.042-4.973.169-10.11 3.746C4.02 21.733 1.956 31.431 12.23 43.388c.956 1.108 4.747 1.245 3.935-4.274-.493-3.377-1.274-7.904 5.066-4.147 4.14 1.33 4.5.2 1.048-4.94-2.456-3.65-5.405-8.284-3.658-13.244.298-.876 1.264-1.878 1.233-.126zm18.063-7.8c-.606-.01-1.233.233-2.107.581.483 2.649-3.462 3.272-3.894.37-1.058.137-2.137.084-3.144-.465 3.39-.496 4.285-2.775 8.857-1.509.616.169 1.716 1.034.288 1.024zm12.648-1.023c-.596-.063-2.805-.992-1.624-1.868.35-.253.833-.496 1.316-.528 1.16-.073 2.764 1.277 1.9 2.037-.328.296-.955.422-1.592.359z"
                    fill="#FDFDFD"
                    id="path51"
                />
            </g>
            <g filter="url(#3mq11w8nbb)" id="g57">
                <path
                    d="M72 14.422c0-.253.152-.405.406-.405h6.007c2.352 0 3.604 1.25 3.604 3.598v6.098c0 2.314-1.252 3.598-3.604 3.598h-2.995v6.283c0 .254-.119.406-.406.406h-2.606c-.254 0-.406-.152-.406-.406V14.422zm5.6 9.595c.677 0 .999-.372.999-.997v-4.696c0-.642-.322-.996-.999-.996h-2.2v6.689h2.2z"
                    fill="#fff"
                    id="path55"
                />
            </g>
            <g filter="url(#rius6vq0oc)" id="g61">
                <path
                    d="M95.552 16.92c0 .253-.101.422-.406.422h-6.26v4.996h4.772c.253 0 .406.151.406.421v2.465c0 .27-.153.422-.406.422h-4.772v5.046h6.26c.305 0 .406.118.406.405v2.498c0 .253-.101.405-.406.405h-9.204c-.22 0-.372-.152-.372-.405V14.422c0-.253.152-.405.372-.405h9.205c.304 0 .405.152.405.405v2.498z"
                    fill="#fff"
                    id="path59"
                />
            </g>
            <g filter="url(#flbrncgfjd)" id="g65">
                <path
                    d="M109.139 23.713c0 1.672-.626 2.77-1.827 3.294l1.726 6.537c.067.304-.068.456-.356.456h-2.605c-.254 0-.373-.118-.423-.355l-1.675-6.334h-1.726v6.284c0 .253-.153.405-.406.405h-2.606c-.254 0-.406-.152-.406-.405V14.422c0-.253.152-.405.406-.405h6.277c2.352 0 3.604 1.25 3.604 3.598v6.098h.017zm-4.399.304c.643 0 .998-.355.998-.997v-4.696c0-.642-.355-.996-.998-.996h-2.47v6.689h2.47z"
                    fill="#fff"
                    id="path63"
                />
            </g>
            <g filter="url(#nz32svldle)" id="g69">
                <path
                    d="M122.692 16.92c0 .253-.101.422-.406.422h-6.26v4.996h4.771c.254 0 .406.151.406.421v2.465c0 .27-.152.422-.406.422h-4.771v5.046h6.26c.305 0 .406.118.406.405v2.498c0 .253-.101.405-.406.405h-9.204c-.22 0-.373-.152-.373-.405V14.422c0-.253.153-.405.373-.405h9.204c.305 0 .406.152.406.405v2.498z"
                    fill="#fff"
                    id="path67"
                />
            </g>
            <g filter="url(#wmi41f2qyf)" id="g73">
                <path
                    d="M136.533 14.017c.253 0 .406.152.406.405v19.14c0 .253-.153.404-.406.404h-2.488c-.22 0-.372-.1-.456-.32l-3.824-10.641h-.119v10.573c0 .253-.118.405-.406.405h-2.606c-.253 0-.406-.152-.406-.405V14.422c0-.253.153-.405.406-.405h2.471c.22 0 .372.101.457.32l3.807 10.59h.152V14.405c0-.253.118-.405.406-.405h2.606v.017z"
                    fill="#fff"
                    id="path71"
                />
            </g>
            <g filter="url(#ea98c1e28g)" id="g77">
                <path
                    d="M149.595 14.017c.27 0 .406.152.406.405v2.495c0 .253-.119.405-.406.405h-2.894v16.256c0 .27-.118.405-.406.405h-2.605c-.254 0-.406-.118-.406-.405V17.305h-2.894c-.254 0-.406-.152-.406-.404v-2.496c0-.253.152-.405.406-.405h9.205v.017z"
                    fill="#fff"
                    id="path75"
                />
            </g>
            <g filter="url(#kk8yv8tbph)" id="g81">
                <path
                    d="M156.058 14.017c.254 0 .406.152.406.405v19.173c0 .253-.152.405-.406.405h-2.606c-.27 0-.406-.152-.406-.405V14.422c0-.253.119-.405.406-.405h2.606z"
                    fill="#fff"
                    id="path79"
                />
            </g>
            <g filter="url(#06z0dumiri)" id="g85">
                <path
                    d="M170 16.92c0 .253-.102.422-.406.422h-6.26v4.996h4.771c.254 0 .406.151.406.421v2.465c0 .27-.152.422-.406.422h-4.771v5.046h6.26c.304 0 .406.118.406.405v2.498c0 .253-.102.405-.406.405h-9.204c-.22 0-.373-.152-.373-.405V14.422c0-.253.153-.405.373-.405h9.204c.304 0 .406.152.406.405v2.498z"
                    fill="#fff"
                    id="path83"
                />
            </g>
            <defs id="defs240">
                <filter
                    id="zkq3pp6swa"
                    x="0"
                    y="0"
                    width="62"
                    height="52"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood87" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix89"
                    />
                    <feOffset dy="2" id="feOffset91" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur93" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite95" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix97"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend99" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend101" />
                </filter>
                <filter
                    id="3mq11w8nbb"
                    x="66"
                    y="10.017"
                    width="22.017"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood104" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix106"
                    />
                    <feOffset dy="2" id="feOffset108" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur110" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite112" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix114"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend116" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend118" />
                </filter>
                <filter
                    id="rius6vq0oc"
                    x="79.57"
                    y="10.017"
                    width="21.983"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood121" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix123"
                    />
                    <feOffset dy="2" id="feOffset125" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur127" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite129" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix131"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend133" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend135" />
                </filter>
                <filter
                    id="flbrncgfjd"
                    x="92.835"
                    y="10.017"
                    width="22.304"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood138" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix140"
                    />
                    <feOffset dy="2" id="feOffset142" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur144" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite146" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix148"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend150" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend152" />
                </filter>
                <filter
                    id="nz32svldle"
                    x="106.709"
                    y="10.017"
                    width="21.983"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood155" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix157"
                    />
                    <feOffset dy="2" id="feOffset159" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur161" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite163" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix165"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend167" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend169" />
                </filter>
                <filter
                    id="wmi41f2qyf"
                    x="120.228"
                    y="10"
                    width="22.71"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood172" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix174"
                    />
                    <feOffset dy="2" id="feOffset176" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur178" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite180" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix182"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend184" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend186" />
                </filter>
                <filter
                    id="ea98c1e28g"
                    x="133.984"
                    y="10"
                    width="22.017"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood189" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix191"
                    />
                    <feOffset dy="2" id="feOffset193" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur195" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite197" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix199"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend201" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend203" />
                </filter>
                <filter
                    id="kk8yv8tbph"
                    x="147.046"
                    y="10.017"
                    width="15.418"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" id="feFlood206" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix208"
                    />
                    <feOffset dy="2" id="feOffset210" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur212" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite214" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix216"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend218" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend220" />
                </filter>
                <filter
                    id="06z0dumiri"
                    x="154.017"
                    y="10.017"
                    width="21.983"
                    height="31.983"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" id="feFlood223" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix225"
                    />
                    <feOffset dy="2" id="feOffset227" />
                    <feGaussianBlur stdDeviation="3" id="feGaussianBlur229" />
                    <feComposite in2="hardAlpha" operator="out" id="feComposite231" />
                    <feColorMatrix
                        values="0 0 0 0 0.274297 0 0 0 0 0.395833 0 0 0 0 0.0164931 0 0 0 0.4 0"
                        id="feColorMatrix233"
                    />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1098_85181" id="feBlend235" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_1098_85181" result="shape" id="feBlend237" />
                </filter>
            </defs>
        </svg>
    );
}

export default PerentieLogo;
