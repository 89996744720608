import { IoWarning } from 'react-icons/io5';
import styles from './FallbackPage.module.scss';

interface FallbackPageProps {
    message?: string;
}

function FallbackPage({ message = 'This page has not been implemented yet' }: FallbackPageProps) {
    return (
        <div className={styles['fallback-page']}>
            <IoWarning fill='lightgray' size={256} />
            <code>{message}</code>
        </div>
    );
}

export default FallbackPage;
