import { ComponentBasicDetails} from "@perentie/common";
import {Button, Cell, Row, Table} from "@systemic-design-framework/react";
import {useState} from "react";
import StateText from "../../components/StateText/StateText";


interface ComponentsListPageProps {
    components: ComponentBasicDetails[],
    rawConfig: string,
    uploadConfig: (data: File) => void;
}

function ComponentsListPage( { components, uploadConfig, rawConfig }: ComponentsListPageProps){

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const uploadChange = (event: any) => {
        if (event.target.files.length > 0){
            const file = event.target.files[0];
            setSelectedFile(file);
        }
    }
    const uploadClick = (): void => {
      if (!!selectedFile){
        uploadConfig(selectedFile);
        setSelectedFile(null);
      }
    }

    const TableHeader = () => {
        return (
          <Row>
              <Cell>State</Cell>
              <Cell>Name</Cell>
              <Cell>Identifier</Cell>
          </Row>
        );
    };

    const renderItem = (item: ComponentBasicDetails, index: number) => {
        return (
          <Row key={index}>
              <Cell>
                <StateText>{item.state}</StateText>
              </Cell>
              <Cell>{item.name}</Cell>
              <Cell>{item.systemIdentifier}</Cell>
          </Row>
        );
    };

    return(
      <div style={{overflow: 'auto', padding: 16}}>

        <div>


        </div>
          <Table data={components} renderItem={renderItem} HeaderComponent={<TableHeader />} border/>
        <div style={{width:'100%', height:1, backgroundColor: "darkgray", marginTop:38}}></div>
          <div style={{marginTop:38}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center'}}>
              <div style={{display: 'flex', flexDirection: 'row', columnGap: 16, alignItems: 'center'}}>
                <h3 style={{margin: 'unset'}}>Component Config | </h3>
                <label htmlFor={'configFile'}>Upload Config:</label>
                <input type="file" id="configFile" accept=".yml, .yaml" onChange={event => uploadChange(event)}/>
                {selectedFile != null ? <Button title="Upload"  size="s"  onClick={uploadClick}/> : []}
              </div>
              <a role="button" style={{backgroundColor:'#6aad00', padding: '8px 12px', borderRadius:8, color:"white", textDecoration:'none'}} href="/perentie/admin/componentConfig" target="_blank">Download</a>
            </div>
            <pre style={{padding: 16, border: '1px solid grey', borderRadius: 8, textWrap:'wrap'}}>
                {rawConfig}
              </pre>
          </div>
      </div>
    );
}

export default ComponentsListPage;
