import styles from "./ManageSchedules.module.scss"
import {useEffect, useState} from "react";
import {perDS} from "../../datasource/WebDS";
import {ScheduleDetails} from "@perentie/common";
function ManageSchedulesPage(){
  const [schedList, setSchedList] = useState<ScheduleDetails[]>([]);

  const ScheduleInfo = (props: {info: ScheduleDetails}) => {
    const {info} = props;
    return (
      <div className={styles['scheduleDetails']} style={{marginBottom: 16}}>
        <span>{info.name}</span>
        <dl>
          <dt>Key:</dt>
          <dd>{info.key}</dd>
          <dt>Description:</dt>
          <dd>{info.description}</dd>
          <dt>Default:</dt>
          <dd>{info._default + ''}</dd>
        </dl>
      </div>
    )
  }

  useEffect(() => {
    perDS.getSchedules().then(res => {
      setSchedList(res);
    }).catch(reason => {
      console.log(' getSchedules catch', reason);
    })
  }, []);

  const schedules = schedList.map( sch => {
    return <ScheduleInfo info={sch}/>
  })

  return (
    <div className={styles['content']}>
      {schedules}
    </div>
  )
}

export default ManageSchedulesPage;
