import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { ReactComponent as Home } from '../../assets/home-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './ScreenIdentityBar.module.scss';

function ScreenIdentityBar() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    if (pathname == '/') {
        navigate('/alerts');
    }

    const crumbs = [
        'Home',
        ...pathname
            .substring(1)
            .split('/')
            .map((crumb) => {
                return crumb.charAt(0).toUpperCase() + crumb.substring(1);
            })
            .filter((crumb) => crumb !== ''),
    ];

    const onNavigate = (crumb: string) => {
      console.log(' crumb', crumb);
    };

    return (
        <header className={styles['screen-identity-bar']}>
            <span className={styles['title']}>{crumbs.at(crumbs.length - 1)}</span>
            <Breadcrumb crumbs={crumbs} actionReducer={onNavigate} RootComponent={Home} />
        </header>
    );
}

export default ScreenIdentityBar;
