
import styles from './AddClientsPage.module.scss'
import {useEffect, useState} from "react";
import {perDS} from "../../../datasource/WebDS";
import { Text, TextArea } from '../../../components/Input';

import {Button, ContainerCard} from "@systemic-design-framework/react";
import { useNavigate } from 'react-router-dom';

function AddClientsPage(){
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [client, setClient] = useState({name: "", description: ""});
    const navigate = useNavigate();

    const handleClientChange = (e: any) => {
        const { name, value } = e?.target;
        setClient((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const onCancel = () => {
        navigate('/clients');
    };

    const onSave = () => {
        perDS.createClient(client.name, client.description)
        .then(res => {
            navigate('/clients');
        }).catch(err => {
            console.error("Error creating client -> ", err);
        });
    }

    useEffect(() => {
        if (client) {            
            if ((client.name.trim().length > 0) && (client.description.trim().length > 0)) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
        }
    }, [client])

    return (
        <div className={styles['content']}>
            <ContainerCard HeaderComponent={
                <div className={styles['header']}>
                Client Details
            </div>}>
                <Text id='client-name' label='Name' valueChange={(value) => setClient((prev) => ({...prev, name: value}))}></Text>
                <TextArea id='client-description' name="description" label='Description' onInput={handleClientChange}></TextArea>

                <div className={styles['actions']}>
                    <Button title="Cancel" variant="ghost" size="sm" onClick={onCancel}/>
                    <Button
                        title="Add Client"
                        size="sm"
                        onClick={onSave}
                        disabled = {isDisabled}
                    />
                </div>
            </ContainerCard>
        </div>
    )
}

export default AddClientsPage;
