/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateComponentRequest,
  NotifyChainRequest,
  RESTResultAlertDetails,
  RESTResultComponentDetails,
  RESTResultListAlertBasicDetails,
  RESTResultListComponentDetails,
  RESTResultListEventLogItem,
  RESTResultListHistoryItemDetails,
  RESTResultListNotifyChainDetails,
  RESTResultListStateHistoryDetails,
  RESTResultNotifyChainDetails,
  RESTResultPerentieRuleDefinition,
  RESTResultString,
  TestRuleDefnRequest,
  UpdateRuleSetRequest,
} from '../models/index';
import {
    CreateComponentRequestFromJSON,
    CreateComponentRequestToJSON,
    NotifyChainRequestFromJSON,
    NotifyChainRequestToJSON,
    RESTResultAlertDetailsFromJSON,
    RESTResultAlertDetailsToJSON,
    RESTResultComponentDetailsFromJSON,
    RESTResultComponentDetailsToJSON,
    RESTResultListAlertBasicDetailsFromJSON,
    RESTResultListAlertBasicDetailsToJSON,
    RESTResultListComponentDetailsFromJSON,
    RESTResultListComponentDetailsToJSON,
    RESTResultListEventLogItemFromJSON,
    RESTResultListEventLogItemToJSON,
    RESTResultListHistoryItemDetailsFromJSON,
    RESTResultListHistoryItemDetailsToJSON,
    RESTResultListNotifyChainDetailsFromJSON,
    RESTResultListNotifyChainDetailsToJSON,
    RESTResultListStateHistoryDetailsFromJSON,
    RESTResultListStateHistoryDetailsToJSON,
    RESTResultNotifyChainDetailsFromJSON,
    RESTResultNotifyChainDetailsToJSON,
    RESTResultPerentieRuleDefinitionFromJSON,
    RESTResultPerentieRuleDefinitionToJSON,
    RESTResultStringFromJSON,
    RESTResultStringToJSON,
    TestRuleDefnRequestFromJSON,
    TestRuleDefnRequestToJSON,
    UpdateRuleSetRequestFromJSON,
    UpdateRuleSetRequestToJSON,
} from '../models/index';

export interface CreateComponentOperationRequest {
    createComponentRequest?: CreateComponentRequest;
}

export interface GetActiveAlertRequest {
    componentId: string;
}

export interface GetComponentAlertHistoryRequest {
    componentId: string;
}

export interface GetComponentEventHistoryRequest {
    componentId: string;
    start?: number;
    num?: number;
}

export interface GetComponentNotifyChainRequest {
    componentId: string;
}

export interface GetComponentRuleSetRequest {
    componentId: string;
}

export interface GetComponentStateHistoryRequest {
    componentId: string;
    limit?: number;
}

export interface GetEventsSinceLastAlertRequest {
    componentId: string;
}

export interface TestRuleSetRequest {
    componentId: string;
    testRuleDefnRequest?: TestRuleDefnRequest;
}

export interface UpdateComponentRuleSetRequest {
    componentId: string;
    updateRuleSetRequest?: UpdateRuleSetRequest;
}

export interface UpdateNotifyChainRequest {
    componentId: string;
    notifyChainRequest?: NotifyChainRequest;
}

export interface ValidatedRuleSetRequest {
    componentId: string;
    updateRuleSetRequest?: UpdateRuleSetRequest;
}

/**
 * PerentieComponentWSApi - interface
 * 
 * @export
 * @interface PerentieComponentWSApiInterface
 */
export interface PerentieComponentWSApiInterface {
    /**
     * Create new Component
     * @param {CreateComponentRequest} [createComponentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    createComponentRaw(requestParameters: CreateComponentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultComponentDetails>>;

    /**
     * Create new Component
     */
    createComponent(createComponentRequest?: CreateComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultComponentDetails>;

    /**
     * Returns the active Perentie Alert for this component if there is one
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getActiveAlertRaw(requestParameters: GetActiveAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>>;

    /**
     * Returns the active Perentie Alert for this component if there is one
     */
    getActiveAlert(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails>;

    /**
     * Returns All the alerts for a component including any active alert
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentAlertHistoryRaw(requestParameters: GetComponentAlertHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>>;

    /**
     * Returns All the alerts for a component including any active alert
     */
    getComponentAlertHistory(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails>;

    /**
     * Returns set number of History Items from start index to num for component
     * @param {string} componentId 
     * @param {number} [start] 
     * @param {number} [num] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentEventHistoryRaw(requestParameters: GetComponentEventHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListHistoryItemDetails>>;

    /**
     * Returns set number of History Items from start index to num for component
     */
    getComponentEventHistory(componentId: string, start?: number, num?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListHistoryItemDetails>;

    /**
     * Get notify chain for component
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentNotifyChainRaw(requestParameters: GetComponentNotifyChainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultNotifyChainDetails>>;

    /**
     * Get notify chain for component
     */
    getComponentNotifyChain(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultNotifyChainDetails>;

    /**
     * 
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentRuleSetRaw(requestParameters: GetComponentRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>>;

    /**
     */
    getComponentRuleSet(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString>;

    /**
     * Returns list of StateHistoryDetails for given component
     * @param {string} componentId 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentStateHistoryRaw(requestParameters: GetComponentStateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListStateHistoryDetails>>;

    /**
     * Returns list of StateHistoryDetails for given component
     */
    getComponentStateHistory(componentId: string, limit?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListStateHistoryDetails>;

    /**
     * Returns Components for this user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListComponentDetails>>;

    /**
     * Returns Components for this user
     */
    getComponents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListComponentDetails>;

    /**
     * Returns Components for this user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getComponentsWithNotifyUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListComponentDetails>>;

    /**
     * Returns Components for this user
     */
    getComponentsWithNotifyUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListComponentDetails>;

    /**
     * Returns set number of History Items from start index to num for component
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getEventsSinceLastAlertRaw(requestParameters: GetEventsSinceLastAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListEventLogItem>>;

    /**
     * Returns set number of History Items from start index to num for component
     */
    getEventsSinceLastAlert(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListEventLogItem>;

    /**
     * Returns Notify Chains for user Components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    getNotifyChainsForComponentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListNotifyChainDetails>>;

    /**
     * Returns Notify Chains for user Components
     */
    getNotifyChainsForComponents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListNotifyChainDetails>;

    /**
     * 
     * @param {string} componentId 
     * @param {TestRuleDefnRequest} [testRuleDefnRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    testRuleSetRaw(requestParameters: TestRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultPerentieRuleDefinition>>;

    /**
     */
    testRuleSet(componentId: string, testRuleDefnRequest?: TestRuleDefnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultPerentieRuleDefinition>;

    /**
     * 
     * @param {string} componentId 
     * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    updateComponentRuleSetRaw(requestParameters: UpdateComponentRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>>;

    /**
     */
    updateComponentRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString>;

    /**
     * Update notify chain of component
     * @param {string} componentId 
     * @param {NotifyChainRequest} [notifyChainRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    updateNotifyChainRaw(requestParameters: UpdateNotifyChainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultNotifyChainDetails>>;

    /**
     * Update notify chain of component
     */
    updateNotifyChain(componentId: string, notifyChainRequest?: NotifyChainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultNotifyChainDetails>;

    /**
     * 
     * @param {string} componentId 
     * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieComponentWSApiInterface
     */
    validatedRuleSetRaw(requestParameters: ValidatedRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>>;

    /**
     */
    validatedRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString>;

}

/**
 * 
 */
export class PerentieComponentWSApi extends runtime.BaseAPI implements PerentieComponentWSApiInterface {

    /**
     * Create new Component
     */
    async createComponentRaw(requestParameters: CreateComponentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultComponentDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/components/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateComponentRequestToJSON(requestParameters.createComponentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultComponentDetailsFromJSON(jsonValue));
    }

    /**
     * Create new Component
     */
    async createComponent(createComponentRequest?: CreateComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultComponentDetails> {
        const response = await this.createComponentRaw({ createComponentRequest: createComponentRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the active Perentie Alert for this component if there is one
     */
    async getActiveAlertRaw(requestParameters: GetActiveAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultAlertDetails>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getActiveAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/activeAlert`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultAlertDetailsFromJSON(jsonValue));
    }

    /**
     * Returns the active Perentie Alert for this component if there is one
     */
    async getActiveAlert(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultAlertDetails> {
        const response = await this.getActiveAlertRaw({ componentId: componentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns All the alerts for a component including any active alert
     */
    async getComponentAlertHistoryRaw(requestParameters: GetComponentAlertHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListAlertBasicDetails>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getComponentAlertHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/alertHistory`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListAlertBasicDetailsFromJSON(jsonValue));
    }

    /**
     * Returns All the alerts for a component including any active alert
     */
    async getComponentAlertHistory(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListAlertBasicDetails> {
        const response = await this.getComponentAlertHistoryRaw({ componentId: componentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns set number of History Items from start index to num for component
     */
    async getComponentEventHistoryRaw(requestParameters: GetComponentEventHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListHistoryItemDetails>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getComponentEventHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.num !== undefined) {
            queryParameters['num'] = requestParameters.num;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/history`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListHistoryItemDetailsFromJSON(jsonValue));
    }

    /**
     * Returns set number of History Items from start index to num for component
     */
    async getComponentEventHistory(componentId: string, start?: number, num?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListHistoryItemDetails> {
        const response = await this.getComponentEventHistoryRaw({ componentId: componentId, start: start, num: num }, initOverrides);
        return await response.value();
    }

    /**
     * Get notify chain for component
     */
    async getComponentNotifyChainRaw(requestParameters: GetComponentNotifyChainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultNotifyChainDetails>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getComponentNotifyChain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/notifyChain`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNotifyChainDetailsFromJSON(jsonValue));
    }

    /**
     * Get notify chain for component
     */
    async getComponentNotifyChain(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultNotifyChainDetails> {
        const response = await this.getComponentNotifyChainRaw({ componentId: componentId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getComponentRuleSetRaw(requestParameters: GetComponentRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getComponentRuleSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/ruleSet`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     */
    async getComponentRuleSet(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString> {
        const response = await this.getComponentRuleSetRaw({ componentId: componentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns list of StateHistoryDetails for given component
     */
    async getComponentStateHistoryRaw(requestParameters: GetComponentStateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListStateHistoryDetails>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getComponentStateHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/stateHistory`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListStateHistoryDetailsFromJSON(jsonValue));
    }

    /**
     * Returns list of StateHistoryDetails for given component
     */
    async getComponentStateHistory(componentId: string, limit?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListStateHistoryDetails> {
        const response = await this.getComponentStateHistoryRaw({ componentId: componentId, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Returns Components for this user
     */
    async getComponentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListComponentDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListComponentDetailsFromJSON(jsonValue));
    }

    /**
     * Returns Components for this user
     */
    async getComponents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListComponentDetails> {
        const response = await this.getComponentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns Components for this user
     */
    async getComponentsWithNotifyUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListComponentDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/withUserNotify`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListComponentDetailsFromJSON(jsonValue));
    }

    /**
     * Returns Components for this user
     */
    async getComponentsWithNotifyUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListComponentDetails> {
        const response = await this.getComponentsWithNotifyUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns set number of History Items from start index to num for component
     */
    async getEventsSinceLastAlertRaw(requestParameters: GetEventsSinceLastAlertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListEventLogItem>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling getEventsSinceLastAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/{component_id}/eventsSinceLastAlert`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListEventLogItemFromJSON(jsonValue));
    }

    /**
     * Returns set number of History Items from start index to num for component
     */
    async getEventsSinceLastAlert(componentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListEventLogItem> {
        const response = await this.getEventsSinceLastAlertRaw({ componentId: componentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns Notify Chains for user Components
     */
    async getNotifyChainsForComponentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultListNotifyChainDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/components/chains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListNotifyChainDetailsFromJSON(jsonValue));
    }

    /**
     * Returns Notify Chains for user Components
     */
    async getNotifyChainsForComponents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultListNotifyChainDetails> {
        const response = await this.getNotifyChainsForComponentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async testRuleSetRaw(requestParameters: TestRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultPerentieRuleDefinition>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling testRuleSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/components/{component_id}/testRuleSet`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestRuleDefnRequestToJSON(requestParameters.testRuleDefnRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultPerentieRuleDefinitionFromJSON(jsonValue));
    }

    /**
     */
    async testRuleSet(componentId: string, testRuleDefnRequest?: TestRuleDefnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultPerentieRuleDefinition> {
        const response = await this.testRuleSetRaw({ componentId: componentId, testRuleDefnRequest: testRuleDefnRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateComponentRuleSetRaw(requestParameters: UpdateComponentRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling updateComponentRuleSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/components/{component_id}/updateRuleSet`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRuleSetRequestToJSON(requestParameters.updateRuleSetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     */
    async updateComponentRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString> {
        const response = await this.updateComponentRuleSetRaw({ componentId: componentId, updateRuleSetRequest: updateRuleSetRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update notify chain of component
     */
    async updateNotifyChainRaw(requestParameters: UpdateNotifyChainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultNotifyChainDetails>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling updateNotifyChain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/components/{component_id}/updateNotifyChain`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotifyChainRequestToJSON(requestParameters.notifyChainRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNotifyChainDetailsFromJSON(jsonValue));
    }

    /**
     * Update notify chain of component
     */
    async updateNotifyChain(componentId: string, notifyChainRequest?: NotifyChainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultNotifyChainDetails> {
        const response = await this.updateNotifyChainRaw({ componentId: componentId, notifyChainRequest: notifyChainRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async validatedRuleSetRaw(requestParameters: ValidatedRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultString>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling validatedRuleSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/components/{component_id}/validateRuleSet`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRuleSetRequestToJSON(requestParameters.updateRuleSetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     */
    async validatedRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultString> {
        const response = await this.validatedRuleSetRaw({ componentId: componentId, updateRuleSetRequest: updateRuleSetRequest }, initOverrides);
        return await response.value();
    }

}
