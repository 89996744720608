/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertActionRequest
 */
export interface AlertActionRequest {
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    type?: AlertActionRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    userId?: string;
}


/**
 * @export
 */
export const AlertActionRequestTypeEnum = {
    Handle: 'HANDLE',
    Unhandle: 'UNHANDLE',
    Assign: 'ASSIGN',
    Comment: 'COMMENT',
    Notify: 'NOTIFY',
    NotifyFail: 'NOTIFY_FAIL',
    UserClose: 'USER_CLOSE'
} as const;
export type AlertActionRequestTypeEnum = typeof AlertActionRequestTypeEnum[keyof typeof AlertActionRequestTypeEnum];


/**
 * Check if a given object implements the AlertActionRequest interface.
 */
export function instanceOfAlertActionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AlertActionRequestFromJSON(json: any): AlertActionRequest {
    return AlertActionRequestFromJSONTyped(json, false);
}

export function AlertActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'object_id') ? undefined : json['object_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function AlertActionRequestToJSON(value?: AlertActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'object_id': value.objectId,
        'type': value.type,
        'info': value.info,
        'user_id': value.userId,
    };
}

