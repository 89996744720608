import { useCallback, useContext, useMemo } from 'react';

import { AlertActionRequest, BaseObjectRequest, WebServiceForHandlingNotificationsAlertApi } from '../api';
import PerentieServiceContext from '../context/PerentieServiceContext';
import { suspendPromise } from '../util/promise';
import useAxiosRequestConfig from './useAxiosRequestConfig';

function useAlertHandlingService() {
    const { apiUrl } = useContext(PerentieServiceContext);
    const api = useMemo(() => new WebServiceForHandlingNotificationsAlertApi(undefined, apiUrl), [apiUrl]);
    const config = useAxiosRequestConfig();

    const suspendHandle = useCallback(
        (alertAction: BaseObjectRequest) => {
            const fp = api.handle(alertAction, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    const suspendPass = useCallback(
        (alertAction: AlertActionRequest) => {
            const fp = api.passAlert(alertAction, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    return { suspendHandle, suspendPass };
}

export default useAlertHandlingService;
