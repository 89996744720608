
import styles from './ManageClientPage.module.scss'
import {useEffect, useState} from "react";
import {perDS} from "../../../datasource/WebDS";
import { Text, TextArea } from '../../../components/Input';

import {Button, Cell, ContainerCard, Label, Row} from "@systemic-design-framework/react";
import { useNavigate, useParams } from 'react-router-dom';
import { ClientDetails, ComponentDetails } from '@perentie/common';

function ManageClientPage(){
    const {clientId} = useParams();
    const [client, setClient] = useState<ClientDetails>({});
    const [updatedClient, setUpdatedClient] = useState<ClientDetails>({});
    const [components, setComponents] = useState<ComponentDetails[]>([]);
    const [availableComponents, setAvailableComponents] = useState<ComponentDetails[]>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const navigate = useNavigate();

    const onCancel = () => {
        navigate('/clients');
    };

    const onSave = () => {
        const componentList: string[] = structuredClone(updatedClient?.components!)?.map((c) => {return c?.objectId!});
        perDS.updatePerentieClient(updatedClient?.objectId!, updatedClient?.name!, updatedClient?.description!, componentList!)
        .then(res => {
            navigate('/clients');
        })
        .catch(err => {
            console.error("Error updating client components -> ", err);
        })
    }

    const handleClientChange = (e: any) => {
        const { name, value } = e?.target;
        setUpdatedClient((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const addComponent = (component: ComponentDetails) => {
        let current = structuredClone(updatedClient.components);
        current?.push(component);
        setUpdatedClient((prev) => ({
            ...prev,
            components: current,
        }));
        setAvailableComponents(availableComponents.filter((c) => {return c.objectId !== component.objectId}));
    }

    const removeComponent = (component: ComponentDetails) => {
        let current = structuredClone(updatedClient.components);
        current = current?.filter((c) => {return c.objectId !== component.objectId});
        setUpdatedClient((prev) => ({
            ...prev,
            components: current,
        }));
        let comp = structuredClone(availableComponents);
        comp.push(component);
        setAvailableComponents(comp);
    }

    useEffect(() => {
        if (updatedClient) {            
            if (JSON.stringify(client) !== JSON.stringify(updatedClient)) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
        }
    }, [updatedClient])

    useEffect(() => {
        perDS.getPerentieClients().then( res => {
            setClient(res?.filter((c) => {return c.objectId === clientId})[0]);
            setUpdatedClient(res?.filter((c) => {return c.objectId === clientId})[0]);
        }).catch( reason => {
            console.log(' getPerentieClients fail ', reason);
        });
    }, []);

    useEffect(() => {
        perDS.adminGetAllComponents().then( res => {
            setComponents(res);
        }).catch( reason => {
            console.log(' getPerentieComponents fail ', reason);
        });
    }, []);

    useEffect(() => {
        if (components && updatedClient) {
            let currentComponents = structuredClone(updatedClient?.components!)?.map((c) => {return c?.objectId!});
            setAvailableComponents(components?.filter((c) => {return !currentComponents?.includes(c.objectId!)}));
        }
    }, [components, updatedClient])

    return (
        <div className={styles['content']}>
            <ContainerCard HeaderComponent={
                <div className={styles['header']}>
                Client Details
            </div>}>
                <Text id='client-name' label='Name' valueChange={(value) => setUpdatedClient((prev) => ({...prev, name: value}))} value={updatedClient.name}></Text>
                <TextArea id='client-description' name="description" label='Description' onInput={handleClientChange} value={updatedClient.description}></TextArea>
                
                <Label text={"Components (" + updatedClient.components?.length + ")"}></Label>
                <div style={{display: 'flex', gap: "100px",}}>
                    <div>
                        <ContainerCard HeaderComponent={<div className={styles['header']}>
                            Current
                        </div>}>
                            {updatedClient?.components && updatedClient?.components?.length > 0 ? (
                                <>
                                    {updatedClient?.components?.map((c) => {
                                        return (
                                            <Row>
                                            <Cell width={300}>{c.name}</Cell>
                                            <Cell position='middle' align='right' width={50}><Button title='Remove' variant='ghost' size="s" onClick={() => removeComponent(c)}/></Cell>
                                        </Row>
                                        )
                                    })}
                                </>
                            ) : (
                                <Row>
                                    <Cell width={390}>No current components.</Cell>
                                </Row>
                            )}
                        </ContainerCard>
                    </div>
                    <div>
                        <ContainerCard HeaderComponent={<div className={styles['header']}>
                            Available
                        </div>}>
                            {availableComponents.length > 0 ? (
                                <>
                                    {availableComponents?.map((c) => {
                                        return (
                                            <Row>
                                                <Cell width={300}>{c.name ?? ""}</Cell>
                                                <Cell position='middle' align='right' width={50}><Button title='Add' variant='ghost' size="s" onClick={() => addComponent(c)}/></Cell>
                                            </Row>
                                        )
                                    })}
                                </>
                            ) : (
                                <Row>
                                    <Cell width={390}>No available components.</Cell>
                                </Row>
                            )}
                        </ContainerCard>
                    </div>
                </div>

                <div className={styles['actions']}>
                    <Button title="Cancel" variant="ghost" size="sm" onClick={onCancel}/>
                    <Button
                        title="Save Client Details"
                        size="sm"
                        onClick={onSave}
                        disabled = {isDisabled}
                    />
                </div>
            </ContainerCard>
        </div>
    )
}

export default ManageClientPage;
