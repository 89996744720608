import { useEffect, useContext, useRef, ChangeEvent } from 'react';
import { InputProps } from '..';
import FormContext from '../FormContext';
import styles from './FileUpload.module.scss';
import { IoCloudUpload, IoCloseCircle } from 'react-icons/io5';
import { Validator } from '..';
import Label from '../Label/Label';

interface FileUploadProps extends InputProps {
    validate?: Validator<File> | Validator<File>[];
    accept?: string;
}

function FileUpload({"for": self, label, disabled, validate, required, accept}: FileUploadProps) {
    const context = useContext(FormContext);

    useEffect(() => {
        context.register(self, undefined, !!required, validate);
    }, []);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            context.setValue(self, event.target.files[0]);
        }
        else {
            context.setValue(self, undefined);
        }
    }

    const openFileUpload = () => {
        filePicker.current!.click();
    }

    const clearFile = () => {
        context.setValue(self, undefined);
    }

    const getFileName = () => {
        const file: File | undefined = context.getValue(self);
        return file ? file.name : (
            <span style={{color: "dimgrey"}}>
                No file chosen
            </span>
        );
    }

    const filePicker = useRef<HTMLInputElement>(null);


    return (
        <>
            { label && <Label text={label} required={required}/> }
            <div style={{display: "flex"}}>
                <div style={{display: "flex"}} className={styles['file-upload-name']}>
                    {getFileName()}
                    {context.getValue(self) &&
                        <div className={styles["right-align-div"]}>
                            <IoCloseCircle
                                className={styles["remove-file-icon"]}
                                onClick={clearFile}
                            />
                        </div>
                    }
                </div>
                <button
                    disabled={disabled}
                    type="button"
                    onClick={openFileUpload}
                    className={styles["upload-button"]}
                >
                    {<><IoCloudUpload/> Upload File</>}
                </button>
                <input
                    style={{display: "none"}}
                    type="file"
                    ref={filePicker}
                    accept={accept}
                    onChange={onChange}
                />
            </div>
            <label className={styles["error-message"]}>
                {context.getError(self)}
            </label>
        </>
    );
}

export default FileUpload;
