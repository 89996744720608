import { useState, useReducer, FormEvent } from 'react';
import { FormContext } from './FormContext';
import { Validator } from '.';
import Container from '../../components/Card/Container/Container'
import styles from './Form.module.scss';
import Loader from '../../components/Loader/Loader';

interface FormProps {
    title?: string,
    children: React.ReactNode;
    onSubmit: (form: any) => void;
    depends?: any[];
    onError?: () => void;
}

interface Field {
    required: boolean;
    validators: Validator<any>[];
    value: any;
    error: string;
}

function Form({ title, children, onSubmit, onError, depends }: FormProps) {

    const [,forceUpdate] = useReducer(x => x + 1, 0);

    const [fields,] = useState<Map<string, Field>>(new Map());

    const getFieldError = (field: Field) => {
        for (let i = 0; i < field.validators.length; i++) {
            const error = field.validators[i](field.value);
            if (error) {
                return error;
            }
        }
        return "";
    }

    const context = {
        getValue: (key: string) => {
            if (fields.get(key) == undefined) {
                return "";
            }
            return fields.get(key)!.value;
        },

        setValue: (key: string, value: any) => {
            let field: Field = {...fields.get(key)!, value: value};
            field.error = value
                ? getFieldError(field)
                : (field.required ? "This field is required" : "");
            fields.set(key, field);
            forceUpdate();
        },

        getError: (key: string) => {
            const field = fields.get(key);
            return field ? fields.get(key)!.error : "";
        },

        register: (key: string, value: any, required: boolean, validators?: Validator<any> | Validator<any>[]) => {
            let validatorList: Validator<any>[];
            
            if (validators == undefined) {
                validatorList = [];
            }
            else if (typeof validators === "function") {
                validatorList = [validators];
            }
            else {
                validatorList = validators;
            }

            const field: Field = {
                required: required,
                error: "",
                value: value,
                validators: validatorList
            };

            fields.set(key, field);
            forceUpdate();
        },
        
        loading: () => {
            return !!depends?.includes(undefined);
        }
    };

    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let isValid = true;
        fields.forEach((field: Field, key: string) => {
            field.error = field.value
                ? getFieldError(field)
                : field.required ?  "This field is required" : "";
            if (field.error) {
                isValid = false;
            }
            fields.set(key, field);
        });

        forceUpdate();

        if (isValid) {
            const form: any = {};
            fields.forEach((field: Field, key: string) => {
                form[key] = field.value;
            });
            onSubmit(form);
        }
        else if (onError) {
            onError();
        }
    }

    const createContainerHeader = () => {
        return (
            <div style={{display: "flex", width: "100%"}}>
                <h3 style={{flex: 1}} className={styles["vertical-center"]}>
                    {title}
                </h3>
                { depends?.includes(undefined) &&
                    <Loader className={styles["vertical-cetner"]}/>
                }
            </div>
        );
    }

    const formContext = (
        <FormContext.Provider value={context}>
            <form className={styles['form']} onSubmit={onFormSubmit}>
                {children}
            </form>
        </FormContext.Provider>
    );

    return !title ? formContext : (
        <Container HeaderComponent={createContainerHeader()}>
            {formContext}
        </Container>
    )
}

export default Form;
