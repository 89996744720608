import { Credentials, PerentieAuthenticateWebServiceApi, SessionDetails } from '../api';
import { BASE_PATH } from '../api/base';
import Service, { ServiceStatus } from './Service';

type LoginResponse = Promise<[LoginResponse, ServiceStatus]>;
type CheckSessionResponse = Promise<[SessionDetails, ServiceStatus]>;
type LogoutResponse = Promise<[boolean, ServiceStatus]>;

class AuthenticationService extends Service<PerentieAuthenticateWebServiceApi> {
    constructor(apiUrl: string = BASE_PATH, authToken = '') {
        super(new PerentieAuthenticateWebServiceApi(undefined, apiUrl), authToken);
    }

    public async login(credentials: Credentials): LoginResponse {
        return this.unpackRequest(this.API.authenticate(credentials, this.config), {}) as LoginResponse;
    }

    public async logout(): LogoutResponse {
        return this.unpackRequest(this.API.logout(this.config), {}) as LogoutResponse;
    }

    public async checkSession(): CheckSessionResponse {
        return this.unpackRequest(this.API.checkSession(this.config), {}) as CheckSessionResponse;
    }
}

export default AuthenticationService;
