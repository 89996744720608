import { CSSProperties, ReactNode, useContext } from 'react';
import SidebarContext from '../SidebarContext';
import styles from './Section.module.scss';
import { animated, AnimatedProps } from 'react-spring';

interface SectionProps {
    title?: string;
    children?: ReactNode;
    separator?: boolean;
}

function Section({ title, children, separator }: SectionProps) {
    const { animationProgress } = useContext(SidebarContext);

    const animatedSectionTitle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [0, 50],
            })
            .to((number) => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [0, 50],
            })
            .to((number) => `${number}%`),
    } as AnimatedProps<CSSProperties>;

    return (
        <div className={styles['section']}>
            {separator && <div className={styles['separator']} />}
            {title && (
                <div className={styles['section-title-frame']}>
                    <animated.div
                        className={styles['section-title']}
                        style={animatedSectionTitle}
                    >
                        {title}
                    </animated.div>
                </div>
            )}
            <div className={styles['section-body']}>{children}</div>
        </div>
    );
}

export default Section;
