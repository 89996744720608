/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotifyChainLinkRequest } from './NotifyChainLinkRequest';
import {
    NotifyChainLinkRequestFromJSON,
    NotifyChainLinkRequestFromJSONTyped,
    NotifyChainLinkRequestToJSON,
} from './NotifyChainLinkRequest';

/**
 * 
 * @export
 * @interface NotifyChainRequest
 */
export interface NotifyChainRequest {
    /**
     * 
     * @type {string}
     * @memberof NotifyChainRequest
     */
    componentId?: string;
    /**
     * 
     * @type {Array<NotifyChainLinkRequest>}
     * @memberof NotifyChainRequest
     */
    links?: Array<NotifyChainLinkRequest>;
}

/**
 * Check if a given object implements the NotifyChainRequest interface.
 */
export function instanceOfNotifyChainRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotifyChainRequestFromJSON(json: any): NotifyChainRequest {
    return NotifyChainRequestFromJSONTyped(json, false);
}

export function NotifyChainRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyChainRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(NotifyChainLinkRequestFromJSON)),
    };
}

export function NotifyChainRequestToJSON(value?: NotifyChainRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.componentId,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(NotifyChainLinkRequestToJSON)),
    };
}

