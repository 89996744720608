/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActiveEventDetails
 */
export interface ActiveEventDetails {
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    'component_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    'alert_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    'componentEventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActiveEventDetails
     */
    'messageCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    'firstMessageTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    'resolvedTime'?: string;
    /**
     * 
     * @type {HistoryItemDetails}
     * @memberof ActiveEventDetails
     */
    'lastMessage'?: HistoryItemDetails;
}
/**
 * 
 * @export
 * @interface AgentBasicDetails
 */
export interface AgentBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof AgentBasicDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicDetails
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicDetails
     */
    'status'?: AgentBasicDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicDetails
     */
    'type'?: AgentBasicDetailsTypeEnum;
}

export const AgentBasicDetailsStatusEnum = {
    Active: 'ACTIVE',
    Pending: 'PENDING',
    Disabled: 'DISABLED'
} as const;

export type AgentBasicDetailsStatusEnum = typeof AgentBasicDetailsStatusEnum[keyof typeof AgentBasicDetailsStatusEnum];
export const AgentBasicDetailsTypeEnum = {
    Zabbix: 'ZABBIX',
    Freshdesk: 'FRESHDESK'
} as const;

export type AgentBasicDetailsTypeEnum = typeof AgentBasicDetailsTypeEnum[keyof typeof AgentBasicDetailsTypeEnum];

/**
 * 
 * @export
 * @interface AgentConnectionDetails
 */
export interface AgentConnectionDetails {
    /**
     * 
     * @type {string}
     * @memberof AgentConnectionDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConnectionDetails
     */
    'lastConnected'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConnectionDetails
     */
    'lastDisconnected'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConnectionDetails
     */
    'lastCheck'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConnectionDetails
     */
    'lastIsConnected'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AgentConnectionDetails
     */
    'config'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof AgentConnectionDetails
     */
    'metricDefinition'?: { [key: string]: { [key: string]: string; }; };
}

export const AgentConnectionDetailsMetricDefinitionEnum = {
    String: 'STRING',
    Number: 'NUMBER',
    Boolean: 'BOOLEAN',
    Severity: 'SEVERITY',
    DateTime: 'DATE_TIME',
    Day: 'DAY',
    Month: 'MONTH',
    StringArray: 'STRING_ARRAY',
    NumberArray: 'NUMBER_ARRAY',
    BooleanArray: 'BOOLEAN_ARRAY',
    SeverityArray: 'SEVERITY_ARRAY',
    DateTimeArray: 'DATE_TIME_ARRAY',
    Regex: 'REGEX',
    Object: 'OBJECT'
} as const;

export type AgentConnectionDetailsMetricDefinitionEnum = typeof AgentConnectionDetailsMetricDefinitionEnum[keyof typeof AgentConnectionDetailsMetricDefinitionEnum];

/**
 * 
 * @export
 * @interface AgentDetails
 */
export interface AgentDetails {
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    'publicKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    'status'?: AgentDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentDetails
     */
    'type'?: AgentDetailsTypeEnum;
    /**
     * 
     * @type {ComponentBasicDetails}
     * @memberof AgentDetails
     */
    'component'?: ComponentBasicDetails;
    /**
     * 
     * @type {AgentConnectionDetails}
     * @memberof AgentDetails
     */
    'connectionInfo'?: AgentConnectionDetails;
}

export const AgentDetailsStatusEnum = {
    Active: 'ACTIVE',
    Pending: 'PENDING',
    Disabled: 'DISABLED'
} as const;

export type AgentDetailsStatusEnum = typeof AgentDetailsStatusEnum[keyof typeof AgentDetailsStatusEnum];
export const AgentDetailsTypeEnum = {
    Zabbix: 'ZABBIX',
    Freshdesk: 'FRESHDESK'
} as const;

export type AgentDetailsTypeEnum = typeof AgentDetailsTypeEnum[keyof typeof AgentDetailsTypeEnum];

/**
 * 
 * @export
 * @interface AgentRequest
 */
export interface AgentRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentRequest
     */
    'object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentRequest
     */
    'publicKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentRequest
     */
    'type'?: AgentRequestTypeEnum;
}

export const AgentRequestTypeEnum = {
    Zabbix: 'ZABBIX',
    Freshdesk: 'FRESHDESK'
} as const;

export type AgentRequestTypeEnum = typeof AgentRequestTypeEnum[keyof typeof AgentRequestTypeEnum];

/**
 * 
 * @export
 * @interface AlertActionRequest
 */
export interface AlertActionRequest {
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    'object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    'type'?: AlertActionRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    'info'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertActionRequest
     */
    'user_id'?: string;
}

export const AlertActionRequestTypeEnum = {
    Handle: 'HANDLE',
    Pass: 'PASS',
    Comment: 'COMMENT',
    Notify: 'NOTIFY',
    NotifyFail: 'NOTIFY_FAIL',
    UserClose: 'USER_CLOSE'
} as const;

export type AlertActionRequestTypeEnum = typeof AlertActionRequestTypeEnum[keyof typeof AlertActionRequestTypeEnum];

/**
 * 
 * @export
 * @interface AlertBasicDetails
 */
export interface AlertBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'component_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'componentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'ended'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'status'?: AlertBasicDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertBasicDetails
     */
    'componentState'?: AlertBasicDetailsComponentStateEnum;
    /**
     * 
     * @type {UserBasicDetails}
     * @memberof AlertBasicDetails
     */
    'handlingUser'?: UserBasicDetails;
}

export const AlertBasicDetailsStatusEnum = {
    ActiveOpen: 'ACTIVE_OPEN',
    NotActiveOpen: 'NOT_ACTIVE_OPEN',
    Closed: 'CLOSED'
} as const;

export type AlertBasicDetailsStatusEnum = typeof AlertBasicDetailsStatusEnum[keyof typeof AlertBasicDetailsStatusEnum];
export const AlertBasicDetailsComponentStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;

export type AlertBasicDetailsComponentStateEnum = typeof AlertBasicDetailsComponentStateEnum[keyof typeof AlertBasicDetailsComponentStateEnum];

/**
 * 
 * @export
 * @interface AlertDetails
 */
export interface AlertDetails {
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'component_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'componentState'?: AlertDetailsComponentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'componentName'?: string;
    /**
     * 
     * @type {Array<ClientBasicDetails>}
     * @memberof AlertDetails
     */
    'clients'?: Array<ClientBasicDetails>;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'ended'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'status'?: AlertDetailsStatusEnum;
    /**
     * 
     * @type {Array<EventLogItem>}
     * @memberof AlertDetails
     */
    'eventLog'?: Array<EventLogItem>;
    /**
     * 
     * @type {{ [key: string]: UserBasicDetails; }}
     * @memberof AlertDetails
     */
    'actionUsers'?: { [key: string]: UserBasicDetails; };
    /**
     * 
     * @type {UserBasicDetails}
     * @memberof AlertDetails
     */
    'handlingUser'?: UserBasicDetails;
    /**
     * 
     * @type {string}
     * @memberof AlertDetails
     */
    'agentName'?: string;
    /**
     * 
     * @type {RuleBasicDetails}
     * @memberof AlertDetails
     */
    'lastRule'?: RuleBasicDetails;
    /**
     * 
     * @type {NotifyChainDetails}
     * @memberof AlertDetails
     */
    'currentNotifyChain'?: NotifyChainDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertDetails
     */
    'notifiedUsers'?: Array<string>;
}

export const AlertDetailsComponentStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;

export type AlertDetailsComponentStateEnum = typeof AlertDetailsComponentStateEnum[keyof typeof AlertDetailsComponentStateEnum];
export const AlertDetailsStatusEnum = {
    ActiveOpen: 'ACTIVE_OPEN',
    NotActiveOpen: 'NOT_ACTIVE_OPEN',
    Closed: 'CLOSED'
} as const;

export type AlertDetailsStatusEnum = typeof AlertDetailsStatusEnum[keyof typeof AlertDetailsStatusEnum];

/**
 * 
 * @export
 * @interface BaseObjectRequest
 */
export interface BaseObjectRequest {
    /**
     * 
     * @type {string}
     * @memberof BaseObjectRequest
     */
    'object_id'?: string;
}
/**
 * 
 * @export
 * @interface ClientBasicDetails
 */
export interface ClientBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof ClientBasicDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBasicDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBasicDetails
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientBasicDetails
     */
    'systemClient'?: boolean;
}
/**
 * 
 * @export
 * @interface ClientDetails
 */
export interface ClientDetails {
    /**
     * 
     * @type {string}
     * @memberof ClientDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDetails
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ComponentBasicDetails>}
     * @memberof ClientDetails
     */
    'components'?: Array<ComponentBasicDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientDetails
     */
    'systemClient'?: boolean;
}
/**
 * 
 * @export
 * @interface ComponentBasicDetails
 */
export interface ComponentBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    'systemIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    'state'?: ComponentBasicDetailsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentBasicDetails
     */
    'stickyAlerts'?: boolean;
}

export const ComponentBasicDetailsStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;

export type ComponentBasicDetailsStateEnum = typeof ComponentBasicDetailsStateEnum[keyof typeof ComponentBasicDetailsStateEnum];

/**
 * 
 * @export
 * @interface ComponentDetails
 */
export interface ComponentDetails {
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    'systemIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    'description'?: string;
    /**
     * 
     * @type {AgentBasicDetails}
     * @memberof ComponentDetails
     */
    'agent'?: AgentBasicDetails;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetails
     */
    'state'?: ComponentDetailsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDetails
     */
    'stickyAlerts'?: boolean;
}

export const ComponentDetailsStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;

export type ComponentDetailsStateEnum = typeof ComponentDetailsStateEnum[keyof typeof ComponentDetailsStateEnum];

/**
 * 
 * @export
 * @interface CreateComponentRequest
 */
export interface CreateComponentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateComponentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateComponentRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateComponentRequest
     */
    'agent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateComponentRequest
     */
    'systemIdentifier'?: string;
}
/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface EventLogItem
 */
export interface EventLogItem {
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    'type'?: EventLogItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    'object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogItem
     */
    'tag'?: string;
}

export const EventLogItemTypeEnum = {
    Event: 'EVENT',
    Handle: 'HANDLE',
    Notify: 'NOTIFY',
    NotifyFail: 'NOTIFY_FAIL',
    Comment: 'COMMENT',
    Pass: 'PASS',
    StateChange: 'STATE_CHANGE'
} as const;

export type EventLogItemTypeEnum = typeof EventLogItemTypeEnum[keyof typeof EventLogItemTypeEnum];

/**
 * 
 * @export
 * @interface EventMetric
 */
export interface EventMetric {
    /**
     * 
     * @type {string}
     * @memberof EventMetric
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EventMetric
     */
    'payload'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface HistoryItemDetails
 */
export interface HistoryItemDetails {
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'component_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'receivedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'componentEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<EventMetric>}
     * @memberof HistoryItemDetails
     */
    'metrics'?: Array<EventMetric>;
    /**
     * 
     * @type {string}
     * @memberof HistoryItemDetails
     */
    'severity'?: HistoryItemDetailsSeverityEnum;
}

export const HistoryItemDetailsSeverityEnum = {
    Ok: 'OK',
    Info: 'INFO',
    Low: 'LOW',
    Moderate: 'MODERATE',
    High: 'HIGH',
    Severe: 'SEVERE'
} as const;

export type HistoryItemDetailsSeverityEnum = typeof HistoryItemDetailsSeverityEnum[keyof typeof HistoryItemDetailsSeverityEnum];

/**
 * 
 * @export
 * @interface InputPart
 */
export interface InputPart {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InputPart
     */
    'headers'?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof InputPart
     */
    'bodyAsString'?: string;
    /**
     * 
     * @type {InputPartMediaType}
     * @memberof InputPart
     */
    'mediaType'?: InputPartMediaType;
    /**
     * 
     * @type {boolean}
     * @memberof InputPart
     */
    'contentTypeFromMessage'?: boolean;
}
/**
 * 
 * @export
 * @interface InputPartMediaType
 */
export interface InputPartMediaType {
    /**
     * 
     * @type {string}
     * @memberof InputPartMediaType
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputPartMediaType
     */
    'subtype'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InputPartMediaType
     */
    'parameters'?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof InputPartMediaType
     */
    'wildcardType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InputPartMediaType
     */
    'wildcardSubtype'?: boolean;
}
/**
 * 
 * @export
 * @interface LoginDetails
 */
export interface LoginDetails {
    /**
     * 
     * @type {UserDetails}
     * @memberof LoginDetails
     */
    'userDetails'?: UserDetails;
    /**
     * 
     * @type {string}
     * @memberof LoginDetails
     */
    'authToken'?: string;
}
/**
 * 
 * @export
 * @interface MultipartFormDataInput
 */
export interface MultipartFormDataInput {
    /**
     * 
     * @type {{ [key: string]: Array<InputPart>; }}
     * @memberof MultipartFormDataInput
     */
    'formDataMap'?: { [key: string]: Array<InputPart>; };
    /**
     * 
     * @type {string}
     * @memberof MultipartFormDataInput
     */
    'preamble'?: string;
    /**
     * 
     * @type {Array<InputPart>}
     * @memberof MultipartFormDataInput
     */
    'parts'?: Array<InputPart>;
}
/**
 * 
 * @export
 * @interface NotifyChainDetails
 */
export interface NotifyChainDetails {
    /**
     * 
     * @type {string}
     * @memberof NotifyChainDetails
     */
    'component_id'?: string;
    /**
     * 
     * @type {Array<NotifyChainLinkDetails>}
     * @memberof NotifyChainDetails
     */
    'links'?: Array<NotifyChainLinkDetails>;
}
/**
 * 
 * @export
 * @interface NotifyChainLinkDetails
 */
export interface NotifyChainLinkDetails {
    /**
     * 
     * @type {number}
     * @memberof NotifyChainLinkDetails
     */
    'level'?: number;
    /**
     * 
     * @type {{ [key: string]: UserBasicDetails; }}
     * @memberof NotifyChainLinkDetails
     */
    'userMap'?: { [key: string]: UserBasicDetails; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof NotifyChainLinkDetails
     */
    'onCallMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof NotifyChainLinkDetails
     */
    'last'?: boolean;
}
/**
 * 
 * @export
 * @interface NotifyChainLinkRequest
 */
export interface NotifyChainLinkRequest {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof NotifyChainLinkRequest
     */
    'onCallMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof NotifyChainLinkRequest
     */
    'level'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotifyChainLinkRequest
     */
    'last'?: boolean;
}
/**
 * 
 * @export
 * @interface NotifyChainRequest
 */
export interface NotifyChainRequest {
    /**
     * 
     * @type {string}
     * @memberof NotifyChainRequest
     */
    'component_id'?: string;
    /**
     * 
     * @type {Array<NotifyChainLinkRequest>}
     * @memberof NotifyChainRequest
     */
    'links'?: Array<NotifyChainLinkRequest>;
}
/**
 * 
 * @export
 * @interface RESTResult
 */
export interface RESTResult {
    /**
     * 
     * @type {string}
     * @memberof RESTResult
     */
    'error'?: string;
    /**
     * 
     * @type {object}
     * @memberof RESTResult
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface RESTResultAgentDetails
 */
export interface RESTResultAgentDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultAgentDetails
     */
    'error'?: string;
    /**
     * 
     * @type {AgentDetails}
     * @memberof RESTResultAgentDetails
     */
    'data'?: AgentDetails;
}
/**
 * 
 * @export
 * @interface RESTResultAlertDetails
 */
export interface RESTResultAlertDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultAlertDetails
     */
    'error'?: string;
    /**
     * 
     * @type {AlertDetails}
     * @memberof RESTResultAlertDetails
     */
    'data'?: AlertDetails;
}
/**
 * 
 * @export
 * @interface RESTResultClientDetails
 */
export interface RESTResultClientDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultClientDetails
     */
    'error'?: string;
    /**
     * 
     * @type {ClientDetails}
     * @memberof RESTResultClientDetails
     */
    'data'?: ClientDetails;
}
/**
 * 
 * @export
 * @interface RESTResultComponentDetails
 */
export interface RESTResultComponentDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultComponentDetails
     */
    'error'?: string;
    /**
     * 
     * @type {ComponentDetails}
     * @memberof RESTResultComponentDetails
     */
    'data'?: ComponentDetails;
}
/**
 * 
 * @export
 * @interface RESTResultListActiveEventDetails
 */
export interface RESTResultListActiveEventDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListActiveEventDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ActiveEventDetails>}
     * @memberof RESTResultListActiveEventDetails
     */
    'data'?: Array<ActiveEventDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListAgentBasicDetails
 */
export interface RESTResultListAgentBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListAgentBasicDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<AgentBasicDetails>}
     * @memberof RESTResultListAgentBasicDetails
     */
    'data'?: Array<AgentBasicDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListAlertBasicDetails
 */
export interface RESTResultListAlertBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListAlertBasicDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<AlertBasicDetails>}
     * @memberof RESTResultListAlertBasicDetails
     */
    'data'?: Array<AlertBasicDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListClientDetails
 */
export interface RESTResultListClientDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListClientDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ClientDetails>}
     * @memberof RESTResultListClientDetails
     */
    'data'?: Array<ClientDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListComponentBasicDetails
 */
export interface RESTResultListComponentBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListComponentBasicDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ComponentBasicDetails>}
     * @memberof RESTResultListComponentBasicDetails
     */
    'data'?: Array<ComponentBasicDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListComponentDetails
 */
export interface RESTResultListComponentDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListComponentDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ComponentDetails>}
     * @memberof RESTResultListComponentDetails
     */
    'data'?: Array<ComponentDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListHistoryItemDetails
 */
export interface RESTResultListHistoryItemDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListHistoryItemDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<HistoryItemDetails>}
     * @memberof RESTResultListHistoryItemDetails
     */
    'data'?: Array<HistoryItemDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListStateHistoryDetails
 */
export interface RESTResultListStateHistoryDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListStateHistoryDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<StateHistoryDetails>}
     * @memberof RESTResultListStateHistoryDetails
     */
    'data'?: Array<StateHistoryDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultListUserDetails
 */
export interface RESTResultListUserDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListUserDetails
     */
    'error'?: string;
    /**
     * 
     * @type {Array<UserDetails>}
     * @memberof RESTResultListUserDetails
     */
    'data'?: Array<UserDetails>;
}
/**
 * 
 * @export
 * @interface RESTResultLoginDetails
 */
export interface RESTResultLoginDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultLoginDetails
     */
    'error'?: string;
    /**
     * 
     * @type {LoginDetails}
     * @memberof RESTResultLoginDetails
     */
    'data'?: LoginDetails;
}
/**
 * 
 * @export
 * @interface RESTResultNotifyChainDetails
 */
export interface RESTResultNotifyChainDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultNotifyChainDetails
     */
    'error'?: string;
    /**
     * 
     * @type {NotifyChainDetails}
     * @memberof RESTResultNotifyChainDetails
     */
    'data'?: NotifyChainDetails;
}
/**
 * 
 * @export
 * @interface RESTResultSessionDetails
 */
export interface RESTResultSessionDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultSessionDetails
     */
    'error'?: string;
    /**
     * 
     * @type {SessionDetails}
     * @memberof RESTResultSessionDetails
     */
    'data'?: SessionDetails;
}
/**
 * 
 * @export
 * @interface RESTResultString
 */
export interface RESTResultString {
    /**
     * 
     * @type {string}
     * @memberof RESTResultString
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof RESTResultString
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface RuleBasicDetails
 */
export interface RuleBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof RuleBasicDetails
     */
    'ruleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleBasicDetails
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SessionDetails
 */
export interface SessionDetails {
    /**
     * 
     * @type {UserDetails}
     * @memberof SessionDetails
     */
    'userDetails'?: UserDetails;
}
/**
 * 
 * @export
 * @interface StateHistoryDetails
 */
export interface StateHistoryDetails {
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    'component_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    'lastState'?: StateHistoryDetailsLastStateEnum;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    'nextState'?: StateHistoryDetailsNextStateEnum;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    'timeStamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateHistoryDetails
     */
    'ruleTrigger'?: string;
    /**
     * 
     * @type {Array<HistoryItemDetails>}
     * @memberof StateHistoryDetails
     */
    'events'?: Array<HistoryItemDetails>;
}

export const StateHistoryDetailsLastStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;

export type StateHistoryDetailsLastStateEnum = typeof StateHistoryDetailsLastStateEnum[keyof typeof StateHistoryDetailsLastStateEnum];
export const StateHistoryDetailsNextStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;

export type StateHistoryDetailsNextStateEnum = typeof StateHistoryDetailsNextStateEnum[keyof typeof StateHistoryDetailsNextStateEnum];

/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'platform'?: TokenRequestPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'token'?: string;
}

export const TokenRequestPlatformEnum = {
    Ios: 'IOS',
    Macos: 'MACOS',
    Android: 'ANDROID'
} as const;

export type TokenRequestPlatformEnum = typeof TokenRequestPlatformEnum[keyof typeof TokenRequestPlatformEnum];

/**
 * 
 * @export
 * @interface UpdateClientRequest
 */
export interface UpdateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    'object_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateClientRequest
     */
    'components'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRuleSetRequest
 */
export interface UpdateRuleSetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleSetRequest
     */
    'object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleSetRequest
     */
    'rules'?: string;
}
/**
 * 
 * @export
 * @interface UserBasicDetails
 */
export interface UserBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof UserBasicDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDetails
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<UserRoleDetails>}
     * @memberof UserDetails
     */
    'roles'?: Array<UserRoleDetails>;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'timeZone'?: string;
}
/**
 * 
 * @export
 * @interface UserRoleDetails
 */
export interface UserRoleDetails {
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetails
     */
    'name'?: UserRoleDetailsNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetails
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetails
     */
    'scope'?: UserRoleDetailsScopeEnum;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserRoleDetails
     */
    'access'?: { [key: string]: Array<string>; };
}

export const UserRoleDetailsNameEnum = {
    ShedAdmin: 'SHED_ADMIN',
    ClientAdmin: 'CLIENT_ADMIN',
    ReadOnly: 'READ_ONLY',
    Responder: 'RESPONDER'
} as const;

export type UserRoleDetailsNameEnum = typeof UserRoleDetailsNameEnum[keyof typeof UserRoleDetailsNameEnum];
export const UserRoleDetailsScopeEnum = {
    Global: 'GLOBAL',
    Client: 'CLIENT',
    Component: 'COMPONENT'
} as const;

export type UserRoleDetailsScopeEnum = typeof UserRoleDetailsScopeEnum[keyof typeof UserRoleDetailsScopeEnum];
export const UserRoleDetailsAccessEnum = {
    Read: 'READ',
    Write: 'WRITE',
    Delete: 'DELETE',
    Create: 'CREATE'
} as const;

export type UserRoleDetailsAccessEnum = typeof UserRoleDetailsAccessEnum[keyof typeof UserRoleDetailsAccessEnum];


/**
 * PerentieAuthenticateWebServiceApi - axios parameter creator
 * @export
 */
export const PerentieAuthenticateWebServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authenticate users
         * @param {Credentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (credentials?: Credentials, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if Session still authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/checksession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerentieAuthenticateWebServiceApi - functional programming interface
 * @export
 */
export const PerentieAuthenticateWebServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PerentieAuthenticateWebServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Authenticate users
         * @param {Credentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(credentials?: Credentials, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultLoginDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(credentials, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PerentieAuthenticateWebServiceApi.authenticate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Check if Session still authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultSessionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkSession(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PerentieAuthenticateWebServiceApi.checkSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Logout Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PerentieAuthenticateWebServiceApi.logout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PerentieAuthenticateWebServiceApi - factory interface
 * @export
 */
export const PerentieAuthenticateWebServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerentieAuthenticateWebServiceApiFp(configuration)
    return {
        /**
         * Authenticate users
         * @param {Credentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(credentials?: Credentials, options?: any): AxiosPromise<RESTResultLoginDetails> {
            return localVarFp.authenticate(credentials, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if Session still authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSession(options?: any): AxiosPromise<RESTResultSessionDetails> {
            return localVarFp.checkSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Logout Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<RESTResultString> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PerentieAuthenticateWebServiceApi - interface
 * @export
 * @interface PerentieAuthenticateWebServiceApi
 */
export interface PerentieAuthenticateWebServiceApiInterface {
    /**
     * Authenticate users
     * @param {Credentials} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWebServiceApiInterface
     */
    authenticate(credentials?: Credentials, options?: AxiosRequestConfig): AxiosPromise<RESTResultLoginDetails>;

    /**
     * Check if Session still authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWebServiceApiInterface
     */
    checkSession(options?: AxiosRequestConfig): AxiosPromise<RESTResultSessionDetails>;

    /**
     * Logout Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWebServiceApiInterface
     */
    logout(options?: AxiosRequestConfig): AxiosPromise<RESTResultString>;

}

/**
 * PerentieAuthenticateWebServiceApi - object-oriented interface
 * @export
 * @class PerentieAuthenticateWebServiceApi
 * @extends {BaseAPI}
 */
export class PerentieAuthenticateWebServiceApi extends BaseAPI implements PerentieAuthenticateWebServiceApiInterface {
    /**
     * Authenticate users
     * @param {Credentials} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWebServiceApi
     */
    public authenticate(credentials?: Credentials, options?: AxiosRequestConfig) {
        return PerentieAuthenticateWebServiceApiFp(this.configuration).authenticate(credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if Session still authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWebServiceApi
     */
    public checkSession(options?: AxiosRequestConfig) {
        return PerentieAuthenticateWebServiceApiFp(this.configuration).checkSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieAuthenticateWebServiceApi
     */
    public logout(options?: AxiosRequestConfig) {
        return PerentieAuthenticateWebServiceApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestPerentieWSApi - axios parameter creator
 * @export
 */
export const TestPerentieWSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Test endpoint. returns pong with the time and version string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test rule endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/rule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestPerentieWSApi - functional programming interface
 * @export
 */
export const TestPerentieWSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestPerentieWSApiAxiosParamCreator(configuration)
    return {
        /**
         * Test endpoint. returns pong with the time and version string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestPerentieWSApi.ping']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Test rule endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ruleTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ruleTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestPerentieWSApi.ruleTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TestPerentieWSApi - factory interface
 * @export
 */
export const TestPerentieWSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestPerentieWSApiFp(configuration)
    return {
        /**
         * Test endpoint. returns pong with the time and version string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<RESTResultString> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
        /**
         * Test rule endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleTest(options?: any): AxiosPromise<RESTResult> {
            return localVarFp.ruleTest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestPerentieWSApi - interface
 * @export
 * @interface TestPerentieWSApi
 */
export interface TestPerentieWSApiInterface {
    /**
     * Test endpoint. returns pong with the time and version string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPerentieWSApiInterface
     */
    ping(options?: AxiosRequestConfig): AxiosPromise<RESTResultString>;

    /**
     * Test rule endpoint.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPerentieWSApiInterface
     */
    ruleTest(options?: AxiosRequestConfig): AxiosPromise<RESTResult>;

}

/**
 * TestPerentieWSApi - object-oriented interface
 * @export
 * @class TestPerentieWSApi
 * @extends {BaseAPI}
 */
export class TestPerentieWSApi extends BaseAPI implements TestPerentieWSApiInterface {
    /**
     * Test endpoint. returns pong with the time and version string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPerentieWSApi
     */
    public ping(options?: AxiosRequestConfig) {
        return TestPerentieWSApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test rule endpoint.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPerentieWSApi
     */
    public ruleTest(options?: AxiosRequestConfig) {
        return TestPerentieWSApiFp(this.configuration).ruleTest(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebServiceForActionsRelatedToSessionUserApi - axios parameter creator
 * @export
 */
export const WebServiceForActionsRelatedToSessionUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send notification token
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyDetails: async (tokenRequest?: TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/notifyDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceForActionsRelatedToSessionUserApi - functional programming interface
 * @export
 */
export const WebServiceForActionsRelatedToSessionUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceForActionsRelatedToSessionUserApiAxiosParamCreator(configuration)
    return {
        /**
         * Send notification token
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifyDetails(tokenRequest?: TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifyDetails(tokenRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForActionsRelatedToSessionUserApi.updateNotifyDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebServiceForActionsRelatedToSessionUserApi - factory interface
 * @export
 */
export const WebServiceForActionsRelatedToSessionUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceForActionsRelatedToSessionUserApiFp(configuration)
    return {
        /**
         * Send notification token
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyDetails(tokenRequest?: TokenRequest, options?: any): AxiosPromise<RESTResult> {
            return localVarFp.updateNotifyDetails(tokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebServiceForActionsRelatedToSessionUserApi - interface
 * @export
 * @interface WebServiceForActionsRelatedToSessionUserApi
 */
export interface WebServiceForActionsRelatedToSessionUserApiInterface {
    /**
     * Send notification token
     * @param {TokenRequest} [tokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForActionsRelatedToSessionUserApiInterface
     */
    updateNotifyDetails(tokenRequest?: TokenRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResult>;

}

/**
 * WebServiceForActionsRelatedToSessionUserApi - object-oriented interface
 * @export
 * @class WebServiceForActionsRelatedToSessionUserApi
 * @extends {BaseAPI}
 */
export class WebServiceForActionsRelatedToSessionUserApi extends BaseAPI implements WebServiceForActionsRelatedToSessionUserApiInterface {
    /**
     * Send notification token
     * @param {TokenRequest} [tokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForActionsRelatedToSessionUserApi
     */
    public updateNotifyDetails(tokenRequest?: TokenRequest, options?: AxiosRequestConfig) {
        return WebServiceForActionsRelatedToSessionUserApiFp(this.configuration).updateNotifyDetails(tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebServiceForAdminFunctionsApi - axios parameter creator
 * @export
 */
export const WebServiceForAdminFunctionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ADMIN ONLY - Create a new PerentieClient
         * @param {UpdateClientRequest} [updateClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerentieClient: async (updateClientRequest?: UpdateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/createClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - Download component config yml.
         * @param {Array<string>} [comp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComponentConfig: async (comp?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/componentConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (comp) {
                localVarQueryParameter['comp'] = comp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - Get Agent Details
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentDetails: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAgentDetails', 'agentId', agentId)
            const localVarPath = `/admin/agent/{agent_id}`
                .replace(`{${"agent_id"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - Get all Perentie Clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerentieClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - Get all Perentie Components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerentieComponents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - Registers a new agent
         * @param {AgentRequest} [agentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAgent: async (agentRequest?: AgentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/registerAgent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - Updates and existing agent\'s details
         * @param {AgentRequest} [agentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (agentRequest?: AgentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/updateAgent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ADMIN ONLY - upload perentie component yaml config file
         * @param {string} [preamble] 
         * @param {Array<InputPart>} [parts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadComponentConfig: async (preamble?: string, parts?: Array<InputPart>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/uploadCompConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (preamble !== undefined) { 
                localVarFormParams.append('preamble', preamble as any);
            }
                if (parts) {
                localVarFormParams.append('parts', parts.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceForAdminFunctionsApi - functional programming interface
 * @export
 */
export const WebServiceForAdminFunctionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceForAdminFunctionsApiAxiosParamCreator(configuration)
    return {
        /**
         * ADMIN ONLY - Create a new PerentieClient
         * @param {UpdateClientRequest} [updateClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerentieClient(updateClientRequest?: UpdateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultClientDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerentieClient(updateClientRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.createPerentieClient']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ADMIN ONLY - Download component config yml.
         * @param {Array<string>} [comp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComponentConfig(comp?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComponentConfig(comp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ADMIN ONLY - Get Agent Details
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentDetails(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAgentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentDetails(agentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.getAgentDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListAgentBasicDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgents(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.getAgents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ADMIN ONLY - Get all Perentie Clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPerentieClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListClientDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPerentieClients(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.getAllPerentieClients']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ADMIN ONLY - Get all Perentie Components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPerentieComponents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListComponentBasicDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPerentieComponents(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.getAllPerentieComponents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListUserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.getUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ADMIN ONLY - Registers a new agent
         * @param {AgentRequest} [agentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAgent(agentRequest?: AgentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAgentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAgent(agentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.registerAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ADMIN ONLY - Updates and existing agent\'s details
         * @param {AgentRequest} [agentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(agentRequest?: AgentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAgentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgent(agentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForAdminFunctionsApi.updateAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ADMIN ONLY - upload perentie component yaml config file
         * @param {string} [preamble] 
         * @param {Array<InputPart>} [parts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadComponentConfig(preamble?: string, parts?: Array<InputPart>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadComponentConfig(preamble, parts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebServiceForAdminFunctionsApi - factory interface
 * @export
 */
export const WebServiceForAdminFunctionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceForAdminFunctionsApiFp(configuration)
    return {
        /**
         * ADMIN ONLY - Create a new PerentieClient
         * @param {UpdateClientRequest} [updateClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerentieClient(updateClientRequest?: UpdateClientRequest, options?: any): AxiosPromise<RESTResultClientDetails> {
            return localVarFp.createPerentieClient(updateClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - Download component config yml.
         * @param {Array<string>} [comp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComponentConfig(comp?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.downloadComponentConfig(comp, options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - Get Agent Details
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentDetails(agentId: string, options?: any): AxiosPromise<RESTResultAgentDetails> {
            return localVarFp.getAgentDetails(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(options?: any): AxiosPromise<RESTResultListAgentBasicDetails> {
            return localVarFp.getAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - Get all Perentie Clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerentieClients(options?: any): AxiosPromise<RESTResultListClientDetails> {
            return localVarFp.getAllPerentieClients(options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - Get all Perentie Components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerentieComponents(options?: any): AxiosPromise<RESTResultListComponentBasicDetails> {
            return localVarFp.getAllPerentieComponents(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<RESTResultListUserDetails> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - Registers a new agent
         * @param {AgentRequest} [agentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAgent(agentRequest?: AgentRequest, options?: any): AxiosPromise<RESTResultAgentDetails> {
            return localVarFp.registerAgent(agentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - Updates and existing agent\'s details
         * @param {AgentRequest} [agentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(agentRequest?: AgentRequest, options?: any): AxiosPromise<RESTResultAgentDetails> {
            return localVarFp.updateAgent(agentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ADMIN ONLY - upload perentie component yaml config file
         * @param {string} [preamble] 
         * @param {Array<InputPart>} [parts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadComponentConfig(preamble?: string, parts?: Array<InputPart>, options?: any): AxiosPromise<RESTResult> {
            return localVarFp.uploadComponentConfig(preamble, parts, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebServiceForAdminFunctionsApi - interface
 * @export
 * @interface WebServiceForAdminFunctionsApi
 */
export interface WebServiceForAdminFunctionsApiInterface {
    /**
     * ADMIN ONLY - Create a new PerentieClient
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    createPerentieClient(updateClientRequest?: UpdateClientRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultClientDetails>;

    /**
     * ADMIN ONLY - Download component config yml.
     * @param {Array<string>} [comp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    downloadComponentConfig(comp?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ADMIN ONLY - Get Agent Details
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    getAgentDetails(agentId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultAgentDetails>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    getAgents(options?: AxiosRequestConfig): AxiosPromise<RESTResultListAgentBasicDetails>;

    /**
     * ADMIN ONLY - Get all Perentie Clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    getAllPerentieClients(options?: AxiosRequestConfig): AxiosPromise<RESTResultListClientDetails>;

    /**
     * ADMIN ONLY - Get all Perentie Components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    getAllPerentieComponents(options?: AxiosRequestConfig): AxiosPromise<RESTResultListComponentBasicDetails>;

    /**
     * Returns all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    getUsers(options?: AxiosRequestConfig): AxiosPromise<RESTResultListUserDetails>;

    /**
     * ADMIN ONLY - Registers a new agent
     * @param {AgentRequest} [agentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    registerAgent(agentRequest?: AgentRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultAgentDetails>;

    /**
     * ADMIN ONLY - Updates and existing agent\'s details
     * @param {AgentRequest} [agentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    updateAgent(agentRequest?: AgentRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultAgentDetails>;

    /**
     * ADMIN ONLY - upload perentie component yaml config file
     * @param {string} [preamble] 
     * @param {Array<InputPart>} [parts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApiInterface
     */
    uploadComponentConfig(preamble?: string, parts?: Array<InputPart>, options?: AxiosRequestConfig): AxiosPromise<RESTResult>;

}

/**
 * WebServiceForAdminFunctionsApi - object-oriented interface
 * @export
 * @class WebServiceForAdminFunctionsApi
 * @extends {BaseAPI}
 */
export class WebServiceForAdminFunctionsApi extends BaseAPI implements WebServiceForAdminFunctionsApiInterface {
    /**
     * ADMIN ONLY - Create a new PerentieClient
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public createPerentieClient(updateClientRequest?: UpdateClientRequest, options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).createPerentieClient(updateClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - Download component config yml.
     * @param {Array<string>} [comp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public downloadComponentConfig(comp?: Array<string>, options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).downloadComponentConfig(comp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - Get Agent Details
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public getAgentDetails(agentId: string, options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).getAgentDetails(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public getAgents(options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).getAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - Get all Perentie Clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public getAllPerentieClients(options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).getAllPerentieClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - Get all Perentie Components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public getAllPerentieComponents(options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).getAllPerentieComponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - Registers a new agent
     * @param {AgentRequest} [agentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public registerAgent(agentRequest?: AgentRequest, options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).registerAgent(agentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - Updates and existing agent\'s details
     * @param {AgentRequest} [agentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public updateAgent(agentRequest?: AgentRequest, options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).updateAgent(agentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ADMIN ONLY - upload perentie component yaml config file
     * @param {string} [preamble] 
     * @param {Array<InputPart>} [parts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForAdminFunctionsApi
     */
    public uploadComponentConfig(preamble?: string, parts?: Array<InputPart>, options?: AxiosRequestConfig) {
        return WebServiceForAdminFunctionsApiFp(this.configuration).uploadComponentConfig(preamble, parts, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebServiceForClientAndClientAdminFunctionsApi - axios parameter creator
 * @export
 */
export const WebServiceForClientAndClientAdminFunctionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the list of components for client
         * @param {string} clientId 
         * @param {UpdateClientRequest} [updateClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientComponents: async (clientId: string, updateClientRequest?: UpdateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('updateClientComponents', 'clientId', clientId)
            const localVarPath = `/client/{client_id}/updatecomponents`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceForClientAndClientAdminFunctionsApi - functional programming interface
 * @export
 */
export const WebServiceForClientAndClientAdminFunctionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceForClientAndClientAdminFunctionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the list of components for client
         * @param {string} clientId 
         * @param {UpdateClientRequest} [updateClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientComponents(clientId: string, updateClientRequest?: UpdateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultClientDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientComponents(clientId, updateClientRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForClientAndClientAdminFunctionsApi.updateClientComponents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebServiceForClientAndClientAdminFunctionsApi - factory interface
 * @export
 */
export const WebServiceForClientAndClientAdminFunctionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceForClientAndClientAdminFunctionsApiFp(configuration)
    return {
        /**
         * Updates the list of components for client
         * @param {string} clientId 
         * @param {UpdateClientRequest} [updateClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientComponents(clientId: string, updateClientRequest?: UpdateClientRequest, options?: any): AxiosPromise<RESTResultClientDetails> {
            return localVarFp.updateClientComponents(clientId, updateClientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebServiceForClientAndClientAdminFunctionsApi - interface
 * @export
 * @interface WebServiceForClientAndClientAdminFunctionsApi
 */
export interface WebServiceForClientAndClientAdminFunctionsApiInterface {
    /**
     * Updates the list of components for client
     * @param {string} clientId 
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForClientAndClientAdminFunctionsApiInterface
     */
    updateClientComponents(clientId: string, updateClientRequest?: UpdateClientRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultClientDetails>;

}

/**
 * WebServiceForClientAndClientAdminFunctionsApi - object-oriented interface
 * @export
 * @class WebServiceForClientAndClientAdminFunctionsApi
 * @extends {BaseAPI}
 */
export class WebServiceForClientAndClientAdminFunctionsApi extends BaseAPI implements WebServiceForClientAndClientAdminFunctionsApiInterface {
    /**
     * Updates the list of components for client
     * @param {string} clientId 
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForClientAndClientAdminFunctionsApi
     */
    public updateClientComponents(clientId: string, updateClientRequest?: UpdateClientRequest, options?: AxiosRequestConfig) {
        return WebServiceForClientAndClientAdminFunctionsApiFp(this.configuration).updateClientComponents(clientId, updateClientRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebServiceForHandlingNotificationsAlertApi - axios parameter creator
 * @export
 */
export const WebServiceForHandlingNotificationsAlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Session user handle alert
         * @param {BaseObjectRequest} [baseObjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handle: async (baseObjectRequest?: BaseObjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/handle/alert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseObjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass handling responsibility onto another user. Session user must be the user currently handling the Alert
         * @param {AlertActionRequest} [alertActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passAlert: async (alertActionRequest?: AlertActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/handle/pass`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceForHandlingNotificationsAlertApi - functional programming interface
 * @export
 */
export const WebServiceForHandlingNotificationsAlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceForHandlingNotificationsAlertApiAxiosParamCreator(configuration)
    return {
        /**
         * Session user handle alert
         * @param {BaseObjectRequest} [baseObjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handle(baseObjectRequest?: BaseObjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAlertDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handle(baseObjectRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForHandlingNotificationsAlertApi.handle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Pass handling responsibility onto another user. Session user must be the user currently handling the Alert
         * @param {AlertActionRequest} [alertActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passAlert(alertActionRequest?: AlertActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAlertDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passAlert(alertActionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForHandlingNotificationsAlertApi.passAlert']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebServiceForHandlingNotificationsAlertApi - factory interface
 * @export
 */
export const WebServiceForHandlingNotificationsAlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceForHandlingNotificationsAlertApiFp(configuration)
    return {
        /**
         * Session user handle alert
         * @param {BaseObjectRequest} [baseObjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handle(baseObjectRequest?: BaseObjectRequest, options?: any): AxiosPromise<RESTResultAlertDetails> {
            return localVarFp.handle(baseObjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass handling responsibility onto another user. Session user must be the user currently handling the Alert
         * @param {AlertActionRequest} [alertActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passAlert(alertActionRequest?: AlertActionRequest, options?: any): AxiosPromise<RESTResultAlertDetails> {
            return localVarFp.passAlert(alertActionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebServiceForHandlingNotificationsAlertApi - interface
 * @export
 * @interface WebServiceForHandlingNotificationsAlertApi
 */
export interface WebServiceForHandlingNotificationsAlertApiInterface {
    /**
     * Session user handle alert
     * @param {BaseObjectRequest} [baseObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForHandlingNotificationsAlertApiInterface
     */
    handle(baseObjectRequest?: BaseObjectRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultAlertDetails>;

    /**
     * Pass handling responsibility onto another user. Session user must be the user currently handling the Alert
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForHandlingNotificationsAlertApiInterface
     */
    passAlert(alertActionRequest?: AlertActionRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultAlertDetails>;

}

/**
 * WebServiceForHandlingNotificationsAlertApi - object-oriented interface
 * @export
 * @class WebServiceForHandlingNotificationsAlertApi
 * @extends {BaseAPI}
 */
export class WebServiceForHandlingNotificationsAlertApi extends BaseAPI implements WebServiceForHandlingNotificationsAlertApiInterface {
    /**
     * Session user handle alert
     * @param {BaseObjectRequest} [baseObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForHandlingNotificationsAlertApi
     */
    public handle(baseObjectRequest?: BaseObjectRequest, options?: AxiosRequestConfig) {
        return WebServiceForHandlingNotificationsAlertApiFp(this.configuration).handle(baseObjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass handling responsibility onto another user. Session user must be the user currently handling the Alert
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForHandlingNotificationsAlertApi
     */
    public passAlert(alertActionRequest?: AlertActionRequest, options?: AxiosRequestConfig) {
        return WebServiceForHandlingNotificationsAlertApiFp(this.configuration).passAlert(alertActionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebServiceForPerentieAlertsApi - axios parameter creator
 * @export
 */
export const WebServiceForPerentieAlertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add Comment to an Alert
         * @param {string} alertId 
         * @param {AlertActionRequest} [alertActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToAlert: async (alertId: string, alertActionRequest?: AlertActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('addCommentToAlert', 'alertId', alertId)
            const localVarPath = `/alerts/{alert_id}/comment`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add Comment to an Alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAlert: async (alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('closeAlert', 'alertId', alertId)
            const localVarPath = `/alerts/{alert_id}/close`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get alert details
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertDetails: async (alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('getAlertDetails', 'alertId', alertId)
            const localVarPath = `/alerts/{alert_id}`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Summary of the active events for an alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertEventSummary: async (alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('getAlertEventSummary', 'alertId', alertId)
            const localVarPath = `/alerts/{alert_id}/eventSummary`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the component state history of component for alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentStateHistoryForAlert: async (alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('getComponentStateHistoryForAlert', 'alertId', alertId)
            const localVarPath = `/alerts/{alert_id}/stateHistory`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts/openalerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceForPerentieAlertsApi - functional programming interface
 * @export
 */
export const WebServiceForPerentieAlertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceForPerentieAlertsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add Comment to an Alert
         * @param {string} alertId 
         * @param {AlertActionRequest} [alertActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentToAlert(alertId: string, alertActionRequest?: AlertActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAlertDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommentToAlert(alertId, alertActionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieAlertsApi.addCommentToAlert']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Add Comment to an Alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeAlert(alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAlertDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeAlert(alertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAlerts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListAlertBasicDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAlerts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieAlertsApi.getActiveAlerts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get alert details
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertDetails(alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAlertDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertDetails(alertId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieAlertsApi.getAlertDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get a Summary of the active events for an alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertEventSummary(alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListActiveEventDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertEventSummary(alertId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieAlertsApi.getAlertEventSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the component state history of component for alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponentStateHistoryForAlert(alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListStateHistoryDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponentStateHistoryForAlert(alertId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieAlertsApi.getComponentStateHistoryForAlert']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenAlerts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListAlertBasicDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenAlerts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebServiceForPerentieAlertsApi - factory interface
 * @export
 */
export const WebServiceForPerentieAlertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceForPerentieAlertsApiFp(configuration)
    return {
        /**
         * Add Comment to an Alert
         * @param {string} alertId 
         * @param {AlertActionRequest} [alertActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToAlert(alertId: string, alertActionRequest?: AlertActionRequest, options?: any): AxiosPromise<RESTResultAlertDetails> {
            return localVarFp.addCommentToAlert(alertId, alertActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add Comment to an Alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAlert(alertId: string, options?: any): AxiosPromise<RESTResultAlertDetails> {
            return localVarFp.closeAlert(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlerts(options?: any): AxiosPromise<RESTResultListAlertBasicDetails> {
            return localVarFp.getActiveAlerts(options).then((request) => request(axios, basePath));
        },
        /**
         * Get alert details
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertDetails(alertId: string, options?: any): AxiosPromise<RESTResultAlertDetails> {
            return localVarFp.getAlertDetails(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Summary of the active events for an alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertEventSummary(alertId: string, options?: any): AxiosPromise<RESTResultListActiveEventDetails> {
            return localVarFp.getAlertEventSummary(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the component state history of component for alert
         * @param {string} alertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentStateHistoryForAlert(alertId: string, options?: any): AxiosPromise<RESTResultListStateHistoryDetails> {
            return localVarFp.getComponentStateHistoryForAlert(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAlerts(options?: any): AxiosPromise<RESTResultListAlertBasicDetails> {
            return localVarFp.getOpenAlerts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebServiceForPerentieAlertsApi - interface
 * @export
 * @interface WebServiceForPerentieAlertsApi
 */
export interface WebServiceForPerentieAlertsApiInterface {
    /**
     * Add Comment to an Alert
     * @param {string} alertId 
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    addCommentToAlert(alertId: string, alertActionRequest?: AlertActionRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultAlertDetails>;

    /**
     * Add Comment to an Alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    closeAlert(alertId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultAlertDetails>;

    /**
     * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    getActiveAlerts(options?: AxiosRequestConfig): AxiosPromise<RESTResultListAlertBasicDetails>;

    /**
     * Get alert details
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    getAlertDetails(alertId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultAlertDetails>;

    /**
     * Get a Summary of the active events for an alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    getAlertEventSummary(alertId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultListActiveEventDetails>;

    /**
     * Get the component state history of component for alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    getComponentStateHistoryForAlert(alertId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultListStateHistoryDetails>;

    /**
     * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApiInterface
     */
    getOpenAlerts(options?: AxiosRequestConfig): AxiosPromise<RESTResultListAlertBasicDetails>;

}

/**
 * WebServiceForPerentieAlertsApi - object-oriented interface
 * @export
 * @class WebServiceForPerentieAlertsApi
 * @extends {BaseAPI}
 */
export class WebServiceForPerentieAlertsApi extends BaseAPI implements WebServiceForPerentieAlertsApiInterface {
    /**
     * Add Comment to an Alert
     * @param {string} alertId 
     * @param {AlertActionRequest} [alertActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public addCommentToAlert(alertId: string, alertActionRequest?: AlertActionRequest, options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).addCommentToAlert(alertId, alertActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add Comment to an Alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public closeAlert(alertId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).closeAlert(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get active alerts for logged in user. This is only alerts that don\'t have an ended date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public getActiveAlerts(options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).getActiveAlerts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get alert details
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public getAlertDetails(alertId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).getAlertDetails(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Summary of the active events for an alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public getAlertEventSummary(alertId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).getAlertEventSummary(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the component state history of component for alert
     * @param {string} alertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public getComponentStateHistoryForAlert(alertId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).getComponentStateHistoryForAlert(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get alerts that are active (active means enddate is null) or have status NOT_ACTIVE_OPEN (sticky alerts). A component may have more than one alert with status NOT_ACTIVE_OPEN.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieAlertsApi
     */
    public getOpenAlerts(options?: AxiosRequestConfig) {
        return WebServiceForPerentieAlertsApiFp(this.configuration).getOpenAlerts(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebServiceForPerentieComponentsApi - axios parameter creator
 * @export
 */
export const WebServiceForPerentieComponentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new Component
         * @param {CreateComponentRequest} [createComponentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComponent: async (createComponentRequest?: CreateComponentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/components/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComponentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the active Perentie Alert for this component if there is one
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlert: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getActiveAlert', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/activeAlert`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns All the alerts for a component including any active alert
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentAlertHistory: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getComponentAlertHistory', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/alertHistory`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns set number of History Items from start index to num for component
         * @param {string} componentId 
         * @param {number} [start] 
         * @param {number} [num] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentEventHistory: async (componentId: string, start?: number, num?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getComponentEventHistory', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/history`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notify chain for component
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentNotifyChain: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getComponentNotifyChain', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/notifyChain`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentRuleSet: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getComponentRuleSet', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/ruleSet`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Components for this user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/components/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} componentId 
         * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComponentRuleSet: async (componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('updateComponentRuleSet', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/updateRuleSet`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRuleSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update notify chain of component
         * @param {string} componentId 
         * @param {NotifyChainRequest} [notifyChainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyChain: async (componentId: string, notifyChainRequest?: NotifyChainRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('updateNotifyChain', 'componentId', componentId)
            const localVarPath = `/components/{component_id}/updateNotifyChain`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyChainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceForPerentieComponentsApi - functional programming interface
 * @export
 */
export const WebServiceForPerentieComponentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceForPerentieComponentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new Component
         * @param {CreateComponentRequest} [createComponentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComponent(createComponentRequest?: CreateComponentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultComponentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComponent(createComponentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.createComponent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns the active Perentie Alert for this component if there is one
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAlert(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultAlertDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAlert(componentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.getActiveAlert']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns All the alerts for a component including any active alert
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponentAlertHistory(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListAlertBasicDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponentAlertHistory(componentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.getComponentAlertHistory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns set number of History Items from start index to num for component
         * @param {string} componentId 
         * @param {number} [start] 
         * @param {number} [num] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponentEventHistory(componentId: string, start?: number, num?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListHistoryItemDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponentEventHistory(componentId, start, num, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.getComponentEventHistory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get notify chain for component
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponentNotifyChain(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultNotifyChainDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponentNotifyChain(componentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.getComponentNotifyChain']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponentRuleSet(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponentRuleSet(componentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.getComponentRuleSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns Components for this user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultListComponentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponents(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.getComponents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} componentId 
         * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComponentRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComponentRuleSet(componentId, updateRuleSetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.updateComponentRuleSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update notify chain of component
         * @param {string} componentId 
         * @param {NotifyChainRequest} [notifyChainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifyChain(componentId: string, notifyChainRequest?: NotifyChainRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RESTResultNotifyChainDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifyChain(componentId, notifyChainRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebServiceForPerentieComponentsApi.updateNotifyChain']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebServiceForPerentieComponentsApi - factory interface
 * @export
 */
export const WebServiceForPerentieComponentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceForPerentieComponentsApiFp(configuration)
    return {
        /**
         * Create new Component
         * @param {CreateComponentRequest} [createComponentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComponent(createComponentRequest?: CreateComponentRequest, options?: any): AxiosPromise<RESTResultComponentDetails> {
            return localVarFp.createComponent(createComponentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the active Perentie Alert for this component if there is one
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlert(componentId: string, options?: any): AxiosPromise<RESTResultAlertDetails> {
            return localVarFp.getActiveAlert(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns All the alerts for a component including any active alert
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentAlertHistory(componentId: string, options?: any): AxiosPromise<RESTResultListAlertBasicDetails> {
            return localVarFp.getComponentAlertHistory(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns set number of History Items from start index to num for component
         * @param {string} componentId 
         * @param {number} [start] 
         * @param {number} [num] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentEventHistory(componentId: string, start?: number, num?: number, options?: any): AxiosPromise<RESTResultListHistoryItemDetails> {
            return localVarFp.getComponentEventHistory(componentId, start, num, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notify chain for component
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentNotifyChain(componentId: string, options?: any): AxiosPromise<RESTResultNotifyChainDetails> {
            return localVarFp.getComponentNotifyChain(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} componentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentRuleSet(componentId: string, options?: any): AxiosPromise<RESTResultString> {
            return localVarFp.getComponentRuleSet(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Components for this user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponents(options?: any): AxiosPromise<RESTResultListComponentDetails> {
            return localVarFp.getComponents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} componentId 
         * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComponentRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, options?: any): AxiosPromise<RESTResultString> {
            return localVarFp.updateComponentRuleSet(componentId, updateRuleSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update notify chain of component
         * @param {string} componentId 
         * @param {NotifyChainRequest} [notifyChainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyChain(componentId: string, notifyChainRequest?: NotifyChainRequest, options?: any): AxiosPromise<RESTResultNotifyChainDetails> {
            return localVarFp.updateNotifyChain(componentId, notifyChainRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebServiceForPerentieComponentsApi - interface
 * @export
 * @interface WebServiceForPerentieComponentsApi
 */
export interface WebServiceForPerentieComponentsApiInterface {
    /**
     * Create new Component
     * @param {CreateComponentRequest} [createComponentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    createComponent(createComponentRequest?: CreateComponentRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultComponentDetails>;

    /**
     * Returns the active Perentie Alert for this component if there is one
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    getActiveAlert(componentId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultAlertDetails>;

    /**
     * Returns All the alerts for a component including any active alert
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    getComponentAlertHistory(componentId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultListAlertBasicDetails>;

    /**
     * Returns set number of History Items from start index to num for component
     * @param {string} componentId 
     * @param {number} [start] 
     * @param {number} [num] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    getComponentEventHistory(componentId: string, start?: number, num?: number, options?: AxiosRequestConfig): AxiosPromise<RESTResultListHistoryItemDetails>;

    /**
     * Get notify chain for component
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    getComponentNotifyChain(componentId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultNotifyChainDetails>;

    /**
     * 
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    getComponentRuleSet(componentId: string, options?: AxiosRequestConfig): AxiosPromise<RESTResultString>;

    /**
     * Returns Components for this user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    getComponents(options?: AxiosRequestConfig): AxiosPromise<RESTResultListComponentDetails>;

    /**
     * 
     * @param {string} componentId 
     * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    updateComponentRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultString>;

    /**
     * Update notify chain of component
     * @param {string} componentId 
     * @param {NotifyChainRequest} [notifyChainRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApiInterface
     */
    updateNotifyChain(componentId: string, notifyChainRequest?: NotifyChainRequest, options?: AxiosRequestConfig): AxiosPromise<RESTResultNotifyChainDetails>;

}

/**
 * WebServiceForPerentieComponentsApi - object-oriented interface
 * @export
 * @class WebServiceForPerentieComponentsApi
 * @extends {BaseAPI}
 */
export class WebServiceForPerentieComponentsApi extends BaseAPI implements WebServiceForPerentieComponentsApiInterface {
    /**
     * Create new Component
     * @param {CreateComponentRequest} [createComponentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public createComponent(createComponentRequest?: CreateComponentRequest, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).createComponent(createComponentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the active Perentie Alert for this component if there is one
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public getActiveAlert(componentId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).getActiveAlert(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns All the alerts for a component including any active alert
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public getComponentAlertHistory(componentId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).getComponentAlertHistory(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns set number of History Items from start index to num for component
     * @param {string} componentId 
     * @param {number} [start] 
     * @param {number} [num] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public getComponentEventHistory(componentId: string, start?: number, num?: number, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).getComponentEventHistory(componentId, start, num, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notify chain for component
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public getComponentNotifyChain(componentId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).getComponentNotifyChain(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} componentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public getComponentRuleSet(componentId: string, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).getComponentRuleSet(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Components for this user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public getComponents(options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).getComponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} componentId 
     * @param {UpdateRuleSetRequest} [updateRuleSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public updateComponentRuleSet(componentId: string, updateRuleSetRequest?: UpdateRuleSetRequest, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).updateComponentRuleSet(componentId, updateRuleSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update notify chain of component
     * @param {string} componentId 
     * @param {NotifyChainRequest} [notifyChainRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceForPerentieComponentsApi
     */
    public updateNotifyChain(componentId: string, notifyChainRequest?: NotifyChainRequest, options?: AxiosRequestConfig) {
        return WebServiceForPerentieComponentsApiFp(this.configuration).updateNotifyChain(componentId, notifyChainRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



