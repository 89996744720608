class FetchFileUploadApi{

  private basePath: string;
  private token: string;

  constructor(basePath: string, token: string) {

    this.basePath = basePath;
    this.token = token;
  }

  setToken(aToken: string): void {
    this.token = aToken;
  }

  uploadFile(aFile: File, toPath: string): Promise<Response>{
    const formData = new FormData();
    formData.append('file', aFile);
    const url = new URL(this.basePath + toPath);
    console.log(' ewerl', url);
    return fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    });

    // try {
    //   const response = await fetch(url, {
    //     method: 'POST',
    //     body: formData,
    //   });
    //
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }
    //
    //   console.log('File successfully uploaded.');
    // } catch (error) {
    //   console.error('Error uploading file:', error);
    // }
  }

}
export default FetchFileUploadApi;
