import { useCallback, useContext, useMemo, useState } from 'react';

import { WebServiceForPerentieAlertsApi } from '../api';
import PerentieServiceContext from '../context/PerentieServiceContext';
import { passthroughPromise, suspendPromise } from '../util/promise';
import useAxiosRequestConfig from './useAxiosRequestConfig';

function useAlertService() {
    const { apiUrl } = useContext(PerentieServiceContext);
    const api = useMemo(() => new WebServiceForPerentieAlertsApi(undefined, apiUrl), [apiUrl]);
    const config = useAxiosRequestConfig();

    const suspendActiveAlerts = useCallback(() => {
        const fp = api.getActiveAlerts(config);
        return suspendPromise(fp);
    }, [config, api]);

    const promiseActiveAlerts = useCallback(() => {
        const fp = api.getActiveAlerts(config);
        return passthroughPromise(fp);
    }, [config, api]);

    const suspendAlertDetails = useCallback(
        (alertId: string) => {
            const fp = api.getAlertDetails(alertId, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    const promiseAlertDetails = useCallback(
        (alertId: string) => {
            const fp = api.getAlertDetails(alertId, config);
            return passthroughPromise(fp);
        },
        [config, api]
    );

    const suspendAlertEvents = useCallback(
        (alertId: string) => {
            const fp = api.getAlertEventSummary(alertId, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    const promiseAlertEvents = useCallback(
        (alertId: string) => {
            const fp = api.getAlertEventSummary(alertId, config);
            return passthroughPromise(fp);
        },
        [config, api]
    );

    const suspendStateHistory = useCallback(
        (alertId: string) => {
            const fp = api.getComponentStateHistoryForAlert(alertId, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    const promiseStateHistory = useCallback(
        (alertId: string) => {
            const fp = api.getComponentStateHistoryForAlert(alertId, config);
            return passthroughPromise(fp);
        },
        [config, api]
    );

    return {
        suspendActiveAlerts,
        suspendAlertDetails,
        suspendAlertEvents,
        suspendStateHistory,
        promiseActiveAlerts,
        promiseAlertDetails,
        promiseAlertEvents,
        promiseStateHistory,
    };
}

export default useAlertService;
