import { SystemicLogo } from '@systemic-design-framework/react';
import styles from './BottomBar.module.scss';

function BottomBar() {
    return (
        <div className={styles['bottom-bar']}>
            <span className={styles['version']}>v{process.env.REACT_APP_VERSION ?? "0.0.0-0"}</span>
            <span className="disclaimer">
                <span className={styles['copyright']}>© 2024 Systemic Pty Ltd</span>&nbsp;
                <span>| If you require support, please email</span>&nbsp;
                <span className={styles['support']}>Perentie Support</span>.
            </span>
            <div className={styles['brand']}>
                <span className="brand-text">Powered By</span>
                <SystemicLogo />
            </div>
        </div>
    );
}

export default BottomBar;
