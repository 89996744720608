import { useContext } from 'react';
import ThemeContext from '../components/Theme/ThemeContext';

function useThemeColors() {
    const { colors } = useContext(ThemeContext);

    return colors;
}

export default useThemeColors;
