/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RESTResultClientDetails,
  UpdateClientRequest,
} from '../models/index';
import {
    RESTResultClientDetailsFromJSON,
    RESTResultClientDetailsToJSON,
    UpdateClientRequestFromJSON,
    UpdateClientRequestToJSON,
} from '../models/index';

export interface UpdateClientComponentsRequest {
    clientId: string;
    updateClientRequest?: UpdateClientRequest;
}

/**
 * PerentieClientWSApi - interface
 * 
 * @export
 * @interface PerentieClientWSApiInterface
 */
export interface PerentieClientWSApiInterface {
    /**
     * Updates the list of components for client
     * @param {string} clientId 
     * @param {UpdateClientRequest} [updateClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieClientWSApiInterface
     */
    updateClientComponentsRaw(requestParameters: UpdateClientComponentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultClientDetails>>;

    /**
     * Updates the list of components for client
     */
    updateClientComponents(clientId: string, updateClientRequest?: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultClientDetails>;

}

/**
 * 
 */
export class PerentieClientWSApi extends runtime.BaseAPI implements PerentieClientWSApiInterface {

    /**
     * Updates the list of components for client
     */
    async updateClientComponentsRaw(requestParameters: UpdateClientComponentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultClientDetails>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updateClientComponents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/{client_id}/updatecomponents`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClientRequestToJSON(requestParameters.updateClientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultClientDetailsFromJSON(jsonValue));
    }

    /**
     * Updates the list of components for client
     */
    async updateClientComponents(clientId: string, updateClientRequest?: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultClientDetails> {
        const response = await this.updateClientComponentsRaw({ clientId: clientId, updateClientRequest: updateClientRequest }, initOverrides);
        return await response.value();
    }

}
