/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HistoryItemDetails } from './HistoryItemDetails';
import {
    HistoryItemDetailsFromJSON,
    HistoryItemDetailsFromJSONTyped,
    HistoryItemDetailsToJSON,
} from './HistoryItemDetails';

/**
 * 
 * @export
 * @interface ActiveEventDetails
 */
export interface ActiveEventDetails {
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    alertId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEventDetails
     */
    componentEventId?: string;
    /**
     * 
     * @type {number}
     * @memberof ActiveEventDetails
     */
    messageCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof ActiveEventDetails
     */
    firstMessageTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActiveEventDetails
     */
    resolvedTime?: Date;
    /**
     * 
     * @type {HistoryItemDetails}
     * @memberof ActiveEventDetails
     */
    lastMessage?: HistoryItemDetails;
}

/**
 * Check if a given object implements the ActiveEventDetails interface.
 */
export function instanceOfActiveEventDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActiveEventDetailsFromJSON(json: any): ActiveEventDetails {
    return ActiveEventDetailsFromJSONTyped(json, false);
}

export function ActiveEventDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveEventDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'alertId': !exists(json, 'alert_id') ? undefined : json['alert_id'],
        'componentEventId': !exists(json, 'componentEventId') ? undefined : json['componentEventId'],
        'messageCount': !exists(json, 'messageCount') ? undefined : json['messageCount'],
        'firstMessageTime': !exists(json, 'firstMessageTime') ? undefined : (new Date(json['firstMessageTime'])),
        'resolvedTime': !exists(json, 'resolvedTime') ? undefined : (new Date(json['resolvedTime'])),
        'lastMessage': !exists(json, 'lastMessage') ? undefined : HistoryItemDetailsFromJSON(json['lastMessage']),
    };
}

export function ActiveEventDetailsToJSON(value?: ActiveEventDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'component_id': value.componentId,
        'alert_id': value.alertId,
        'componentEventId': value.componentEventId,
        'messageCount': value.messageCount,
        'firstMessageTime': value.firstMessageTime === undefined ? undefined : (value.firstMessageTime.toISOString()),
        'resolvedTime': value.resolvedTime === undefined ? undefined : (value.resolvedTime.toISOString()),
        'lastMessage': HistoryItemDetailsToJSON(value.lastMessage),
    };
}

