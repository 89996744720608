import { useCallback, useContext, useMemo } from 'react';

import { WebServiceForPerentieComponentsApi } from '../api';
import PerentieServiceContext from '../context/PerentieServiceContext';
import {passthroughPromise, suspendPromise} from '../util/promise';
import useAxiosRequestConfig from './useAxiosRequestConfig';

function useComponentService() {
    const { apiUrl } = useContext(PerentieServiceContext);
    const api = useMemo(() => new WebServiceForPerentieComponentsApi(undefined, apiUrl), [apiUrl]);
    const config = useAxiosRequestConfig();

    const suspendNotifyChain = useCallback(
        (componentId: string) => {
            const fp = api.getComponentNotifyChain(componentId, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    const getComponents = useCallback(
        () => {
            const fp = api.getComponents(config) ;
            return passthroughPromise(fp);
        }, [api,config]
    );

    return { suspendNotifyChain, getComponents };
}

export default useComponentService;
