import styles from './Breadcrumb.module.scss';

interface BreadcrumbProps {
    crumbs: string[];
    actionReducer: (crumb: string) => void;
    RootComponent?: React.ComponentType<any>;
}

function Breadcrumb({ crumbs, actionReducer, RootComponent }: BreadcrumbProps) {
    return (
        <div className={styles['bread-crumb']}>
            {crumbs.map((crumb, index) => {
                return (
                    <div className={styles['crumb']} key={index}>
                        <div onClick={() => actionReducer(crumb)} className={styles['text']}>
                            {RootComponent && index === 0 ? <RootComponent /> : crumb}
                        </div>

                        {index !== crumbs.length - 1 && <div className={styles['divider']}>/</div>}
                    </div>
                );
            })}
        </div>
    );
}

export default Breadcrumb;
