import { CSSProperties } from 'react';
import styles from './Cell.module.scss';

export interface CellProps {
    className?: string;
    children: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    position?: 'top' | 'middle' | 'bottom';
    flex?: number;
    width?: number;
}

function Cell({ children, className, width, flex = 1, align = 'left', position = 'top' }: CellProps) {
    if (width) {
        flex = 0;
    }

    const alignmentToFlex = new Map<'left' | 'center' | 'right', string>([
        ['left', 'flex-start'],
        ['center', 'center'],
        ['right', 'flex-end'],
    ]);

    const positionToFlex = new Map<'top' | 'middle' | 'bottom', string>([
        ['top', 'flex-start'],
        ['middle', 'center'],
        ['bottom', 'flex-end'],
    ]);

    const style: CSSProperties = {
        minWidth: width ? width + "px" : undefined,
        flex,
        alignItems: positionToFlex.get(position),
        justifyContent: alignmentToFlex.get(align),
    };

    return (
        <div className={styles['cell']} style={style}>
            <div className={className}>{children}</div>
        </div>
    );
}

export default Cell;
