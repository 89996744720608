import { useCallback, useContext, useMemo } from 'react';

import {AgentRequest, InputPart, WebServiceForAdminFunctionsApi} from '../api';
import PerentieServiceContext from '../context/PerentieServiceContext';
import {passthroughPromise, suspendPromise} from '../util/promise';
import useAxiosRequestConfig from './useAxiosRequestConfig';

function useAdminService() {
    const { apiUrl } = useContext(PerentieServiceContext);
    const api = useMemo(() => new WebServiceForAdminFunctionsApi(undefined, apiUrl), [apiUrl]);
    const config = useAxiosRequestConfig();

    const suspendUsers = useCallback(() => {
        const fp = api.getUsers(config);
        return suspendPromise(fp);
    }, [api, config]);

    const suspendAgents = useCallback(() => {
        const fp = api.getAgents(config);
        return suspendPromise(fp);
    }, [api, config]);

    const promiseAgents = useCallback(
        () => {
            const fp = api.getAgents(config);
            return passthroughPromise(fp);
        }, [api, config]
    );

    const suspendRegisterAgent = useCallback(
        (agent: AgentRequest) => {
            const fp = api.registerAgent(agent, config);
            return suspendPromise(fp);
        },
        [api, config]
    );

    const suspendUpdateAgent = useCallback(
        (agent: AgentRequest) => {
            const fp = api.updateAgent(agent, config);
            return suspendPromise(fp);
        },
        [api, config]
    );

    const suspendAgentDetails = useCallback(
        (agentId: string) => {
            const fp = api.getAgentDetails(agentId, config);
            return suspendPromise(fp);
        },
        [api, config]
    );

    const promiseAgentDetails = useCallback(
        (agentId: string) => {
            const fp = api.getAgentDetails(agentId,config);
            return passthroughPromise(fp);
        }, [api,config]
    );

    const uploadConfig = useCallback(
        (data: InputPart[]) => {
            // const fp = api.uploadComponentConfig(data);


        },[api,config]
    );

    return { suspendUsers, suspendAgents, suspendRegisterAgent, suspendUpdateAgent, suspendAgentDetails, promiseAgents, promiseAgentDetails, uploadConfig };
}

export default useAdminService;
