import {Slice, PayloadAction} from '@reduxjs/toolkit'
export function createGetter<T>(slice: Slice<T>) {
  return function<K extends keyof T>(name: K): (state: any) => (T)[K] {
    return (state: any) => (state[slice.name] as T)[name];
  }
}

/*
    helper function to make creating redux getters easier

    Example usage:

        interface ExampleState {
            farmAnimals: FarmAnimal[];
            plants: Plant[];
        };

        const exampleSlice = createSlice({
            name: 'example',
            ...
        });

        ...

        const getter = createGetter(exampleSlice);
        const getters = {
            getFarmAnimals:  getter("farmAnimals"),
            getPlants:       getter("plants"),
        };
*/


export function createSetter<T>() {
  return function<K extends keyof T>(key: K) {
    return (state: any, action: PayloadAction<(T)[K]>) => {
      state[key] = action.payload;
    };
  }
}
