import {ResponseErrorHandler} from "./ResponseErrorHandler";
import {RESTResult} from "../fetchapi";

abstract class AbstractBaseDatasource {
  protected apiBasePath: string;
  protected errorHandler: ResponseErrorHandler;

  constructor(path: string, errorHandler: ResponseErrorHandler) {
    this.apiBasePath = path;
    this.errorHandler = errorHandler;
  }

  wrap<T>(apiResp: Promise<RESTResult>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      apiResp
        .then(res => {
          if (!!res.error) {
            this.errorHandler.handleRestError(res.error);
            reject(res.error);
          }
          resolve(res.data as T);
        })
        .catch((reason: {response: Response}) => {
          this.errorHandler.handleErrorResponse(reason.response);
          reject(reason.response.statusText);
        });
    });
  }

}

export default AbstractBaseDatasource;
