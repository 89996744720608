/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientDetails } from './ClientDetails';
import {
    ClientDetailsFromJSON,
    ClientDetailsFromJSONTyped,
    ClientDetailsToJSON,
} from './ClientDetails';

/**
 * 
 * @export
 * @interface RESTResultListClientDetails
 */
export interface RESTResultListClientDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListClientDetails
     */
    error?: string;
    /**
     * 
     * @type {Array<ClientDetails>}
     * @memberof RESTResultListClientDetails
     */
    data?: Array<ClientDetails>;
}

/**
 * Check if a given object implements the RESTResultListClientDetails interface.
 */
export function instanceOfRESTResultListClientDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultListClientDetailsFromJSON(json: any): RESTResultListClientDetails {
    return RESTResultListClientDetailsFromJSONTyped(json, false);
}

export function RESTResultListClientDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultListClientDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ClientDetailsFromJSON)),
    };
}

export function RESTResultListClientDetailsToJSON(value?: RESTResultListClientDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ClientDetailsToJSON)),
    };
}

