/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDetails } from './UserDetails';
import {
    UserDetailsFromJSON,
    UserDetailsFromJSONTyped,
    UserDetailsToJSON,
} from './UserDetails';

/**
 * 
 * @export
 * @interface RESTResultListUserDetails
 */
export interface RESTResultListUserDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListUserDetails
     */
    error?: string;
    /**
     * 
     * @type {Array<UserDetails>}
     * @memberof RESTResultListUserDetails
     */
    data?: Array<UserDetails>;
}

/**
 * Check if a given object implements the RESTResultListUserDetails interface.
 */
export function instanceOfRESTResultListUserDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultListUserDetailsFromJSON(json: any): RESTResultListUserDetails {
    return RESTResultListUserDetailsFromJSONTyped(json, false);
}

export function RESTResultListUserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultListUserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(UserDetailsFromJSON)),
    };
}

export function RESTResultListUserDetailsToJSON(value?: RESTResultListUserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(UserDetailsToJSON)),
    };
}

