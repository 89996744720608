import { AgentDetailsTypeEnum } from '@perentie/common';
import { Button } from '@systemic-design-framework/react';
import { useReducer } from 'react';
import { IoKeyOutline } from 'react-icons/io5';
import { Text, TextArea } from '../../components/Input';
import Dropdown from '../../components/Input/Dropdown/Dropdown';
import Form from '../../components/Input/Form/Form';

export enum AgentReducerAction {
    ID,
    NAME,
    TYPE,
    DESCRIPTION,
    PUBLIC_KEY,
}

interface AgentFormData {
    agentId?: string;
    agentName?: string;
    agentType?: AgentDetailsTypeEnum;
    description?: string;
    publicKey?: string;
}

interface AgentFormProps extends AgentFormData {
    onSubmit: (form: AgentFormData) => void;
}

function AgentForm({
    agentId = '',
    agentName = '',
    agentType,
    description = '',
    publicKey = '',
    onSubmit,
}: AgentFormProps) {
    const agentsList = Object.values(AgentDetailsTypeEnum) as AgentDetailsTypeEnum[];

    const [form, setForm] = useReducer(
        (state: AgentFormData, action: any) => {
            switch (action.type) {
                case AgentReducerAction.ID.toString():
                    return { ...state, agentId: action.payload };
                case AgentReducerAction.NAME.toString():
                    return { ...state, agentName: action.payload };
                case AgentReducerAction.TYPE.toString():
                    return { ...state, agentType: agentsList[action.payload] };
                case AgentReducerAction.DESCRIPTION.toString():
                    return { ...state, description: action.payload };
                case AgentReducerAction.PUBLIC_KEY.toString():
                    return { ...state, publicKey: action.payload };
                default:
                    return state;
            }
        },
        { agentId, agentName, agentType, description, publicKey },
        initialData => {
            return initialData;
        }
    );

    return (
        <Form
            onChangeReducer={(key, value) => {
                const action = { type: key, payload: value };
                setForm(action);
            }}
            style={{ display: 'flex',flex: '1', gap: '16px',  flexDirection: 'column', width:'800px'}}
        >
            <Text
                id={AgentReducerAction.ID.toString()}
                label="Agent ID"
                value={form.agentId}
                helper="ID is assigned once the Agent is registered."
                size="s"
                disabled
            />

            <Text id={AgentReducerAction.NAME.toString()} label="Name" value={form.agentName} size="s" />

            <Dropdown
                label="Type"
                options={agentsList}
                id={AgentReducerAction.TYPE.toString()}
                selected={form.agentType ? agentsList.indexOf(form.agentType) : undefined}
            />

            <TextArea id={AgentReducerAction.DESCRIPTION.toString()} label="Description" value={form.description} />

            <TextArea id={AgentReducerAction.PUBLIC_KEY.toString()} label="Public Key" value={form.publicKey}>
                <Button Icon={<IoKeyOutline />} disabled />
            </TextArea>

            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button title="Cancel" variant="ghost" size="sm" />
                <Button
                    title="Save Agent Details"
                    size="sm"
                    onClick={() => {
                        onSubmit(form);
                    }}
                />
            </div>
        </Form>
    );
}

export default AgentForm;
