import { TokenRequest, WebServiceForActionsRelatedToSessionUserApi } from '../api';
import { BASE_PATH } from '../api/base';
import Service, { ServiceStatus } from './Service';

type RegisterDeviceTokenResponse = Promise<[boolean, ServiceStatus]>;

class DeviceTokenService extends Service<WebServiceForActionsRelatedToSessionUserApi> {
    constructor(apiUrl: string = BASE_PATH, authToken = '') {
        super(new WebServiceForActionsRelatedToSessionUserApi(undefined, apiUrl), authToken);
    }

    public async registerDeviceToken(tokenRequest: TokenRequest): RegisterDeviceTokenResponse {
        return this.unpackRequest(
            this.API.updateNotifyDetails(tokenRequest, this.config),
            false
        ) as RegisterDeviceTokenResponse;
    }
}
export default DeviceTokenService;
