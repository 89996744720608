import './index.scss';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@systemic-design-framework/react';

import App from './App';
import { store } from './store';
import SessionManager from "./components/SessionManger/SessionManager";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
      <BrowserRouter>
          <Provider store={store}>
              <ThemeProvider
                  animated
                  otherColors={{
                      'main-menu-dark-background': '#242728',
                      'main-menu-dark-item-background': '#242728',
                      'main-menu-dark-item-hover-background': '#383d3f',
                      'main-menu-dark-item-focused-border': 'rgba(87, 101, 105, 0.2)',
                      'main-menu-dark-item-active-background': '#354145',
                      'main-menu-dark-header-background': '#191b1c',
                  }}
              >
                <App />
              </ThemeProvider>
          </Provider>
      </BrowserRouter>
);
