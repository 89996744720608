/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotifyChainLinkRequest
 */
export interface NotifyChainLinkRequest {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof NotifyChainLinkRequest
     */
    onCallMap?: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof NotifyChainLinkRequest
     */
    level?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotifyChainLinkRequest
     */
    last?: boolean;
}

/**
 * Check if a given object implements the NotifyChainLinkRequest interface.
 */
export function instanceOfNotifyChainLinkRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotifyChainLinkRequestFromJSON(json: any): NotifyChainLinkRequest {
    return NotifyChainLinkRequestFromJSONTyped(json, false);
}

export function NotifyChainLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyChainLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onCallMap': !exists(json, 'onCallMap') ? undefined : json['onCallMap'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'last': !exists(json, 'last') ? undefined : json['last'],
    };
}

export function NotifyChainLinkRequestToJSON(value?: NotifyChainLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onCallMap': value.onCallMap,
        'level': value.level,
        'last': value.last,
    };
}

