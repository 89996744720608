import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const devReducer = createSlice({
    name: 'dev',
    initialState: {
        apiUrl: 'http://192.168.1.238/perentie',
        version: '0.0.0',
    },
    reducers: {
        setAPIDeployment: (state) => {
            console.debug('Setting API to deployment');
            // state.apiUrl = 'https://perentie.sys-dev.net/perentie';
            state.apiUrl = 'http://192.168.1.238/perentie';
        },
        setAPI: (state, action) => {
            console.debug('Setting API to', action.payload);
            state.apiUrl = action.payload;
        },
    },
});

export const selectAPIUrl = createSelector(
    (state: RootState) => state.dev,
    (dev) => dev.apiUrl
);

export const { setAPIDeployment, setAPI } = devReducer.actions;

export default devReducer.reducer;
