/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgentConnectionDetails
 */
export interface AgentConnectionDetails {
    /**
     * 
     * @type {string}
     * @memberof AgentConnectionDetails
     */
    objectId?: string;
    /**
     * 
     * @type {Date}
     * @memberof AgentConnectionDetails
     */
    lastConnected?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AgentConnectionDetails
     */
    lastDisconnected?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AgentConnectionDetails
     */
    lastCheck?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConnectionDetails
     */
    lastIsConnected?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AgentConnectionDetails
     */
    config?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof AgentConnectionDetails
     */
    metricDefinition?: { [key: string]: { [key: string]: string; }; };
}


/**
 * @export
 */
export const AgentConnectionDetailsMetricDefinitionEnum = {
    String: 'STRING',
    Number: 'NUMBER',
    Boolean: 'BOOLEAN',
    Severity: 'SEVERITY',
    DateTime: 'DATE_TIME',
    Day: 'DAY',
    Month: 'MONTH',
    StringArray: 'STRING_ARRAY',
    NumberArray: 'NUMBER_ARRAY',
    BooleanArray: 'BOOLEAN_ARRAY',
    SeverityArray: 'SEVERITY_ARRAY',
    DateTimeArray: 'DATE_TIME_ARRAY',
    Regex: 'REGEX',
    Object: 'OBJECT'
} as const;
export type AgentConnectionDetailsMetricDefinitionEnum = typeof AgentConnectionDetailsMetricDefinitionEnum[keyof typeof AgentConnectionDetailsMetricDefinitionEnum];


/**
 * Check if a given object implements the AgentConnectionDetails interface.
 */
export function instanceOfAgentConnectionDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgentConnectionDetailsFromJSON(json: any): AgentConnectionDetails {
    return AgentConnectionDetailsFromJSONTyped(json, false);
}

export function AgentConnectionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentConnectionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'lastConnected': !exists(json, 'lastConnected') ? undefined : (new Date(json['lastConnected'])),
        'lastDisconnected': !exists(json, 'lastDisconnected') ? undefined : (new Date(json['lastDisconnected'])),
        'lastCheck': !exists(json, 'lastCheck') ? undefined : (new Date(json['lastCheck'])),
        'lastIsConnected': !exists(json, 'lastIsConnected') ? undefined : json['lastIsConnected'],
        'config': !exists(json, 'config') ? undefined : json['config'],
        'metricDefinition': !exists(json, 'metricDefinition') ? undefined : json['metricDefinition'],
    };
}

export function AgentConnectionDetailsToJSON(value?: AgentConnectionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'lastConnected': value.lastConnected === undefined ? undefined : (value.lastConnected.toISOString()),
        'lastDisconnected': value.lastDisconnected === undefined ? undefined : (value.lastDisconnected.toISOString()),
        'lastCheck': value.lastCheck === undefined ? undefined : (value.lastCheck.toISOString()),
        'lastIsConnected': value.lastIsConnected,
        'config': value.config,
        'metricDefinition': value.metricDefinition,
    };
}

