interface SidebarToggleProps {
    toggleCollapsed?: () => void;
    collapsed?: boolean;
}

function SidebarToggle({ toggleCollapsed, collapsed }: SidebarToggleProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" cursor="pointer" onClick={toggleCollapsed}>
            {collapsed ? (
                <>
                    <path fill="#757575" d="M2 1h5v22H2z" />
                    <rect x="1" y="1" width="22" height="22" rx="3" stroke="#EDEDED" stroke-width="2" />
                    <path
                        d="M12.157 12.211a.5.5 0 0 0 0-.822L7.535 8.187a.5.5 0 0 0-.785.41v6.405a.5.5 0 0 0 .785.411l4.622-3.202z"
                        fill="#EDEDED"
                    />
                    <path stroke="#EDEDED" stroke-width="2" stroke-linecap="round" d="M7 2v20" />
                </>
            ) : (
                <>
                    <path fill="#757575" d="M2 1h10v22H2z" />
                    <rect x="1" y="1" width="22" height="22" rx="3" stroke="#EDEDED" strokeWidth="2" />
                    <path
                        d="M6.343 11.389a.5.5 0 0 0 0 .822l4.622 3.202a.5.5 0 0 0 .785-.41V8.597a.5.5 0 0 0-.785-.411l-4.622 3.202z"
                        fill="#EDEDED"
                    />
                    <path stroke="#EDEDED" strokeWidth="2" strokeLinecap="round" d="M12 2v20" />
                </>
            )}
        </svg>
    );
}

export default SidebarToggle;
