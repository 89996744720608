import {UserDetails, UserRoleDetailsNameEnum} from "../fetchapi";

class AccessUtil {

  private user: UserDetails | undefined | null;

  constructor(user: UserDetails | undefined | null) {
    this.user = user;
  }

  /**
   * Returns true if user has role with Global Scope. Only ShedAdmin role should have that!
   */
  public isSystemAdmin(): boolean {
    if (!!this.user) {
      return !!this.user.roles?.find(role => role.scope === 'GLOBAL');
    }
    return false;
  }

  public hasRoleType(type: UserRoleDetailsNameEnum): boolean {
    if (!!this.user) {
      return !!this.user.roles?.find(role => role.name === type);
    }
    return false;
  }
}

export default AccessUtil;
