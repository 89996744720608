import {AlertBasicDetails, AlertDetails, AlertDetailsStatusEnum} from "@perentie/common";
import styles from "./BasicAlertCard.module.scss";
import {Button} from "@systemic-design-framework/react";
import StateText from "../StateText/StateText";
import {IoReaderOutline} from "react-icons/io5";

export interface BasicAlertCardProps {
  details: AlertBasicDetails;
  onClick?: (item: AlertDetails) => void;
  actionClick?: (item: AlertDetails, action: 'close') => void;
  showState?: boolean;
}
function BasicAlertCard(props:BasicAlertCardProps) {

  const {showState = true, details} = props;
  const cardClick = () => {
    if (!!props.onClick){
      props.onClick(props.details);
    }
  }
  const closeClick = () => {
    if (!!props.actionClick){
      props.actionClick(props.details, 'close');
    }
  }

  // This is here because needed to wrap the APS button in a div to stop card click being called.
  // The APS button isn't providing the underlying event so cannot stop propagation. Can remove that once
  // the APS button has been updated to provide the click event.
  const divStopper = (evt: any) =>{
    evt.stopPropagation();
  }

  // @ts-ignore
  return (
    <div className={styles['alertCard']} onClick={cardClick}>
      <div className={styles['title']}>
        <h3 key={'b'}>{details.componentName} {details.status == AlertDetailsStatusEnum.ActiveOpen ? ['- ', <StateText>{details.componentState}</StateText>] : ''}</h3>
        {!!details && details.numComments && details.numComments > 0 ? <IoReaderOutline/> : []}
      </div>
      <h4 key={'a'}>{details.status}</h4>
      <p key={'1'}>Started: {details.created?.toLocaleDateString() + ' ' + details.created?.toLocaleTimeString()}</p>
      {details.ended != null ?
        <p key={'3'}>Ended: {details.ended?.toLocaleDateString() + ' ' + details.ended?.toLocaleTimeString()}</p>
        : []}
      <p key={'2'}>{details.handlingUser != null ? 'Handled By: ' + details.handlingUser.username : 'Not Handled'}</p>
      {props.actionClick != null ? <div style={{display:"inline-block"}} onClick={divStopper}><Button size={'s'} title={'Close Alert'} onClick={closeClick}/> </div>: [] }
    </div>
  )
}

export default BasicAlertCard;
