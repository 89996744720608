import { ComponentType, CSSProperties, useContext } from 'react';
import { animated, AnimatedProps } from 'react-spring';
import SidebarContext from '../SidebarContext';
import styles from './Header.module.scss';

interface HeaderProps {
    children: React.ReactNode;
    Icon: ComponentType<any>;
    height?: number;
}

function Header({ children, height, Icon }: HeaderProps) {
    const { animationProgress } = useContext(SidebarContext);

    const style: CSSProperties = {
        height: height || 104,
    };

    const animatedContentStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [35, 100],
            })
            .to((number) => `${number}%`),
    } as AnimatedProps<CSSProperties>;

    const animatedIconStyle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [10, 50],
            })
            .to((number) => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [10, 50],
            })
            .to((number) => `${number}%`),
    } as AnimatedProps<CSSProperties>;

    return (
        <div className={styles['header']} style={style}>
            <div className={styles['frame']}>
                <animated.div
                    className={styles['icon']}
                    style={animatedIconStyle}
                >
                    <Icon />
                </animated.div>
                <animated.div
                    className={styles['content']}
                    style={animatedContentStyle}
                >
                    {children}
                </animated.div>
            </div>
        </div>
    );
}

export default Header;
