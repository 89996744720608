import styles from './ManageUsersPage.module.scss'
import {useEffect, useState} from "react";
import {AccessUtil, ComponentBasicDetails, UserDetails, UserRoleDetailsNameEnum} from "@perentie/common";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/authenticationSlice";
import {perDS} from "../../datasource/WebDS";
import {Button, Cell, Row, Table} from "@systemic-design-framework/react";
import Select from 'react-select'

function ManageUsersPage(){

  const [userList, setUserList] = useState<UserDetails[]>([])
  const [editUser, setEditUser] = useState<UserDetails | null>(null)
  const [existingRoles, setExistingRoles] = useState<any>([])
  const [nextRoles, setNextRoles] = useState<any | null>(null)
  const sessionUser = useSelector(selectUser);
  const accessUtil = new AccessUtil(sessionUser);

  //SHED_ADMIN("Super Shed Admin"),
  //     CLIENT_ADMIN("Client Admin"),
  //     READ_ONLY("Read Only"),
  //     RESPONDER("Responder - handle alerts");

  const roleOptions = [
    {value: UserRoleDetailsNameEnum.ClientAdmin, label: 'Client Admin'},
    {value: UserRoleDetailsNameEnum.ReadOnly, label: 'Read Only'},
    {value: UserRoleDetailsNameEnum.Responder, label: 'Responder - handle alerts'},
    {value: UserRoleDetailsNameEnum.ShedAdmin, label: 'Shed Admin'},
  ]

  useEffect(() => {
    perDS.getPerentieUsers().then( res => {
      setUserList(res);
    }).catch(reason => {
      console.log(' get users fail', reason);
    })
  }, []);

  console.log('sessiong user', sessionUser);

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Username</Cell>
        <Cell>Name</Cell>
        <Cell>Roles</Cell>
        <Cell>{''}</Cell>
      </Row>
    );
  };

  const renderItem = (item: UserDetails, index: number) => {
    return (
      <Row key={index} striped={index % 2 == 1}>
        <Cell>
          {item.username}
        </Cell>
        <Cell>{item.firstName} {item.lastName} {item.objectId === sessionUser.objectId ? '*' : ''}</Cell>
        <Cell>{item.roles?.map(r => r.displayName).join(',')}</Cell>
        <Cell>
          <div style={{display: 'flex', flexDirection: 'row', columnGap: 16}}>
          <Button size={'s'} title={'Edit Roles'} onClick={() => editClick(item)}/>
          {accessUtil.isSystemAdmin() ? <div>
            <Button size={'s'} title={'Notify Test'} onClick={() => perDS.testNotify(item.objectId!).then()} />
          </div> : [] }
          </div>
        </Cell>
      </Row>
    );
  };

  const editClick = (user: UserDetails) => {
    const existing = user.roles?.map( r => {
      return {
        value: r.name,
        label: r.displayName
      }
    })
    setExistingRoles(existing);
    setEditUser(user);
  }

  const roleSelectChange = (thing: any) => {
    console.log('thingj', thing);
    // @ts-ignore
    const types = thing.map( opt => {
      console.log(' opt', opt.value);
      return opt.value;
    });
    console.log(' nextTypes', types);
    setNextRoles(types);
  }

  const updateRoles = () => {
    perDS.updateUserRoles(editUser?.objectId!, nextRoles).then(res => {
      const nextList = userList.map(u => {
        if (u.objectId! == res.objectId!){
          return res;
        }
        return u;
      })
      setEditUser(null);
      setExistingRoles(null)
      setUserList(nextList);
    }).catch( reason => {
      console.log(' updateUserRoles catch', reason);
      setEditUser(null);
      setExistingRoles(null)
    })
  }

  return (
    <div className={styles['content']}>
      {editUser != null ?
        <div style={{border: '1px solid black', borderRadius: 6, padding: '8px 16px', display: 'flex', flexDirection:'column', rowGap:16}}>
          <p style={{marginTop: 'unset'}}><strong>Edit Roles</strong></p>

          <div style={{display:'inline-flex', alignItems:'center', columnGap: 16}}>
            <span>{editUser.username}</span> <Select options={roleOptions} isMulti={true} defaultValue={existingRoles} onChange={roleSelectChange}/>
          </div>
          <div style={{display:'inline-flex', alignItems:'center', columnGap: 16}}>
            <Button size={'s'} title={'Cancel'} onClick={() => {setEditUser(null); setExistingRoles([]); setNextRoles([])}}/>
            <Button size={'s'} title={'Update Roles'} onClick={updateRoles} disabled={nextRoles == null}/>
          </div>
        </div>
        : []}
      <Table data={userList} HeaderComponent={<TableHeader/>} renderItem={renderItem} border/>
    </div>
  )
}

export default ManageUsersPage;
