import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AgentRequest } from '@perentie/common';
import { ContainerCard, Modal } from '@systemic-design-framework/react';

import AgentForm from '../../forms/AgentForm/AgentForm';
import styles from './RegisterAgentPage.module.scss';
import {perDS} from "../../datasource/WebDS";

function RegisterAgentPage() {
    const [agentId, setAgentId] = useState<string>('');
    const [agentName, setAgentName] = useState<string>('');

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const closeModal = () => {
        setModalVisible(false);
        navigate(`/agents/details/?id=${agentId}`);
    };

    const registerAgent = (req: AgentRequest) => {
      perDS.registerAgent( req).then( res => {

        setAgentId(res.objectId!);
        setAgentName(res.name!);
        setModalVisible(true);
      }).catch( reason => {
        window.alert(" Create agent failed "+ reason);
      });
    }

    return (
        <div className={styles['register-agents-page']}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <ContainerCard HeaderComponent={<div>Agent Details</div>}>
                    <AgentForm
                        onSubmit={data => {
                            console.log('submitting', data);
                            const agentDetails: AgentRequest = {
                                description: data.description,
                                name: data.agentName,
                                type: data.agentType,
                                publicKey: data.publicKey,
                            };
                            //@ts-ignore
                            registerAgent(agentDetails);
                        }}
                    />
                </ContainerCard>
            </div>

            <Modal title="Success!" onClose={closeModal} visible={modalVisible} actions={['Confirm', 'Cancel']}>
                <span>You've successfully registered the agent named</span> <b>{agentName}</b>
                <span>. The ID of the new agent is:</span>
                <p style={{ textAlign: 'center' }}>
                    <b>{agentId}</b>
                </p>
            </Modal>
        </div>
    );
}

export default RegisterAgentPage;
