/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PerentieRuleDefinition } from './PerentieRuleDefinition';
import {
    PerentieRuleDefinitionFromJSON,
    PerentieRuleDefinitionFromJSONTyped,
    PerentieRuleDefinitionToJSON,
} from './PerentieRuleDefinition';
import type { ReceivedEvent } from './ReceivedEvent';
import {
    ReceivedEventFromJSON,
    ReceivedEventFromJSONTyped,
    ReceivedEventToJSON,
} from './ReceivedEvent';

/**
 * 
 * @export
 * @interface TestRuleDefnRequest
 */
export interface TestRuleDefnRequest {
    /**
     * 
     * @type {PerentieRuleDefinition}
     * @memberof TestRuleDefnRequest
     */
    definition?: PerentieRuleDefinition;
    /**
     * 
     * @type {ReceivedEvent}
     * @memberof TestRuleDefnRequest
     */
    event?: ReceivedEvent;
}

/**
 * Check if a given object implements the TestRuleDefnRequest interface.
 */
export function instanceOfTestRuleDefnRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TestRuleDefnRequestFromJSON(json: any): TestRuleDefnRequest {
    return TestRuleDefnRequestFromJSONTyped(json, false);
}

export function TestRuleDefnRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestRuleDefnRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': !exists(json, 'definition') ? undefined : PerentieRuleDefinitionFromJSON(json['definition']),
        'event': !exists(json, 'event') ? undefined : ReceivedEventFromJSON(json['event']),
    };
}

export function TestRuleDefnRequestToJSON(value?: TestRuleDefnRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': PerentieRuleDefinitionToJSON(value.definition),
        'event': ReceivedEventToJSON(value.event),
    };
}

