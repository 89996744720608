export function dateToAgo(date: Date) {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const minutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));
    const hours = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60));
    const days = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    const months = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 30 * 12));

    if (minutes < 1) {
        return `${seconds} seconds ago`;
    }
    if (minutes < 60) {
        return `${minutes} minutes ago`;
    }
    if (hours < 24) {
        return `${hours} hours ago`;
    }
    if (days < 30) {
        return `${days} days ago`;
    }
    if (months < 12) {
        return `${months} months ago`;
    }
    return `${years} years ago`;
}

export function dateToLast(date: Date) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
    const lastYear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

    if (date.getTime() > today.getTime()) {
        return 'Today';
    }
    if (date.getTime() > yesterday.getTime()) {
        return 'Yesterday';
    }
    if (date.getTime() > lastWeek.getTime()) {
        return 'Last Week';
    }
    if (date.getTime() > lastMonth.getTime()) {
        return 'Last Month';
    }
    if (date.getTime() > lastYear.getTime()) {
        return 'Last Year';
    }
    return 'Older';
}

export class Duration {

    private seconds;
    constructor(seconds: number) {
        this.seconds = Math.floor(seconds);
    }

    toSeconds(): number {
        return this.seconds;
    }

    toMinutes(): number {
        return Math.floor(this.seconds / 60);
    }

    toHours(): number{
        return Math.floor(this.seconds / 3600);
    }
    toDays(): number{
        return Math.floor(this.seconds / 86400);
    }

    roughDuration(): string {
        if (this.seconds >= 86400){
            const days = this.toDays();
            return '~' + days + ' day' + (days == 1 ? '' : 's');
        }
        if (this.seconds >= 3600 && this.seconds < 86400){
            const num = this.toHours();
            return '~' + num + ' hour' + (num == 1 ? '' : 's');
        }
        if (this.seconds >= 60 && this.seconds < 3600){
            const num = this.toMinutes();
            return '~' + num + ' min' + (num == 1 ? '' : 's');
        }

        return this.seconds + 's';
    }

}

export function durationBetween(start?: Date, end?: Date): Duration | undefined{
    if (start == null || end == null){
        return undefined;
    }
    let diff = end.getTime() - start.getTime();
    return new Duration(diff/1000);
}
