// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._SMa75bq5koldgPCwvsO {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  padding: 0;\n  border-radius: 8px;\n  border: solid 1px var(--neutral-40);\n}", "",{"version":3,"sources":["webpack://./src/components/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,mCAAA;AACJ","sourcesContent":[".accordion {\r\n    display: flex;\r\n    flex-direction: column;\r\n    overflow: hidden;\r\n    padding: 0;\r\n    border-radius: 8px;\r\n    border: solid 1px var(--neutral-40);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "_SMa75bq5koldgPCwvsO"
};
export default ___CSS_LOADER_EXPORT___;
