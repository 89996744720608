import {useEffect, useState} from "react";
import {AlertBasicDetails, AlertDetails} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import styles from "./ActiveAlertsPage.module.scss"
import {Button} from "@systemic-design-framework/react";
import BasicAlertCard from "../../components/BasicAlertCard/BasicAlertCard";
import {useNavigate} from "react-router-dom";
import vibe from '../../assets/noalertsvibe.webp';


function ActiveAlertsPage(){

  const [alerts, setAlerts] = useState<AlertBasicDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();



  const loadAlerts = () => {
    perDS.getOpenAlerts().then(res => {
      setAlerts(res);
      setTimeout(() => {
        setLoading(false);
      },100);
    }).catch( reason => {
      console.log(' problem', reason);
      setLoading(false);
    });
  }

  useEffect(() => {
    loadAlerts();
  }, []);

  const alertClick = (alert: AlertDetails) => {
    navigate('/alert/'+alert.objectId);
  }


  const closeAlert = (item: AlertBasicDetails) => {
    if (confirm('Are you sure you want to close this alert?\n\nThis cannot be undone.')) {
      perDS.closeAlert(item.objectId!).then(res => {
        loadAlerts();
      })
    }
  }

  const cards = alerts.map( deets => {
    return <BasicAlertCard details={deets} onClick={alertClick} actionClick={(item, action) => closeAlert(item)}/>
  });

  return (
    <div className={styles['content']}>
      <div style={{display: loading ? '': 'none'}}>Loading...</div>
      {cards.length == 0 && !loading ?
      <div style={{display:'flex', flex:1,justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
        <div style={{backgroundImage: `url(${vibe})`, width:800, height:800, backgroundSize:'contain', backgroundRepeat:'no-repeat'}}>
          <div style={{height:'100%', display:'flex', alignItems:'flex-end', justifyContent:'flex-start'}}>
            <div style={{fontFamily:'Inter', fontWeight:'bolder', fontSize:'48px', color:'rgba(0,0,0,0.5)', marginBottom:80, marginLeft:40}}>
              No Open Alerts
            </div>
          </div>
        </div>
      </div>:
        <div className={styles['cardGrid']}>{cards}</div>}
    </div>
  )
}

export default ActiveAlertsPage;
