/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRoleDetails
 */
export interface UserRoleDetails {
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetails
     */
    name?: UserRoleDetailsNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetails
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetails
     */
    scope?: UserRoleDetailsScopeEnum;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserRoleDetails
     */
    access?: { [key: string]: Array<string>; };
}


/**
 * @export
 */
export const UserRoleDetailsNameEnum = {
    ShedAdmin: 'SHED_ADMIN',
    ClientAdmin: 'CLIENT_ADMIN',
    ReadOnly: 'READ_ONLY',
    Responder: 'RESPONDER'
} as const;
export type UserRoleDetailsNameEnum = typeof UserRoleDetailsNameEnum[keyof typeof UserRoleDetailsNameEnum];

/**
 * @export
 */
export const UserRoleDetailsScopeEnum = {
    Global: 'GLOBAL',
    Client: 'CLIENT',
    Component: 'COMPONENT'
} as const;
export type UserRoleDetailsScopeEnum = typeof UserRoleDetailsScopeEnum[keyof typeof UserRoleDetailsScopeEnum];

/**
 * @export
 */
export const UserRoleDetailsAccessEnum = {
    Read: 'READ',
    Write: 'WRITE',
    Delete: 'DELETE',
    Create: 'CREATE'
} as const;
export type UserRoleDetailsAccessEnum = typeof UserRoleDetailsAccessEnum[keyof typeof UserRoleDetailsAccessEnum];


/**
 * Check if a given object implements the UserRoleDetails interface.
 */
export function instanceOfUserRoleDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserRoleDetailsFromJSON(json: any): UserRoleDetails {
    return UserRoleDetailsFromJSONTyped(json, false);
}

export function UserRoleDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRoleDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'access': !exists(json, 'access') ? undefined : json['access'],
    };
}

export function UserRoleDetailsToJSON(value?: UserRoleDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'scope': value.scope,
        'access': value.access,
    };
}

