import { useCallback, useContext, useMemo } from 'react';

import { Credentials, PerentieAuthenticateWebServiceApi } from '../api';
import PerentieServiceContext from '../context/PerentieServiceContext';
import { passthroughPromise, suspendPromise } from '../util/promise';
import useAxiosRequestConfig from './useAxiosRequestConfig';

function useAuthenticationService() {
    const { apiUrl } = useContext(PerentieServiceContext);
    const api = useMemo(() => new PerentieAuthenticateWebServiceApi(undefined, apiUrl), [apiUrl]);
    const config = useAxiosRequestConfig();

    const login = useCallback(
        (credentials: Credentials) => {
            const fp = api.authenticate(credentials, config);
            return suspendPromise(fp);
        },
        [config, api]
    );

    const promiseLogin = useCallback(
        (credentials: Credentials) => {
            const fp = api.authenticate(credentials, config);
            return passthroughPromise(fp);
        },
        [config, api]
    );

    const logout = useCallback(() => {
        const fp = api.logout(config);
        return suspendPromise(fp);
    }, [config, api]);

    const promiseLogout = useCallback(() => {
        const fp = api.logout(config);
        return passthroughPromise(fp);
    }, [config, api]);

    const checkSession = useCallback(() => {
        const fp = api.checkSession(config);
        return suspendPromise(fp);
    }, [config, api]);

    const promiseCheckSession = useCallback(() => {
        const fp = api.checkSession(config);
        return passthroughPromise(fp);
    }, [config, api]);

    return { login, promiseLogin, logout, promiseLogout, checkSession, promiseCheckSession };

    // return { promiseCheckSession };
}

export default useAuthenticationService;
