import { AxiosRequestConfig } from 'axios';
import { useContext } from 'react';

import PerentieServiceContext from '../context/PerentieServiceContext';

function useRequestHeader(): AxiosRequestConfig {
    const { apiToken } = useContext(PerentieServiceContext);

    return {
        headers: {
            Authorization: apiToken,
        },
        timeout: 1000,
    };
}

export default useRequestHeader;
