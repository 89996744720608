import styles from './TopBar.module.scss';
import PerentieLogo from '../Icons/PerentieLogo';
import SidebarToggle from '../Icons/SidebarToggle';
import { useCallback, useEffect, useRef } from 'react';

interface TopBarProps {
    setHeight?: (height: number) => void;
    setCollapsed?: (collapsed: boolean) => void;
    collapsed?: boolean;
}

function TopBar({ setHeight, setCollapsed, collapsed }: TopBarProps) {
    const ref = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (ref.current) {
            const height = ref.current.offsetHeight;
            setHeight?.(height);
        }
    }, [ref, setHeight]);

    const toggleCollapsed = useCallback(() => {
        setCollapsed && setCollapsed(!collapsed);
    }, [collapsed, setCollapsed]);

    return (
        <header className={styles['top-bar']} ref={ref}>
            <SidebarToggle toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
            <PerentieLogo />
        </header>
    );
}

export default TopBar;
