import {ComponentBasicDetails, NotifyChainDetails, NotifyChainLinkDetails} from "@perentie/common";
import styles from './ManageComponentsPage.module.scss';
import {Switch} from "@systemic-design-framework/react";



export interface NotifyChainComponentProps {
  chain?: NotifyChainDetails | null,
  changeUserOnCall: (level: number, userId: string, isOnCall: boolean) => void;
}

function NotifyChainComponent(props: NotifyChainComponentProps){

  const onCallChange = (level:number, userId: string, isOnCall: boolean) => {
    console.log(' onCall change', userId, isOnCall);
    props.changeUserOnCall(level, userId, isOnCall);
  }
  const ChanLink = (props: {link: NotifyChainLinkDetails}) => {

    const switchChange = (level: number, user: string, isOn: boolean) => {
      onCallChange(level, user, isOn);
    }

    const users = Object.keys(props.link.userMap!).map( key => {
      const user = props.link.userMap![key];
      return [
        <div style={{marginBottom:8}}>{user.firstName} {user.lastName}</div>,
        <div>{user.username}</div>,
        <div><Switch checked={props.link.onCallMap![user.objectId!]} onChange={(checked) => switchChange(props.link.level!, user.objectId!, checked)}/></div>
      ];
    })
    return(
      <div>
        <p style={{marginBottom:4, textDecoration:'underline'}}>Level: {props.link.level}</p>
        <div className={styles['chainGrid']} style={{marginLeft: 8}}>
          <div className={styles['header']}>Name</div>
          <div className={styles['header']}>Username</div>
          <div className={styles['header']}>On Call</div>
          {users}
        </div>
      </div>
    )
  }

  const links = props.chain?.links?.map( l => {
    return <ChanLink link={l}/>
  })

  return (
    <div>
      {props.chain == null || props.chain.links?.length == 0 ? <em>No notify chain for component</em> :
        <>
        {links}
        </>
      }
    </div>
  )
}

export default NotifyChainComponent;
