import { CSSProperties } from 'react';
import styles from './Row.module.scss';

export interface RowProps {
    children?: React.ReactNode;
    className?: string;
    divider?: boolean;
    striped?: boolean;
    isHeader?: boolean;
    onClick?: () => void;
}

function Row({ children, className, onClick, divider, striped, isHeader }: RowProps) {
    const style: CSSProperties = {
        border: divider ? undefined : 'unset',
        backgroundColor: striped ? 'var(--neutral-20)' : undefined,
    };

    return (
        <div className={[styles['row'], isHeader || styles['hover'], onClick && styles['cursor-pointer']].join(' ')}
             onClick={onClick} style={style}>
                {children}
        </div>
    );
}

export default Row;
