/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlertBasicDetails } from './AlertBasicDetails';
import {
    AlertBasicDetailsFromJSON,
    AlertBasicDetailsFromJSONTyped,
    AlertBasicDetailsToJSON,
} from './AlertBasicDetails';

/**
 * 
 * @export
 * @interface RESTResultListAlertBasicDetails
 */
export interface RESTResultListAlertBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListAlertBasicDetails
     */
    error?: string;
    /**
     * 
     * @type {Array<AlertBasicDetails>}
     * @memberof RESTResultListAlertBasicDetails
     */
    data?: Array<AlertBasicDetails>;
}

/**
 * Check if a given object implements the RESTResultListAlertBasicDetails interface.
 */
export function instanceOfRESTResultListAlertBasicDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultListAlertBasicDetailsFromJSON(json: any): RESTResultListAlertBasicDetails {
    return RESTResultListAlertBasicDetailsFromJSONTyped(json, false);
}

export function RESTResultListAlertBasicDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultListAlertBasicDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AlertBasicDetailsFromJSON)),
    };
}

export function RESTResultListAlertBasicDetailsToJSON(value?: RESTResultListAlertBasicDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AlertBasicDetailsToJSON)),
    };
}

