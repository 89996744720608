/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentBasicDetails
 */
export interface ComponentBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    systemIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentBasicDetails
     */
    state?: ComponentBasicDetailsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentBasicDetails
     */
    stickyAlerts?: boolean;
}


/**
 * @export
 */
export const ComponentBasicDetailsStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type ComponentBasicDetailsStateEnum = typeof ComponentBasicDetailsStateEnum[keyof typeof ComponentBasicDetailsStateEnum];


/**
 * Check if a given object implements the ComponentBasicDetails interface.
 */
export function instanceOfComponentBasicDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentBasicDetailsFromJSON(json: any): ComponentBasicDetails {
    return ComponentBasicDetailsFromJSONTyped(json, false);
}

export function ComponentBasicDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentBasicDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'systemIdentifier': !exists(json, 'systemIdentifier') ? undefined : json['systemIdentifier'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'stickyAlerts': !exists(json, 'stickyAlerts') ? undefined : json['stickyAlerts'],
    };
}

export function ComponentBasicDetailsToJSON(value?: ComponentBasicDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'name': value.name,
        'systemIdentifier': value.systemIdentifier,
        'description': value.description,
        'state': value.state,
        'stickyAlerts': value.stickyAlerts,
    };
}

