import {useEffect, useState} from 'react';
import { IoCopyOutline, IoKeyOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import {
    AgentBasicDetails,
    AgentDetailsStatusEnum,
    useAdminService
} from '@perentie/common';
import { Button, Cell, ContainerCard, Row, Table } from '@systemic-design-framework/react';

import AgentStatus from '../../components/AgentStatus/AgentStatus';
//@ts-ignore
import styles from './AgentsPage.module.scss';
import {perDS} from "../../datasource/WebDS";

function AgentsPage() {
    //const [agents, fetchAgents, fetchAgentsStatus] = useAllAgentsService();
    // const {promiseAgents} = useAdminService();
    const [agents, setAgents] = useState<AgentBasicDetails[]>([]);
    const navigate = useNavigate();
    const onRegisterAgentClick = () => {
        navigate('/agents/register');
    };

    useEffect(() => {
        // promiseAgents().then( result => {
        //     // @ts-ignore
        //     setAgents(result.data);
        // });
        perDS.getAgents().then(resp => {
          setAgents(resp);
        })
    },[]);
    
    const updateAgentStatus = (agent: AgentBasicDetails) => {
        agent.status = agent.status === AgentDetailsStatusEnum.Active ? AgentDetailsStatusEnum.Disabled : AgentDetailsStatusEnum.Active;
        perDS.updateAgent(agent)
        .then(res => {
            perDS.getAgents().then(resp => {
                setAgents(resp);
            });
        })
        .catch(err => {
            console.error('updateAgent fail ', err);
        });
    }

    const renderItem = (item: AgentBasicDetails, index: number) => {
        return (
            <Row key={index}>
                <Cell>
                    <AgentStatus
                        timestamp={new Date().toUTCString()}
                        connected={item.status === AgentDetailsStatusEnum.Active}
                    />
                </Cell>
                <Cell>{item.name}</Cell>
                <Cell>{item.type}</Cell>
                <Cell>{item.description}</Cell>
                <Cell>
                    <Button
                        variant="ghost"
                        size="s"
                        title="View/Edit Agent"
                        onClick={() => {
                            navigate(`/agents/details/?id=${item.objectId}`);
                        }}
                    />
                </Cell>
                <Cell>
                    <Button variant="ghost" Icon={<IoCopyOutline />} size="s" />
                </Cell>
                <Cell>
                    <Button variant="ghost" Icon={<IoKeyOutline />} size="s" />
                </Cell>
                <Cell>
                    <Button variant="ghost" title={item.status === AgentDetailsStatusEnum.Active ? "Disable" : "Enable"} size="s" onClick={() => updateAgentStatus(item)}/>
                </Cell>
            </Row>
        );
    };

    const TableHeader = () => {
        return (
            <Row>
                <Cell>Status</Cell>
                <Cell>Name</Cell>
                <Cell>Type</Cell>
                <Cell>Description</Cell>
                <Cell>View/Edit</Cell>
                <Cell>Copy ID</Cell>
                <Cell>Copy Public Key</Cell>
                <Cell> </Cell>
            </Row>
        );
    };

    return (
        <div className={styles['manage-agents-page']}>
            <ContainerCard
                HeaderComponent={
                    <div style={{display:'flex', justifyContent:'space-between', flex:'1'}}>
                        Manage Agents <Button title="Register New Agent" onClick={onRegisterAgentClick} size="s" />
                    </div>
                }
            >
                <Table data={agents} renderItem={renderItem} HeaderComponent={<TableHeader />} border />
            </ContainerCard>
        </div>
    );
}

export default AgentsPage;
