/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RESTResultUserDetails,
  TokenRequest,
} from '../models/index';
import {
    RESTResultUserDetailsFromJSON,
    RESTResultUserDetailsToJSON,
    TokenRequestFromJSON,
    TokenRequestToJSON,
} from '../models/index';

export interface UpdateNotifyDetailsRequest {
    tokenRequest?: TokenRequest;
}

/**
 * PerentieUserWSApi - interface
 * 
 * @export
 * @interface PerentieUserWSApiInterface
 */
export interface PerentieUserWSApiInterface {
    /**
     * Send notification token
     * @param {TokenRequest} [tokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerentieUserWSApiInterface
     */
    updateNotifyDetailsRaw(requestParameters: UpdateNotifyDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultUserDetails>>;

    /**
     * Send notification token
     */
    updateNotifyDetails(tokenRequest?: TokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultUserDetails>;

}

/**
 * 
 */
export class PerentieUserWSApi extends runtime.BaseAPI implements PerentieUserWSApiInterface {

    /**
     * Send notification token
     */
    async updateNotifyDetailsRaw(requestParameters: UpdateNotifyDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RESTResultUserDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/notifyDetails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRequestToJSON(requestParameters.tokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultUserDetailsFromJSON(jsonValue));
    }

    /**
     * Send notification token
     */
    async updateNotifyDetails(tokenRequest?: TokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RESTResultUserDetails> {
        const response = await this.updateNotifyDetailsRaw({ tokenRequest: tokenRequest }, initOverrides);
        return await response.value();
    }

}
