import { InputProps } from '..';
import FormContext from '../Form/FormContext';
import styles from './TextArea.module.scss';

interface TextAreaProps extends InputProps {
    disabled?: boolean;
    placeholder?: string;
    children?: React.ReactNode;
    reverse?: boolean;
    value?: string;
    rows?: number;
    name?: string;
    onInput?: (data: any) => void;
}

function TextArea({
    label,
    helper,
    placeholder,
    disabled,
    id,
    children,
    reverse,
    value,
    rows,
    name,
    onInput
}: TextAreaProps) {
    return (
        <FormContext.Consumer>
            {({ data, update }) => (
                <div className={styles['container']}>
                    <label htmlFor={id}>{label}</label>
                    <div
                        style={{
                            all: 'inherit',
                            flexDirection: reverse ? 'row-reverse' : 'row',
                        }}>
                        <textarea
                            placeholder={placeholder}
                            id={id}
                            name={name}
                            disabled={disabled}
                            defaultValue={value}
                            onChange={(e) => update(id, e.currentTarget.value)}
                            onInput={onInput}
                            rows={rows}
                        />
                        {children}
                    </div>
                    {helper && <div>{helper}</div>}
                </div>
            )}
        </FormContext.Consumer>
    );
}

export default TextArea;
