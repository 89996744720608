import {ErrorContext, FetchParams, Middleware, RequestContext, ResponseContext} from "../fetchapi";
class HeaderMiddleWare implements Middleware{

  private tokenResolver: () => Promise<string>;
  constructor(tokenPromise: () => Promise<string>) {
    this.tokenResolver = tokenPromise;
  }

  // onError(context: ErrorContext): Promise<Response | void> {
  //   return Promise.resolve(undefined);
  // }

  post(context: ResponseContext): Promise<Response | void> {
    return Promise.resolve(context.response);
  }

  async pre(context: RequestContext): Promise<FetchParams | void> {
    // console.log(' header middlewear pre');
    let accessToken: string = await this.tokenResolver();

    if (accessToken == null || accessToken.length === 0){
      return Promise.resolve({
        url: context.url,
        init: {
          ...context.init
        },
      });
    }

    // console.log(' middleWare Token: ', accessToken);
    if (!accessToken.startsWith('Bearer')){
      accessToken = `Bearer ${accessToken}`;
    }
    return Promise.resolve({
      url: context.url,
      init: {
        ...context.init,
        headers: new Headers({
          ...context.init.headers,
          Authorization: `${accessToken}`,
        }),
      },
    });
  }
}
export default HeaderMiddleWare
