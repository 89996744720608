import styles from './CreateComponentPage.module.scss'
import {Text} from "../../components/Input";
import {Button} from "@systemic-design-framework/react";
import {useEffect, useState} from "react";
import {AgentBasicDetails} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import Select from "react-select";
import {useNavigate} from "react-router-dom";

interface ComponentFormValues {
  name?: string,
  desc?: string,
  agentId?: string;
  identifier?: string;
}
function CreateComponentPage() {

  const [agentList, setAgentList] = useState<AgentBasicDetails[]>([]);
  const [req, setReq] = useState<ComponentFormValues>({});
  const [canUpdate, setCanUpdate] = useState(false);
  const [createError, setCreateError] = useState<string|null>(null);

  const navigate = useNavigate();


  useEffect(() => {
    perDS.getAgents().then( res => {
      setAgentList(res);
    })
  }, []);

  useEffect(() => {
    const next = !!req.name && req.name.trim().length > 0
      && !!req.identifier && req.identifier.trim().length > 0
    && !!req.agentId && !!req.desc;
    setCanUpdate(next);
  }, [req]);

  const agentOptions = agentList.map( agent => {
    return {
      value: agent.objectId,
      label: agent.name + ' ('+agent.objectId+') ['+ agent.type +']'
    }
  })

  const createClick = () => {
    setCreateError(null);
    perDS.createComponent(req.name!, req.desc!, req.agentId!, req.identifier!).then(res => {
      navigate("/components");
    }).catch( reason => {
      console.log(' createComponent catch', reason);
      setCreateError(`Error creating component ${reason}`);
    });
  }

  const textChange = (field: 'name' | 'identifier' | 'desc', val: string) => {
    const next = {...req};
    next[field] = val;
    setReq(next);
  }

  const agentSelectChange = (thing: any) => {
    console.log('thing', thing);
    const next = {...req, agentId: thing.value};
    setReq(next);
  }

  return (
    <div className={styles['content']}>
      <h4>Create New Perentie Component</h4>
      <div style={{display:'inline-flex', flexDirection:'column', width:' 50%', rowGap: 16}}>
        <div style={{display: createError == null ? 'none' : '', color:'red', fontWeight:'bold'}}>{createError}</div>
        <Text id={'name'} size={'s'} label={'Name*'} value={req.name} valueChange={val => textChange('name', val)}></Text>
        <Text id={'identifier'} size={'s'} label={'Identifier*'} valueChange={val => textChange('identifier', val)}></Text>
        <label className={styles['label']} htmlFor={'agentId'}>Agent*</label>
        <Select options={agentOptions} required id={'agentId'} onChange={agentSelectChange}/>
        <Text id={'desc'} size={'s'} label={'Description*'} valueChange={val => textChange('desc', val)}></Text>

        <div style={{display:'inline-flex', columnGap:32}}>
          <Button size={'s'} title={'Cancel'} variant={'secondary-dark'} onClick={() => navigate('/components')}/>
          <Button size={'s'} disabled={!canUpdate} title={'Create'} onClick={createClick}/>
          disabled: {!canUpdate+''}
          <pre>{JSON.stringify(req,null,2)}</pre>
        </div>

      </div>
    </div>
  )

}

export default CreateComponentPage;
