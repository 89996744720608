/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PerentieRuleDefinition } from './PerentieRuleDefinition';
import {
    PerentieRuleDefinitionFromJSON,
    PerentieRuleDefinitionFromJSONTyped,
    PerentieRuleDefinitionToJSON,
} from './PerentieRuleDefinition';

/**
 * 
 * @export
 * @interface RESTResultPerentieRuleDefinition
 */
export interface RESTResultPerentieRuleDefinition {
    /**
     * 
     * @type {string}
     * @memberof RESTResultPerentieRuleDefinition
     */
    error?: string;
    /**
     * 
     * @type {PerentieRuleDefinition}
     * @memberof RESTResultPerentieRuleDefinition
     */
    data?: PerentieRuleDefinition;
}

/**
 * Check if a given object implements the RESTResultPerentieRuleDefinition interface.
 */
export function instanceOfRESTResultPerentieRuleDefinition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultPerentieRuleDefinitionFromJSON(json: any): RESTResultPerentieRuleDefinition {
    return RESTResultPerentieRuleDefinitionFromJSONTyped(json, false);
}

export function RESTResultPerentieRuleDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultPerentieRuleDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : PerentieRuleDefinitionFromJSON(json['data']),
    };
}

export function RESTResultPerentieRuleDefinitionToJSON(value?: RESTResultPerentieRuleDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': PerentieRuleDefinitionToJSON(value.data),
    };
}

