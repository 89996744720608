import { useState } from 'react';
import { InputProps } from '..';
import FormContext from '../Form/FormContext';
import styles from './Text.module.scss';

type TextState = 'default' | 'hover' | 'disabled' | 'focused' | 'active';
type TextSize = 's' | 'sm';

interface TextProps extends InputProps {
    ActionElement?: JSX.Element;
    placeholder?: string;
    value?: string;
    size?: TextSize;
    children?: React.ReactNode;
    reversed?: boolean;
    valueChange?: (value: string) => void;
}

function Text({
    placeholder,
    ActionElement,
    label,
    helper,
    disabled,
    value,
    size = 'sm',
    id,
    children,
    reversed,
    valueChange
}: TextProps) {
    const [state, setState] = useState<TextState>('default');
    const setFocused = () => setState('focused');
    const setHovered = () => state !== 'focused' && setState('hover');
    const setDefault = () => state !== 'focused' && setState('default');
    const setBlur = () => setState('default');

    return (
        <FormContext.Consumer>
            {({ data, update }) => (
                <div className={styles['container']}>
                    {label && <label htmlFor={id}>{label}</label>}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: reversed ? 'row-reverse' : 'row',
                        }}>
                        <div className={styles[`${disabled ? 'disabled' : state}-${size}`]}>
                            <input
                                id={id}
                                className={styles['input']}
                                placeholder={placeholder}
                                onChange={(e) => {
                                    update(id, e.currentTarget.value);
                                    if (!!valueChange) {
                                        valueChange(e.currentTarget.value);
                                    }
                                }}
                                onFocus={setFocused}
                                onBlur={setBlur}
                                onMouseEnter={setHovered}
                                onMouseLeave={setDefault}
                                disabled={disabled}
                                defaultValue={value}
                            />
                            <div className={styles['action']}>{ActionElement}</div>
                        </div>

                        {children}
                    </div>

                    {helper && <div className={styles['helper']}>{helper}</div>}
                </div>
            )}
        </FormContext.Consumer>
    );
}

export default Text;
