// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AO4_mmQdcaSjTdk4OD7H {\n  background-color: var(--main-menu-dark-header-background);\n  box-shadow: inset 0 -1px 0 0 #3a4143;\n  flex-direction: row;\n  justify-content: flex-start;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n}\n.AO4_mmQdcaSjTdk4OD7H .G0A08BYJj8TEsvJqSHw8 {\n  align-items: inherit;\n  display: inherit;\n  flex-grow: 1;\n  position: relative;\n}\n.AO4_mmQdcaSjTdk4OD7H .G0A08BYJj8TEsvJqSHw8 .QA5msBr0jXuM3dHG8Xsu {\n  display: flex;\n  position: absolute;\n}\n.AO4_mmQdcaSjTdk4OD7H .G0A08BYJj8TEsvJqSHw8 .P4dKzb6VYssLf71e_qwI {\n  display: flex;\n  position: absolute;\n  flex-wrap: nowrap;\n  flex-grow: 1;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/components/Sidebar/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,oCAAA;EACA,mBAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,oBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AACR;AAAQ;EACI,aAAA;EACA,kBAAA;AAEZ;AACQ;EACI,aAAA;EACA,kBAAA;EAEA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAAZ","sourcesContent":[".header {\n    background-color: var(--main-menu-dark-header-background);\n    box-shadow: inset 0 -1px 0 0 #3a4143;\n    flex-direction: row;\n    justify-content: flex-start;\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n\n    .frame {\n        align-items: inherit;\n        display: inherit;\n        flex-grow: 1;\n        position: relative;\n        .icon {\n            display: flex;\n            position: absolute;\n        }\n\n        .content {\n            display: flex;\n            position: absolute;\n            // do not wrap text in header\n            flex-wrap: nowrap;\n            flex-grow: 1;\n            overflow: hidden;\n            white-space: nowrap;\n            text-overflow: ellipsis;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "AO4_mmQdcaSjTdk4OD7H",
	"frame": "G0A08BYJj8TEsvJqSHw8",
	"icon": "QA5msBr0jXuM3dHG8Xsu",
	"content": "P4dKzb6VYssLf71e_qwI"
};
export default ___CSS_LOADER_EXPORT___;
