/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotifyChainLinkDetails } from './NotifyChainLinkDetails';
import {
    NotifyChainLinkDetailsFromJSON,
    NotifyChainLinkDetailsFromJSONTyped,
    NotifyChainLinkDetailsToJSON,
} from './NotifyChainLinkDetails';

/**
 * 
 * @export
 * @interface NotifyChainDetails
 */
export interface NotifyChainDetails {
    /**
     * 
     * @type {string}
     * @memberof NotifyChainDetails
     */
    componentId?: string;
    /**
     * 
     * @type {Array<NotifyChainLinkDetails>}
     * @memberof NotifyChainDetails
     */
    links?: Array<NotifyChainLinkDetails>;
}

/**
 * Check if a given object implements the NotifyChainDetails interface.
 */
export function instanceOfNotifyChainDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotifyChainDetailsFromJSON(json: any): NotifyChainDetails {
    return NotifyChainDetailsFromJSONTyped(json, false);
}

export function NotifyChainDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyChainDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(NotifyChainLinkDetailsFromJSON)),
    };
}

export function NotifyChainDetailsToJSON(value?: NotifyChainDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.componentId,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(NotifyChainLinkDetailsToJSON)),
    };
}

