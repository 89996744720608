/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    platform?: TokenRequestPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    token?: string;
}


/**
 * @export
 */
export const TokenRequestPlatformEnum = {
    Ios: 'IOS',
    Macos: 'MACOS',
    Android: 'ANDROID'
} as const;
export type TokenRequestPlatformEnum = typeof TokenRequestPlatformEnum[keyof typeof TokenRequestPlatformEnum];


/**
 * Check if a given object implements the TokenRequest interface.
 */
export function instanceOfTokenRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TokenRequestFromJSON(json: any): TokenRequest {
    return TokenRequestFromJSONTyped(json, false);
}

export function TokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function TokenRequestToJSON(value?: TokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'platform': value.platform,
        'token': value.token,
    };
}

