import { useEffect, useContext, ChangeEvent, useState } from 'react';
import { InputProps } from '..';
import FormContext from '../FormContext';
import styles from './Select.module.scss';
import { Validator } from '..';
import Label from '../Label/Label';

interface SelectProps extends InputProps {
    options?: {[key: string]: string};
    validate?: Validator<string> | Validator<string>[];
    defaultValue?: string;
}

function Select({ "for": self, defaultValue="", label, disabled, validate, required, options }: SelectProps) {
    const context = useContext(FormContext);

    const [modified, setModified] = useState(false);

    useEffect(() => {
        let value;
        if (options && Object.keys(options).length) {
            if (Object.keys(options).includes(defaultValue)) {
                value = modified ? context.getValue(self) : defaultValue;
            }
            else {
                value = Object.keys(options)[0];
            }
        }
        else {
            value = "";
        }
        context.register(self, value, !!required, validate);
    }, [defaultValue, options]);

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setModified(true);
        context.setValue(self, event.target.value);
    }

    const loading = context.loading();

    return (
        <>
            { label && <Label text={label} required={required}/> }
            <select
                className={styles['select']}
                value={context.getValue(self)}
                disabled={loading || disabled}
                onChange={onChange}
            >
                {!required &&
                    <option key={-1} value="">None</option>
                }
                {options &&
                    Object.entries(options).map(([key, value], i) => {
                        return <option key={i} value={key}>{value}</option>
                    })
                }
            </select>
            <label className={styles["error-message"]}>
                {context.getError(self)}
            </label>
        </>
    );
}

export default Select;
