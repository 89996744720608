import { useEffect, useState } from 'react';
import { UserDetails } from '../api';
import PerentieServiceContext from './PerentieServiceContext';

interface PerentieServiceProviderProps {
    children: React.ReactNode;
    url?: string;
    token?: string;
}

function PerentieServiceProvider({ children, url = '', token = '' }: PerentieServiceProviderProps) {
  console.log(' PerentieServiceProvider ... ');
    const [authenticated, setAuthenticated] = useState(false);
    const [userDetails, setUserDetails] = useState<UserDetails>({});
    const [apiUrl, setApiUrl] = useState(url);
    const [apiToken, setApiToken] = useState(token);

    // useEffect(() => {
    //     console.info(`API URL: ${apiUrl}`);
    // }, [apiUrl]);
    //
    // useEffect(() => {
    //     console.info(`API TOKEN: ${apiToken}`);
    // }, [apiToken]);
    //
    // useEffect(() => {
    //     console.log('Authenticated', authenticated);
    // }, [authenticated]);
    //
    // useEffect(() => {
    //     console.log('User Details', userDetails);
    // }, [userDetails]);

    return (
        <PerentieServiceContext.Provider
            value={{
                apiUrl,
                apiToken,
                authenticated,
                userDetails,
                setAuthenticated,
                setUserDetails,
                setApiUrl,
                setApiToken,
            }}
        >
            {children}
        </PerentieServiceContext.Provider>
    );
}

export default PerentieServiceProvider;
