// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A7l_rZWOcqo4mY6BW9CR {\n  display: block;\n  border-radius: 4px;\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);\n  background-color: lightgray;\n  pointer-events: none;\n  animation: byLJTatalVp13LX8uRF7 1s infinite alternate;\n}\n@keyframes byLJTatalVp13LX8uRF7 {\n  from {\n    opacity: 0.4;\n  }\n  to {\n    opacity: 1;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Skeleton/Skeleton.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;EACA,sCAAA;EACA,2BAAA;EACA,oBAAA;EACA,qDAAA;AACJ;AACI;EACI;IACI,YAAA;EACV;EACM;IACI,UAAA;EACV;AACF","sourcesContent":[".skeleton {\n    display: block;\n    border-radius: 4px;\n    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);\n    background-color: lightgray;\n    pointer-events: none;\n    animation: loading-skeleton 1s infinite alternate;\n\n    @keyframes loading-skeleton {\n        from {\n            opacity: 0.4;\n        }\n        to {\n            opacity: 1;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "A7l_rZWOcqo4mY6BW9CR",
	"loading-skeleton": "byLJTatalVp13LX8uRF7"
};
export default ___CSS_LOADER_EXPORT___;
