import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { RESTResult, RESTResultString } from '../api';
import { BaseAPI } from '../api/base';

export enum ServiceStatus {
    Initializing,
    Initialized,
    Error,
    Unauthorized,
    Unknown,
    Success,
    Loading,
}

type UnpackedRequest = Promise<[any, ServiceStatus]>;

class Service<T extends BaseAPI> {
    protected config: AxiosRequestConfig;

    constructor(protected API: T, token: string = '') {
        this.config = {
            headers: {
                Authorization: token,
            },
        };
    }

    protected unpackRequest(
        promise: Promise<AxiosResponse<RESTResult | RESTResultString, any>>,
        defaultValue: any,
        requestName: string = 'unknown'
    ): UnpackedRequest {
        const now = Date.now();
        return promise
            .then(response => {
                let status = ServiceStatus.Unknown;
                switch (response.status) {
                    case 200:
                        status = ServiceStatus.Success;
                        break;
                    case 403:
                    case 401:
                        status = ServiceStatus.Unauthorized;
                        break;
                    default:
                        status = ServiceStatus.Error;
                        break;
                }

                const data = response.data.error ? defaultValue : response.data.data;
                return [data, status];
            })
            .catch(error => {
                console.error(error);
                return [defaultValue, ServiceStatus.Error];
            })
            .finally(() => {
                const duration = Date.now() - now;
                console.log('finished request', requestName, 'in', duration, 'ms');
            }) as UnpackedRequest;
    }
}

export default Service;
