import { useContext, useRef } from 'react';
import usePosition from '../../../hooks/usePosition';
import TabBarContext from '../TabBarContext';
import styles from './Tab.module.scss';

interface TabProps {
    title: string;
    key?: string;
    disabled?: boolean;
}

function Tab({ title, key, disabled }: TabProps) {
    const { setCurrent, setCurrentRef, current, size } = useContext(TabBarContext);
    const [ref, index] = usePosition<HTMLDivElement>();
    const isSelected = current === key || current === index;

    const onClickHandler = () => {
        if (key) {
            setCurrent(key);
        } else {
            setCurrent(index);
        }

        setCurrentRef(ref);
    };

    return (
        <div
            className={`${styles['tab']} ${styles[`tab-${size}`]} ${isSelected && styles[`tab-selected`]}`}
            ref={ref}
            onClick={onClickHandler}
        >
            {title}
        </div>
    );
}

export default Tab;
