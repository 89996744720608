/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgentBasicDetails } from './AgentBasicDetails';
import {
    AgentBasicDetailsFromJSON,
    AgentBasicDetailsFromJSONTyped,
    AgentBasicDetailsToJSON,
} from './AgentBasicDetails';

/**
 * 
 * @export
 * @interface RESTResultListAgentBasicDetails
 */
export interface RESTResultListAgentBasicDetails {
    /**
     * 
     * @type {string}
     * @memberof RESTResultListAgentBasicDetails
     */
    error?: string;
    /**
     * 
     * @type {Array<AgentBasicDetails>}
     * @memberof RESTResultListAgentBasicDetails
     */
    data?: Array<AgentBasicDetails>;
}

/**
 * Check if a given object implements the RESTResultListAgentBasicDetails interface.
 */
export function instanceOfRESTResultListAgentBasicDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RESTResultListAgentBasicDetailsFromJSON(json: any): RESTResultListAgentBasicDetails {
    return RESTResultListAgentBasicDetailsFromJSONTyped(json, false);
}

export function RESTResultListAgentBasicDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultListAgentBasicDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AgentBasicDetailsFromJSON)),
    };
}

export function RESTResultListAgentBasicDetailsToJSON(value?: RESTResultListAgentBasicDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AgentBasicDetailsToJSON)),
    };
}

