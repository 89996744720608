/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRuleSetRequest
 */
export interface UpdateRuleSetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleSetRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleSetRequest
     */
    rules?: string;
}

/**
 * Check if a given object implements the UpdateRuleSetRequest interface.
 */
export function instanceOfUpdateRuleSetRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateRuleSetRequestFromJSON(json: any): UpdateRuleSetRequest {
    return UpdateRuleSetRequestFromJSONTyped(json, false);
}

export function UpdateRuleSetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRuleSetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': !exists(json, 'object_id') ? undefined : json['object_id'],
        'rules': !exists(json, 'rules') ? undefined : json['rules'],
    };
}

export function UpdateRuleSetRequestToJSON(value?: UpdateRuleSetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'object_id': value.objectId,
        'rules': value.rules,
    };
}

