import {AgentDetails, AgentDetailsStatusEnum, AgentRequest} from '@perentie/common';
import { ContainerCard } from '@systemic-design-framework/react';
import AgentStatus from '../../components/AgentStatus/AgentStatus';
import AgentForm from '../../forms/AgentForm/AgentForm';
import styles from './AgentDetails.module.scss';

interface AgentDetailsProps {
    agentDetails: AgentDetails;
    onSubmit?: (data: AgentDetails) => void;
}

function AgentDetailsPage({ agentDetails, onSubmit }: AgentDetailsProps) {
    const { name, type, description, publicKey, component, connectionInfo, objectId, status } = agentDetails;

    console.log(' AgentDetails', agentDetails);

    if (!agentDetails.status) {
        return null;
    }

    return (
        <div className={styles['container']} style={{overflow:'auto'}}>
            <ContainerCard HeaderComponent={<div>Agent Details</div>}>
                <div style={{ flex: 1, display: 'flex' }}>
                    <AgentForm
                        agentId={objectId}
                        agentName={name}
                        agentType={type}
                        description={description}
                        publicKey={publicKey}
                        onSubmit={data => {
                            const agentDetails: AgentDetails = {
                                objectId: data.agentId,
                                name: data.agentName,
                                type: data.agentType,
                                description: data.description,
                                publicKey: data.publicKey,
                            };
                            console.log(agentDetails);
                            onSubmit?.(agentDetails);
                        }}
                    />
                </div>

                <div className={styles['vertical-rule']} />

                <div style={{ flex: 1, gap: '16px', display: 'flex', flexDirection: 'column' }}>
                    <div className={styles['title']}>Agent Connection Details</div>
                    <div className={styles['connection-details']}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className={styles['cell']}>Connection status:</div>
                            <div className={styles['cell']}>
                                <AgentStatus
                                    timestamp={connectionInfo?.lastConnected?.toISOString()}
                                    connected={!!connectionInfo?.lastIsConnected}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className={styles['cell']}>Last received message time:</div>
                            <div className={styles['cell']}>{connectionInfo?.lastConnected?.toISOString()}</div>
                        </div>
                    </div>
                    <div className={styles['title']}>Agent Configuration</div>
                    <pre style={{textWrap:'wrap'}}>{JSON.stringify(connectionInfo, null, 2)}</pre>
                </div>
            </ContainerCard>
        </div>
    );
}

export default AgentDetailsPage;
