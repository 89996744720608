/* tslint:disable */
/* eslint-disable */
/**
 * Perentie REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PerentieRuleDefinition
 */
export interface PerentieRuleDefinition {
    /**
     * 
     * @type {string}
     * @memberof PerentieRuleDefinition
     */
    ruleId?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieRuleDefinition
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieRuleDefinition
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PerentieRuleDefinition
     */
    state?: PerentieRuleDefinitionStateEnum;
    /**
     * 
     * @type {string}
     * @memberof PerentieRuleDefinition
     */
    condition?: string;
    /**
     * 
     * @type {number}
     * @memberof PerentieRuleDefinition
     */
    priority?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PerentieRuleDefinition
     */
    valid?: boolean;
}


/**
 * @export
 */
export const PerentieRuleDefinitionStateEnum = {
    Unknown: 'UNKNOWN',
    Ok: 'OK',
    Info: 'INFO',
    Warning: 'WARNING',
    Severe: 'SEVERE',
    Critical: 'CRITICAL'
} as const;
export type PerentieRuleDefinitionStateEnum = typeof PerentieRuleDefinitionStateEnum[keyof typeof PerentieRuleDefinitionStateEnum];


/**
 * Check if a given object implements the PerentieRuleDefinition interface.
 */
export function instanceOfPerentieRuleDefinition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PerentieRuleDefinitionFromJSON(json: any): PerentieRuleDefinition {
    return PerentieRuleDefinitionFromJSONTyped(json, false);
}

export function PerentieRuleDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerentieRuleDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
    };
}

export function PerentieRuleDefinitionToJSON(value?: PerentieRuleDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruleId': value.ruleId,
        'name': value.name,
        'description': value.description,
        'state': value.state,
        'condition': value.condition,
        'priority': value.priority,
        'valid': value.valid,
    };
}

