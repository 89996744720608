import { createContext } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import { SpringValue } from 'react-spring';

const SidebarContext = createContext({
    animationProgress: new SpringValue(0),
    collapsed: false,
    location: {} as Location,
    navigator: {} as NavigateFunction,
});

export default SidebarContext;
