import { useEffect, useContext, ChangeEvent } from 'react';
import { InputProps } from '..';
import FormContext from '../FormContext';
import styles from './TextArea.module.scss';
import { Validator } from '..';
import Label from '../Label/Label';

interface TextAreaProps extends InputProps {
    placeholder?: string;
    validate?: Validator<string> | Validator<string>[];
    defaultValue?: string;
}

function TextArea({ for: self, label, defaultValue="", placeholder, disabled, validate, required }: TextAreaProps) {
    const context = useContext(FormContext);

    useEffect(() => {
        context.register(self, defaultValue, !!required);
    }, [defaultValue]);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        context.setValue(self, event.target.value)
    }

    const loading = context.loading();

    return (
        <>
            { label && <Label text={label} required={required}/> }
            <textarea
                className={styles['textarea']}
                placeholder={placeholder}
                value={loading ? "" : context.getValue(self)}
                disabled={loading || disabled}
                onChange={onChange}
            />
            <label className={styles["error-message"]}>
                {context.getError(self)}
            </label>
        </>
    );
}

export default TextArea;
